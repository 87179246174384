// Auto-gerado com typewriter
import { computedFrom } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { BaseViewModel } from "./BaseViewModel";
import { VmWrapper } from "./VmWrapper";
//imports locais
import { Pagamento } from './Pagamento';
//export classe
export class CondicoesPagamento extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public id: number = 0;
  public nvcDescricao: string = null;
  public bitActivo: boolean = false;
  public bitSistema: boolean = false;
  public nvcCodigoInterno: string = "00000000-0000-0000-0000-000000000000";
  public nvcUtilizadorI: string = null;
  public bitFaturaRecibo: boolean = false;
  public nvcChaveErp: string = null;
  //public pagamento : Pagamento[] = [];
  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<CondicoesPagamento>, ) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): CondicoesPagamento {
    let model = new CondicoesPagamento();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): CondicoesPagamento[] {
    if (objs && Array.isArray(objs)) return objs.map(CondicoesPagamento.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<CondicoesPagamento>({ payload: this, confirmLevel: (+cl || 0) });
  }

  public cloneInstance(): CondicoesPagamento { return CondicoesPagamento.fromPOJSO(this.stateToPOJSO()); }
  //endregion

  public toString() {
    return `CondicoesPagamento`;
  }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: CondicoesPagamento) => m.id).displayName("id").required()
  .ensure((m: CondicoesPagamento) => m.nvcDescricao).displayName("Descrição").required()
  .ensure((m: CondicoesPagamento) => m.bitActivo).displayName("bitActivo").required()
  .ensure((m: CondicoesPagamento) => m.bitSistema).displayName("bitSistema").required()
  .ensure((m: CondicoesPagamento) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
  .ensure((m: CondicoesPagamento) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
  //.ensure((m: CondicoesPagamento) => m.dtmDataI).displayName("dtmDataI").required()
  //.ensure((m: CondicoesPagamento) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
  //.ensure((m: CondicoesPagamento) => m.dtmDataU).displayName("dtmDataU").required()
  //.ensure((m: CondicoesPagamento) => m.timestamp).displayName("timestamp").required()
  .ensure((m: CondicoesPagamento) => m.nvcChaveErp).displayName("nvcChaveErp").required()
  //.ensure((m: CondicoesPagamento) => m.pagamento).displayName("pagamento").required()
  .on(CondicoesPagamento);
