// Auto-gerado com typewriter
import { computedFrom } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { BaseViewModel } from "./BaseViewModel";
import { VmWrapper } from "./VmWrapper";
//imports locais
import { Assinatura } from './Assinatura';
import { MeioPagamento } from './MeioPagamento';
import { Pais } from './Pais';
import { AssinanteTipo } from './AssinanteTipo';
import { Titulo } from './Titulo';
import { Morada } from './Morada';
import { Pagamento } from './Pagamento';
import moment = require("moment");

import { now } from '../utils/ItNumeric'
import { AssinanteAssinatura } from "./AssinanteAssinatura";
import { AssinanteCredito } from "./AssinanteCredito";
//export classe
export class Assinante extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public id: number = 0;
  public idAssinatura: number = null;
  public intNumero: number = 0;
  public nvcReferencia: string = "";
  public nvcChaveErp: string = null;
  public nvcNome: string = null;
  public nvcContribuinte: string = "";
  public nvcTipo: string = null;
  public nvcMeioPagamento: string = null;
  public nvcTitulo: string = null;
  public nvcMorada: string = "";
  public nvcLocalidade: string = "";
  public nvcCodPostal: string = "";
  public nvcTelemovel: string = null;
  public nvcTelefone: string = null;
  public nvcFax: string = null;
  public nvcEmail: string = null;
  public nvcPais: string = null;
  public nvcWebsite: string = null;
  public nvcObservacoes: string = null;
  public nvcTags: string = null;
  public intPermissao: number = 0;
  public dtmAniversario: string = null;
  public _dtmAniversario: Date = new Date();
  public bitActivo: boolean = true;
  public bitAnonimato: boolean = false;
  public nvcEntidadeFaturacaoErp: string = null;
  public dtmRegisto: string = moment().format("YYYY-MM-DD");
  public _dtmRegisto: Date = new Date();
  public dtmRenovacao: string = null;
  public _dtmRenovacao: Date = new Date();
  public dtmInactivo: string = null;
  public _dtmInactivo: Date = new Date();
  public intUltmaEdicao: number = null;
  public bitCartaRenovacaoEmitida: boolean = false;
  public bitConsumidorFinal: boolean = false;
  public bitFaturacaoEletronica: boolean = false;
  public nvcEntidadeMb: string = null;
  public nvcReferenciaMb: string = null;
  public dtmExpiraMb: string = null;
  public _dtmExpiraMb: Date = new Date();
  public idAssinaturaNavigation: Assinatura = null;
  public nvcMeioPagamentoNavigation: MeioPagamento = null;
  public nvcPaisNavigation: Pais = null;
  public nvcTipoNavigation: AssinanteTipo = null;
  public nvcTituloNavigation: Titulo = null;
  public assinanteAssinatura: AssinanteAssinatura[] = [];
  public assinanteCredito: AssinanteCredito[] = [];
  public morada: Morada[] = [];
  public pagamento: Pagamento[] = [];


  public get MoradaEnvio(): Morada {
    return this.morada[0] || new Morada();
  }
  public set MoradaEnvio(morada: Morada) {
    this.morada[0] = morada;
  }

  public get MoradaFaturacao(): Morada {
    return this.morada[1] || new Morada();
  }
  public set MoradaFaturacao(morada: Morada) {
    this.morada[1] = morada;
  }
  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Assinante>, ) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Assinante {
    let model = new Assinante();
    model.setState(obj);
    if (obj.morada) model.morada = Morada.multipleFromPOJSO(obj.morada);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Assinante[] {
    if (objs && Array.isArray(objs)) return objs.map(Assinante.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    var model = this.getState();

    if (this.morada) model.morada = this.morada.map(el => el.stateToPOJSO());
    return model;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Assinante>({ payload: this, confirmLevel: (+cl || 0) });
  }

  public cloneInstance(): Assinante { return Assinante.fromPOJSO(this.stateToPOJSO()); }
  //endregion

  public toString() {
    return `Assinante`;
  }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Assinante) => m.id).displayName("id").required()
  .ensure((m: Assinante) => m.intNumero).displayName("intNumero").required()
  .ensure((m: Assinante) => m.nvcReferencia).displayName("Referência").required()
  .ensure((m: Assinante) => m.nvcChaveErp).displayName("nvcChaveErp").required()
  .ensure((m: Assinante) => m.nvcNome).displayName("Nome").required()
  .ensure((m: Assinante) => m.nvcContribuinte).displayName("Nº Contribuinte").required()
  .ensure((m: Assinante) => m.nvcTipo).displayName("Tipo de assinante").required()
  .ensure((m: Assinante) => m.nvcMeioPagamento).displayName("Meio de pagamento").required()
  .ensure((m: Assinante) => m.nvcTitulo).displayName("Título").required()
  .ensure((m: Assinante) => m.nvcMorada).displayName("nvcMorada").required()
  .ensure((m: Assinante) => m.nvcLocalidade).displayName("nvcLocalidade").required()
  .ensure((m: Assinante) => m.nvcCodPostal).displayName("nvcCodPostal").required()
  .ensure((m: Assinante) => m.nvcTelemovel).displayName("Telemóvel").required()
  .ensure((m: Assinante) => m.nvcTelefone).displayName("Telefone").required()
  .ensure((m: Assinante) => m.nvcFax).displayName("Fax").required()
  .ensure((m: Assinante) => m.nvcEmail).displayName("Email").required().email()
  .ensure((m: Assinante) => m.nvcPais).displayName("nvcPais").required()
  .ensure((m: Assinante) => m.idAssinatura).displayName("Assinatura").required()
  .ensure((m: Assinante) => m.nvcWebsite).displayName("nvcWebsite").required()
  .ensure((m: Assinante) => m.nvcObservacoes).displayName("nvcObservacoes").required()
  .ensure((m: Assinante) => m.nvcTags).displayName("nvcTags").required()
  .ensure((m: Assinante) => m.intPermissao).displayName("intPermissao").required()
  .ensure((m: Assinante) => m.dtmAniversario).displayName("dtmAniversario").required()
  .ensure((m: Assinante) => m.bitActivo).displayName("bitActivo").required()
  .ensure((m: Assinante) => m.bitAnonimato).displayName("bitAnonimato").required()
  .ensure((m: Assinante) => m.dtmRegisto).displayName("dtmRegisto").required()
  //.ensure((m: Assinante) => m.dtmRenovacao).displayName("dtmRenovacao").required()
  //.ensure((m: Assinante) => m.dtmInactivo).displayName("dtmInactivo").required()
  //.ensure((m: Assinante) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
  //.ensure((m: Assinante) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
  //.ensure((m: Assinante) => m.dtmDataI).displayName("dtmDataI").required()
  //.ensure((m: Assinante) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
  //.ensure((m: Assinante) => m.dtmDataU).displayName("dtmDataU").required()
  //.ensure((m: Assinante) => m.timestamp).displayName("timestamp").required()
  //.ensure((m: Assinante) => m.idAssinaturaNavigation).displayName("idAssinaturaNavigation").required()
  .ensure((m: Assinante) => m.nvcMeioPagamentoNavigation).displayName("nvcMeioPagamentoNavigation").required()
  .ensure((m: Assinante) => m.nvcPaisNavigation).displayName("nvcPaisNavigation").required()
  .ensure((m: Assinante) => m.nvcTipoNavigation).displayName("nvcTipoNavigation").required()
  .ensure((m: Assinante) => m.nvcTituloNavigation).displayName("nvcTituloNavigation").required()
  .ensure((m: Assinante) => m.morada).displayName("morada").required()
  .ensure((m: Assinante) => m.pagamento).displayName("pagamento").required()
  .on(Assinante);
