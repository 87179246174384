// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {AssinanteAssinatura} from './AssinanteAssinatura';
import {Pagamento} from './Pagamento';
//export classe
export class AssinaturaPagamento extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public idAssinanteAssinatura : number = 0;
    public idPagamento : number = 0;
    public decValorPago : number = 0;
    public idAssinanteAssinaturaNavigation : AssinanteAssinatura = null;
    public idPagamentoNavigation : Pagamento = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<AssinaturaPagamento>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): AssinaturaPagamento {
        let model = new AssinaturaPagamento();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): AssinaturaPagamento[] {
        if (objs && Array.isArray(objs)) return objs.map(AssinaturaPagamento.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<AssinaturaPagamento>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): AssinaturaPagamento { return AssinaturaPagamento.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `AssinaturaPagamento`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: AssinaturaPagamento) => m.idAssinanteAssinatura).displayName("idAssinanteAssinatura").required()
    .ensure((m: AssinaturaPagamento) => m.idPagamento).displayName("idPagamento").required()
    .ensure((m: AssinaturaPagamento) => m.decValorPago).displayName("decValorPago").required()
    .ensure((m: AssinaturaPagamento) => m.idAssinanteAssinaturaNavigation).displayName("idAssinanteAssinaturaNavigation").required()
    .ensure((m: AssinaturaPagamento) => m.idPagamentoNavigation).displayName("idPagamentoNavigation").required()
  .on(AssinaturaPagamento);
 