/**
 * Mapa de bundle-mobile para entry-point em mobile
 */
import {RouteConfig} from "aurelia-router";

export const level_3: RouteConfig[] = [
//@formatter:off
{route: ''       , href: 'level-1/level-2/level-3/index'  , name: 'level 3' , moduleId:'bundle-mobile/level-1/level-2/level-3/index'   , nav: false  , title: 'Level 3'} ,
{route: 'page-1' , href: 'level-1/level-2/level-3/page-1' , name: 'page 1'  , moduleId: 'bundle-mobile/level-1/level-2/level-3/page-1' , nav: true   , auth: false} ,
//@formatter:on
];

export const level_2: RouteConfig[] = [
//@formatter:off
{route: ''        , href: 'level-1/level-2/index'   , name: 'level 2' , moduleId:'bundle-mobile/level-1/level-2/index'                      , nav: false  , title: 'Level 2'} ,
{route: 'level-3' , href: 'level-1/level-2/level-3' , name: 'level 3' , moduleId: 'bundle-mobile/level-1/level-2/level-3/level-3-component' , nav: true   , title: 'Level 3', auth: false , settings: { children: level_3}} ,
//@formatter:on
];


export const level_1: RouteConfig[] = [
//@formatter:off
{route: ''        , href: 'level-1/index'   , name: 'level 1' , moduleId:'bundle-mobile/level-1/index'                      , nav: false  , title: 'Level 1'} ,
{route: 'level-2' , href: 'level-1/level-2' , name: 'level 2' , moduleId: 'bundle-mobile/level-1/level-2/level-2-component' , nav: true   , title: 'Level 2', auth: false , settings: { children: level_2}} ,
//@formatter:on
];

export const mobileRoutes: RouteConfig[] = [
//@formatter:off
{route: ''            , href: ''            , name: 'mobile'        , moduleId: 'bundle-mobile/index'                     , nav: false       , title: 'Mobile'      , auth: false} ,
{route: 'inicio'      , href: 'inicio'      , name: 'mobile_inicio' , moduleId: 'bundle-mobile/index'                     , nav: false       , title: 'Mobile'      , auth: false} ,
{route: 'level-1'     , href: 'level-1'     , name: 'level 1'       , moduleId: 'bundle-mobile/level-1/level-1-component' , nav: true        , title: 'Level 1'    , auth: false  , settings: { children: level_1, icon: 'fa fa-truck' , titulo: 'Level 1'}} ,
{route: 'blankpage_1' , href: 'blankpage_1' , name: 'main-nav-1'    , moduleId: 'bundle-mobile/blankpage_1'               , nav: true        , title: 'blankpage_1' , auth: false} ,
{route: 'blankpage_2' , href: 'blankpage_2' , name: 'main-nav-2'    , moduleId: 'bundle-mobile/blankpage_2'               , nav: true        , title: 'blankpage_2' , auth: false} ,
{route: 'login'       , href: 'login'       , name: 'login'         , moduleId: 'bundle-mobile/login'                     , nav: false}      ,
//@formatter:on
];
