import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import environment from "../environment";

@autoinject()
export class AlertDialog {
  protected controller: DialogController;
  protected title:string = "Evento a confirmar";
  private errors: any;
  private errorKeys: string[] = [];

  constructor(controller: DialogController/*, app: GlobalServices*/) {
    // console.log("ConfirmacaoDialog constructor");
    this.controller           = controller;
  }

  //canDeactivate(){}

  activate(p) {
    if(p.title) this.title = p.title;
    if(p.errors) {
      this.errors = p.errors;
      if(environment.debug) console.log("[alert-dialog]", this.errors);
      this.errorKeys = Object.keys(this.errors);
      if(!environment.debug) this.errorKeys = this.errorKeys.filter(el => el != '__s');
    }
  }
}
