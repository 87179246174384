/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar uma acção(POST) passível de confirmação
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo - um Modelo de domínio
 * @param {string} route - o endereço para onde fazer o POST
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaActionTyped = (something, modelo, route: string) =>
  something.app.confirmaActionTyped(modelo.wrapIt(), route)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);

/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar um DELETE passível de confirmação
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo - um Modelo de domínio
 * @param {string} route - o endereço para onde fazer o POST
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaDeletionTyped = (something, modelo, route: string) =>
  something.app.confirmaDeletionTyped(modelo.wrapIt(), route)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);
