import {autoinject, computedFrom} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";

@autoinject()
export class ErroServidorDialog {
  protected controller: DialogController;
  protected mensagens:any;
  protected keys:any;
  protected closeBtn:HTMLButtonElement;

  constructor(controller: DialogController) {
    this.controller = controller;
    //console.log("lodash test", _s.camelCase("uma-string-que-vai passar a camel case"));
  }

  // canDeactivate() {}

  //Recebe o objeto enviado pelo servidor em resposta tipo erro-servidor
  activate(obj: any) {
    this.mensagens = obj.mensagens;
    this.keys = Object.keys(this.mensagens);
    this.closeBtn.focus();
  }
}
