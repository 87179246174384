//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {assinantesRoutes} from "../../app-routes";
import {App} from "../../app";

@autoinject()
export class AssinantesComponent {
  public app: GlobalServices;
  public childRouter: Router;

  constructor(gs: GlobalServices, protected parent: App) {
    this.app = gs;
    //setup de breadcrumbs
    // this.app.activeModule = {title:"Siial", uri: `${environment.basedir}#/siial`}
    // this.app.activeModule = {title:"Sopocal", uri: `${environment.basedir}#/sopocal`}
  }

  // canActivate() {
  //   let superUser = this.app.auth.superUser;
  //   if(!superUser) this.app.notificationError("Não está autorizado para aceder ao módulo sopocal");
  //   return superUser;
  // }

  configureRouter(config: RouterConfiguration, router: Router) {
    if (environment.debug) console.log("[assinantes-component]","configureRouter", config, router);
    config.map(assinantesRoutes);
    this.childRouter = router;
  }

  logout() {
    if (environment.debug) console.log("[assinantes-component]","logout");
    this.parent && this.parent.logout();

    let a = {prop1: "teste", prop2: "xpto"};
  }
}
