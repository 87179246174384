// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
//no imports

//export classe
export class SysConfig extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public nvcKey : string = null;
    public nvcValue : string = null;
    public nvcLabel : string = null;
    public nvcGroup : string = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<SysConfig>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): SysConfig {
        let model = new SysConfig();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): SysConfig[] {
        if (objs && Array.isArray(objs)) return objs.map(SysConfig.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<SysConfig>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): SysConfig { return SysConfig.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `SysConfig`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: SysConfig) => m.id).displayName("id").required()
    .ensure((m: SysConfig) => m.nvcKey).displayName("nvcKey").required()
    .ensure((m: SysConfig) => m.nvcValue).displayName("nvcValue").required()
    .ensure((m: SysConfig) => m.nvcLabel).displayName("nvcLabel").required()
    .ensure((m: SysConfig) => m.nvcGroup).displayName("nvcGroup").required()
  .on(SysConfig);
