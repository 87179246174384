import {autoinject} from "aurelia-framework";
import {DialogCancellableOpenResult, DialogController, DialogOpenPromise, DialogService, DialogSettings} from "aurelia-dialog";
import {ColConfigInterface, EntityInterface, FilterObjectInterface} from "../../it-v-grid/interfaces";
import environment from "../../environment";
import {dateISOString} from "../../utils/ItNumeric";
import {RemoteGrid} from "./remote-grid";

@autoinject()
export class FilterDialog {
  protected controller: DialogController;
  private isReady = false;

  //estilo local a aplicar à dialog (dimensão e posição)
  private localStyle: any = "";

  private options: FilterDialogOptions;

  //elementos HTML da dialog
  private container: Element;
  private overlay: Element;
  private col: ColConfigInterface;

  //estados do dialog
  private dialogValue: string        = "";
  private dialogMultiValue: string[] = [];
  private dialogOperator: any;

  private dialogAvailableOperators: string[] = ["*"];

  private dialogClosing: boolean = false;

  private inputElement:HTMLInputElement = null;

  allOperators = {
    '='    : 'Igual',                  // 1
    '<='   : 'Menor Ou Igual',         // 2
    '>='   : 'Maior Ou Igual',         // 3
    '<'    : 'Menor',                  // 4
    '>'    : 'Maior',                  // 5
    '*'    : 'Inclui',                 // 6
    '!='   : 'Diferente',              // 7
    '!*'   : 'Não Inclui',             // 8
    '*='   : 'Começa Com',             // 9
    '=*'   : 'Termina Com',            // 10
    //extra
    '>=;<=': 'Entre',                  // 11
    //'>,<': 'ineqBetween',            // 12
    'in'   : 'Contém',                 // 13
    'lin'  : 'Contém',                 // 14 contém fragmentos
  };
  private isDirty: boolean = false;

  constructor(controller: DialogController) {
    this.controller = controller;
    if (environment.debug) console.log("[filter-dialog]", "FilterDialog constructor", this);
  }

  canActivate(p: FilterDialogOptions) {
    if (environment.debug) console.log("[filter-dialog]", "OPENING!!! canActivate", p, this);

    if (!p) {
      console.error("FilterDialog", "Falta um objeto de configuração do tipo FilterDialogOptions", p);
      return false;
    }

    this.options = p;

    //assumir defaults póstumos
    this.options.openAs = p.openAs || "popup";
    this.options.align  = p.align || "left";
    this.options.type   = p.type || "string";
    this.options.title  = p.title || "Título Filtro";

    this.options.filterOperator = p.filterOperator || "*";
    this.options.filterValue    = p.filterValue || "";

    this.options.keyVal = p.keyVal || null;

    //region no caso de se configurar o filtro como MENU
    if (this.options.openAs === "menu") {
      let offset = p.offset;
      if (offset) {
        //todo: decidir se o width é válido
        if (this.options.align == "right") {
          this.localStyle = {
            right       : "calc(100vw - " + (~~offset.left + ~~offset.width) + "px)",
            top         : (25 + ~~offset.top) + "px",
            position    : "absolute",
            borderRadius: "0",
            minWidth    : offset.width + "px"
          };
        } else {
          this.localStyle = {left: offset.left + "px", top: (25 + ~~offset.top) + "px", position: "absolute", borderRadius: "0", minWidth: offset.width + "px"};
        }
        if (environment.debug) console.log("[filter-dialog]", "offsetting dialog", this.options.offset, this.localStyle);
      }

      this.controller.settings.position = (modalContainer: Element, modalOverlay: Element) => {
        this.container = modalContainer;
        this.overlay   = modalOverlay;
        // é indicador que a classe que sabe se estamos em mobile tem de ser injetada aqui.
        this.overlay.classList.add("transparent");
        if (environment.debug) console.log("[filter-dialog]", "Setup position", this.container, this.overlay);
      };
    }
    //endregion no caso de se configurar o filtro como MENU

    // this.initInternalValues();
    return true;
  }

  activate(p) {
    if (environment.debug) console.log("[a-dialog]", "activate", p, this);
    if (environment.debug) console.log("[a-dialog]", "activate os elementos HTML já existem?", p, this.container);

    this.dialogValue = this.options.filterValue;

    this.isDirty = !!this.dialogValue;

    //megaswitch de tipos
    switch (this.options.type) {
      //tipo string - nada de especial
      case "string":
        this.dialogOperator           = this.options.filterOperator || "*";
        this.dialogAvailableOperators = ["*", "*=", "=*"];
        break;

      //tipo booleano - os pares keyVal podem ser configurados na coluna
      case "boolean":
        this.dialogOperator = "=";
        if (!this.options.keyVal || this.options.keyVal.length == 0) {
          this.options.keyVal = [{k: "Não", v: "0"}, {k: "Sim", v: "1"}, {k: "Todos", v: ""}];
        }
        break;

      //tipo numérico
      case "number":
        this.dialogOperator           = this.options.filterOperator;
        this.dialogAvailableOperators = ['<', '<=', '=', '>=', '>',];
        if (!this.dialogAvailableOperators.includes(this.dialogOperator)) this.dialogOperator = "=";
        break;

      //tipo numérico (intervalo)
      case "number-range":
        this.dialogOperator = ">=;<=";
        if (this.dialogValue) {
          this.dialogMultiValue = this.dialogValue.split(";");
        } else {
          this.dialogMultiValue = ["0", "1"];
        }
        break;

      //tipo numérico (substitui , por .; não aceita letras)
      case "number-strict":
        this.dialogOperator           = this.options.filterOperator;
        this.dialogAvailableOperators = ['<', '<=', '=', '>=', '>',];
        if (!this.dialogAvailableOperators.includes(this.dialogOperator)) this.dialogOperator = "=";
        break;

      //tipo numérico (intervalo) (substitui , por .; não aceita letras)
      case "number-range-strict":
        this.dialogOperator = ">=;<=";
        if (this.dialogValue) {
          this.dialogMultiValue = this.dialogValue.split(";");
        } else {
          this.dialogMultiValue = ["0", "1"];
        }
        break;

      //tipo data
      case "date":
        this.dialogOperator           = this.options.filterOperator;
        this.dialogAvailableOperators = ['<', '<=', '=', '>=', '>',];
        if (!this.dialogAvailableOperators.includes(this.dialogOperator)) this.dialogOperator = ">=";
        break;

      //tipo data
      case "date-range":
        this.dialogOperator = ">=;<=";
        if (this.dialogValue) {
          this.dialogMultiValue = this.dialogValue.split(";");
        } else {
          this.dialogMultiValue = [dateISOString(), dateISOString()];
        }
        break;

      //opcções conhecidas na configuração de botão
      case "static-options":
        if (this.dialogOperator == "*") this.dialogOperator = "=";
        if (!this.options.keyVal || this.options.keyVal.length == 0) {
          this.options.keyVal = [{k: "Sem valores definidos", v: ""}];
        }
        break;

      //opcções conhecidas na configuração de botão
      case "multi-choices":
        // if (this.dialogOperator == "*")
        this.dialogOperator = "in";
        if (!this.options.keyVal || this.options.keyVal.length == 0) {
          this.options.keyVal = [{k: "Sem valores definidos", v: ""}];
        }
        //this.dialogAvailableOperators = ["in"];
        if (this.dialogValue) {
          this.dialogMultiValue = this.dialogValue.split(";");
        } else {
          this.dialogMultiValue = [];
        }
        break;

      case "tags-choices":
        // if (this.dialogOperator == "*")
        this.dialogOperator = "lin";
        break;

      //region api
      case "remote-single-choices":
        // if (this.dialogOperator == "*")
        this.dialogOperator = "=";
        if (!this.options.keyVal || this.options.keyVal.length == 0) {
          console.error("Para uso do <remote-single-choices> como filtro deve existir uma configuração keyVal");
          this.options.keyVal = [{k: "Sem valores definidos", v: ""}];
        }
        break;
      //endregion api

      //region api
      case "distinct-rg-col-filter":
        this.dialogOperator = "in";
        break;
      //endregion api
    }

    // this.dialogValue = this.options.filterValue;
    this.isReady = true;
  }

  attached() {
    if (environment.debug) console.log("[filter-dialog]", "inputElement", this.inputElement);
    if (this.inputElement) {
      if (environment.debug) console.log("[filter-dialog]", "AutoFocusing");
      this.inputElement.focus();
    }
  }

  canDeactivate() {
    if (environment.debug) console.log("DIALOG_IS_TRYING_TO_CLOSE", this, this.controller);
    if (!this.dialogClosing) {
      this.aplicarFiltro();
      return false;
    }
    return true;
  }

  setDialogOperator(key) {
    this.dialogOperator = key;
    if (this.inputElement) {
      if (environment.debug) console.log("[filter-dialog]", "AutoFocusing");
      this.inputElement.focus();
    }
  }

  aplicarFiltro() {
    this.dialogClosing = true;
    if (this.dialogValue !== null && this.dialogMultiValue.length > 0) {
      //no caso de "in" e "like in" qq número de elementos está bem
      if (['in', 'lin'].includes(this.dialogOperator)) {
        this.dialogValue = this.dialogMultiValue.join(";");
      } else {
        //no caso de intervalos têm de ser 2 valores
        if (this.dialogMultiValue.length == 2) {
          this.dialogValue = this.dialogMultiValue.join(";");
        } else {
          return;
        }
      }
    }
    let resposta = {value: this.dialogValue, operator: this.dialogOperator};

    if (environment.debug) console.log("[filter-dialog]", "resposta", resposta);
    this.controller.ok(resposta);
  }

  aplicarFiltroComValor(val: string) {
    if(!this.isDirty && val == null){
      this.fecharFiltro();
    }
    else if (this.dialogValue !== val) {
      this.dialogValue = val;
      this.aplicarFiltro();
    }
  }

  fecharFiltro() {
    this.dialogClosing = true;
    this.controller.cancel();
  }

  getKvConf(key: string): { k: string, v: string, c?: string } {
    return this.options.keyVal.find(el => el.k == key);
  }

  /**
   * Factory de FilterDialogs
   *
   * ```exemplo
   * FilterDialog.Open(this.dialogService, {openAs: "menu", offset}, {overlayDismiss: true, ignoreTransitions: true})
   *  .whenClosed(r =>...
   * ```
   * @param {DialogService} ds
   * @param {FilterDialogOptions} options
   * @param {DialogSettings} dialogSettings
   * @return {DialogOpenPromise<DialogCancellableOpenResult>}
   * @constructor
   */
  static Open(ds: DialogService, options: FilterDialogOptions, dialogSettings?: DialogSettings): DialogOpenPromise<DialogCancellableOpenResult> {
    if (environment.debug) console.log("[filter-dialog]", "Factory", ds, options, dialogSettings);
    let settings = Object.assign({viewModel: FilterDialog, model: options}, dialogSettings);
    if (environment.debug) console.log("[filter-dialog]", "Opening dialog ...", settings);
    return ds.open(settings)
  }
}

/**
 * Tipo que conjuga as definições possíveis de um filter-dialog
 */
export interface FilterDialogOptions {
  //modo de exibição do dialog
  openAs?: "popup" | "menu",

  //Apenas em efeito quando esta em modo "menu"
  //alinhamento left: ou right:
  align?: "left" | "right",

  allowClear?: boolean;

  //posição do elemento a associar a dialog caso esta abra como menu
  offset?: { left: number, top: number, width?: number, height?: number },

  //título do popup
  title?: string,

  //o campo associado ao filtro (necessário para distinct-rg)
  field?: string,

  //a remoteGrid associada ao filtro (necessário para distinct-rg)
  rg?: RemoteGrid,

  //tipo de controlo a usar
  type?: "string" | "boolean" | "number" | "static-options" | "multi-choices" | string,

  //o valor aplicado ao filtro na altura da abertura do menu|popup
  filterValue?: any,

  //o operador aplicado ao filtro na altura da abertura do menu|popup
  filterOperator?: any,

  //multi-valor? to do: ver se é mesmo preciso
  //multiValue?: boolean,
  keyVal?: { k: string, v: string, c?: string }[]
}
