import {NavigationInstruction, Next, Redirect, Router, RouterConfiguration} from "aurelia-router";
import environment from "./environment";
import {mainRoutes} from "./app-routes";
import {autoinject, computedFrom, Aurelia} from "aurelia-framework";
import {GlobalServices} from "./services/global-services";
import {ItNavigation, NavStruct} from "./it-features/it-navigation/it-navigation";
import {ConfirmacaoDialog} from "./dialogs/confirmacao-dialog";

@autoinject()
export class App {
  message                = 'App';
  private router: Router;
  private versao         = versao;
  public isBusy: boolean = true;

  private nav: ItNavigation;
  private parts: string[]         = [];
  private navigation: NavStruct[] = [];

  // private expanded:boolean = false;

  @computedFrom("app.auth.authenticated")
  get authenticated(): boolean {
    if (environment.debug) console.log("[app]", "get authenticated", this);
    return this.app.auth.authenticated;
  }

  @computedFrom("nav.mode")
  get expanded(): boolean {
    if (environment.debug) console.log("[app]", "get expanded", this);
    return this.nav && (this.nav.mode == "open");
  }

  constructor(private aurelia: Aurelia, private app: GlobalServices) {
    if (environment.debug) {
      console.log("[app]", "constructor", "Aurelia app started", this.aurelia, this.versao);
      console.log("[app]", "Aplicação registada globalmente em window.app...");
      window["app"] = this;
    }
  }

  /**
   * Quando há uma autenticação
   */
  attached() {
    this.app.auth.authenticated && this.nav && this.nav.makeActive(this.parts);
    var self = this;
    this.app.api.postProcessed('api/config/update');
    //this.app.api.postProcessed('api/config/inactivar');
    //setInterval(() => {
    //  return this.app.api.postProcessed('api/config/update')
    //}, 10000);
  }

  /**
   * Configuração do router da aplicação
   * @param {RouterConfiguration} config
   * @param {Router} router
   */
  configureRouter(config: RouterConfiguration, router: Router) {
    if (environment.debug) console.log("[app]", "configureRouter", config, router);
    this.navigation = NavStruct.translateRoutes(mainRoutes, 0);

    let handleUnknownRoutes = (instruction) => {
      return {route: 'not-found', moduleId: 'routes/not-found'};
    };

    config.mapUnknownRoutes(handleUnknownRoutes);

    //fase de pré-activação: idealmente este deve ser ativado sempre que acontece um post + navegação, evitando assim o flicker
    const preActivateStep = {
      run: (navigationInstruction: NavigationInstruction, next: Next) => {
        this.isBusy = true;
        return next();
      }
    };

    //fase de auth
    let step = {
      run: (navigationInstruction: NavigationInstruction, next) => {
        if (environment.debug) console.log("[app]", "intercepted navigation", this, navigationInstruction, navigationInstruction.fragment);
        if (navigationInstruction.getAllInstructions().some(i => i.config.auth)) {
          let isLoggedIn = this.authenticated;
          if (!isLoggedIn) {
            //memorizar o fragmento que se pretende visualizar sem login
            if (this.app) this.app.redirectFragment = navigationInstruction.fragment;
            return next.cancel(new Redirect('login', ));
            //return next();
          } else {
            this.app.api.getProcessed('api/jwt/is-auth')
              .then(response => {
                if (response === false)
                  this.app.auth.logout();
              });
          }
        }
        return next();
      }
    };

    //fase de pós render
    const postRenderStep = {
      run: (navigationInstruction: NavigationInstruction, next: Next) => {
        if (environment.debug) console.log("[app]", "postRenderStep", navigationInstruction.getAllInstructions(), next);

        let frg    = navigationInstruction.fragment;
        //substituir a primeira barra
        frg        = frg.replace("/", "");
        //substituir a primeira barra
        let parts  = frg.split("/");
        // if (environment.debug) console.log("[app]", "parts", parts);
        this.parts = parts;

        if (this.nav) {
          this.nav.makeActive(parts);
        }
        this.isBusy = false;

        //cada parte corresponde a um nível de navegação
        return next();
      }
    };

    config.title = 'Jornal do Fundão | Assinaturas';
    config.addPreActivateStep(preActivateStep);
    config.addAuthorizeStep(step);
    config.addPostRenderStep(postRenderStep);
    // config.addPipelineStep('authorize', step);
    config.map(mainRoutes);
    this.router = router;
  }

  /**
   * Método placeholder para expansão / contração da barra de menu lateral
   */
  toggleNav() {
    this.nav && this.nav.toggleMode();
  }

  /**
   * método placeholder para implementação de megaSearch (tinha de se lhe chamar qualquer coisa)
   */
  megaSearch() {
    if (environment.debug) console.log("[app]", "megaSearch", this);
  }

  logout() {
    if (environment.debug) console.log("[app]", "logout", this);

    return this.app.ds.open({viewModel: ConfirmacaoDialog, model: "Tem a certeza que deseja sair?"})
      .whenClosed(resp => {
        if (!resp.wasCancelled) {
          this.app.auth.logout();
          this.router.navigate("", {replace: true});
        } else {}
      });

  }
}

/**
 * Ainda está para se ver se a versão (comum)
 * @type {string}
 */
export let versao = "0.0.0";
