import {autoinject, bindable} from "aurelia-framework";
import {ValidationController} from "aurelia-validation";
import environment from "../../environment";
import {RouteConfig} from "aurelia-router";
import {mainRoutes} from "../../app-routes";

@autoinject()
export class ItNavigation {
  // protected subscription: Subscription;
  public isBusy: boolean     = true;
  @bindable() debug: boolean = true;

  @bindable() brandHtml: string = '<b>Jornal do Fundão</b>';

  @bindable navigation: NavStruct[] = [];

  public mode: NavigationMode = NavigationMode.open;

  //elementos
  private containerDiv: HTMLDivElement;
  private element: HTMLElement;

  private modifier: string = "";

  constructor() {
    if (this.debug) console.log("[it-navigation]", "constructor", this);

    document.addEventListener("keyup", this.processKey);

    if(window.localStorage) {
      this.mode = (JSON.parse(localStorage.getItem("it-navigation_mode")) as NavigationMode) || NavigationMode.open;
    }

    // this.navigation = [
    //   new NavStruct({icon: "fa fa-tachometer-alt", nome: "Link direto", route: "blankpage"}),
    //   new NavStruct({icon: "fa fa-tachometer-alt", nome: "blankpage1", route: "blankpage1"}),
    //   new NavStruct({
    //     icon: "fa fa-times", nome: "Grupo", children: [
    //       new NavStruct({icon: "fa fa-check", nome: "Sub Link 1", route: "#link1"}),
    //       new NavStruct({
    //         icon: "fa fa-check", nome: "Sub Link 2", route: "#link2", children: [
    //           new NavStruct({icon: "fa fa-check", nome: "Sub Link 1", route: "#link1"}),
    //           new NavStruct({icon: "fa fa-check", nome: "Sub Link 2", route: "#link2"}),
    //         ]
    //       }),
    //     ]
    //   }),
    //   new NavStruct({icon: "fa fa-tachometer-alt", nome: "Link direto", route: "#"}),
    // ];

    // this.navigation = NavStruct.translateRoutes(mainRoutes);
  }

  attached() {
    this.element.onkeyup = (e) => {
      if (environment.debug) console.log("[it-navigation]", "onkeyup", e);
      return true;
    };
  }

  processKey = (e: KeyboardEvent) => {
    if (environment.debug) console.log("[it-navigation]", "onkeyup", e, this);

    if (e.key == "Escape") {
      if (this.modifier == "draw") {
        this.modifier = "";
      } else {
        this.modifier = "draw";
      }
    }
    return true;
  };

  toggleNav() {
    if (this.debug) console.log("[it-navigation]", "toggleNav", this.mode);
  }

  /**
   * função de debug
   * @param what
   */
  log(what) {
    console.log(what);
  }

  toggleMode() {
    switch (this.mode) {
      case NavigationMode.open:
        //   return this.mode = NavigationMode.compact;
        // case NavigationMode.compact:
        this.modifier = "";
        localStorage.setItem("it-navigation_mode", JSON.stringify(NavigationMode.closed));
        return this.mode = NavigationMode.closed;
      case NavigationMode.closed:
        localStorage.setItem("it-navigation_mode", JSON.stringify(NavigationMode.open));
        return this.mode = NavigationMode.open;
    }
  }

  public makeActive(parts: string[]) {
    this.deactivateAll();
    this.modifier = "";
    if (environment.debug) console.log("[it-navigation]", "makeActive", parts);

    if (parts.length > 0) {
      let base = this.navigation.find(el => el.route == parts[0]);
      if (!base) return;
      base.active = true;
      if (base.children.length > 0) {
        base.open = true;
        if (parts[1] !== null && parts[1] != undefined) {
          let lvl1    = base.children.find(el => el.route == parts[1]);
          if(lvl1) lvl1.active = true;

          //todo: se forem necessários mais níveis
        }
      }
    }
  }

  /**
   * Marca todas as routes como não activas
   */
  public deactivateAll() {
    this.navigation.forEach(el => el.deactivate());
  }
}

enum NavigationMode {
  open    = "open",
  compact = "compact",
  closed  = "closed"
}

/**
 * Estrutura a usar para representar a navegação
 */
export class NavStruct {
  icon: string   = "fa fa-times";
  nome: string   = "Nome";
  titulo: string = "Título";
  route: string  = "#";

  children: NavStruct[] = [];
  active: boolean       = false;
  open: boolean         = false;
  level: number         = 0;

  public constructor(fields?: Partial<NavStruct>,) {
    if (fields) Object.assign(this, fields);
  }

  public toggleOpen() {
    this.open = !this.open;
  }

  public deactivate() {
    this.active = false;
    this.open   = false;
    this.children.forEach(el => el.deactivate())
  }

  public composeRoute(prefix: string): string {
    return prefix + "/" + this.route;
  }

  public static translateRoute(route: RouteConfig, level:number = 0): NavStruct {
    let ns  = new NavStruct();
    ns.nome = ns.titulo = route.title || route.name;
    if (Array.isArray(route.route)) {
      ns.route = route.route[0];
    } else {
      ns.route = "" + route.route;
    }
    ns.level = level;
    if (route.settings) {
      ns.icon   = route.settings.icon || "fa fa-times";
      ns.titulo = route.settings.titulo;
      if (route.settings.children) {
        ns.children = NavStruct.translateRoutes(route.settings.children, level + 1);
      }
    }

    return ns;
  }

  public static translateRoutes(routes: RouteConfig[], level:number = 0): NavStruct[] {
    if (Array.isArray(routes) && routes.length > 0)
      return routes.filter(r => r.nav).map(r => NavStruct.translateRoute(r, level));
    return [];
  }

}
