import {autoinject, bindable, bindingMode, computedFrom} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {Api} from "../../services/api";
import Choices = require("choices.js");

/**
 * Um CE com choices de base que se comporta como o select2 mas bem.
 */
@autoinject()
export class TagsChoices {
    @bindable debug: boolean = false;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value: any[] = [];
    @bindable config: Choices.Options = {
        placeholderValue: 'Insira uma nova Tag...',
        addItemText: (value) => {
            return `Carregue Enter para adicionar <b>"${value}"</b>`;
        },
        shouldSortItems      : true,
        duplicateItems: true,
        delimiter: ';',
        // searchFloor     : 3,     // or whatever value makes sense for your data / API
        // searchChoices   : false, // see bullet point 2 above
        // duplicateItems  : false,  // this is ignored, see bullet point 3 above
        removeItemButton: true
    };

    /**
     * O valor da escolha (o id, código)
     * é tratado pelo plugin por value
     * @type {string}
     */
    @bindable valueKey: string = "id";

    /**
     * O texto a aparecer na escolha
     * é tratado pelo plugin por label
     * @type {string}
     */
    @bindable labelText: string = "text";

    /**
     * Se estiver definida e uma pesquisa não contiver resultados é invocada.
     */
    @bindable creationCallback: any;

    //??? será que é útil?
    public clearSearchOnSelect: boolean = true;

    //a referência da instância do plugin
    public choices: any | Choices;

    //o elemento HTML select(multiple)
    private inputElem: HTMLInputElement = null;

    attached() {
        if (this.debug) console.log("[tags-choices]", "attached", "inicializar o plugin", this.config);
        this.choices = new Choices(this.inputElem, this.config);
        //window["c"] = this.choices;
    }

    //o value é um array de strings com as keys das opções selecionadas muda em duas ocasiões
    //1. inicialização do plugin
    //2. escolha de um elemento
/*
    valueChanged(newValue, oldValue) {
        if (this.debug) console.log("[tags-choices]", "valueChanged", newValue, oldValue);
        if (newValue) {
            if (!this.choices) {
        //         if (this.debug) console.log("[tags-choices]", "valueChanged", "inicialização do plugin");
                //sem ir ao servidor não se consegue adivinhar qual a label do item em questao,
                // estas opcções são embutidas no objeto de configuração com tratamento igual
                this.config.choices = newValue.map(el => ({value: el, label: el, selected: true, customProperties: null}));
            } else {
        //         if (this.debug) console.log("[tags-choices]", "valueChanged", "escolha de um elemento");
            }
        }
    }

    handleChange($event) {
        if (this.debug) console.log("[tags-choices]", "handleChange", $event);
        let data = $event.detail.value;
        if (this.debug) console.log("[tags-choices]", "handleChange", "selected", data);

        if(this.creationCallback) this.creationCallback();
        // if (data != this.innerValue) {
        //     this.innerValue = "" + data;
        // } else {
        //     this.innerValue = "";
        // }
    }
*/

}
