import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ItResponse} from "../models/VmWrapper";
import environment from "../environment";

@autoinject()
export class ConfirmacaoOpcoesDialog {
  // protected controller: DialogController;
  private response: ItResponse;

  constructor(private controller: DialogController) {
    // console.log("ConfirmacaoDialog constructor");
    if (environment.debug) console.log("[confirmacao-opcoes-dialog]", "constructor");
    this.controller = controller;
  }

  // canDeactivate(){
  //   $(".js-confirm-dialog").off("keypress")
  // }

  canActivate(model: { itr: ItResponse }) {
    if (!(model && model.itr)) {
      console.error("[confirmacao-opcoes-dialog]", "Não foi possível abrir o dialog");
      return false;
    }
    this.response = model.itr;
    if (environment.debug) console.log("[confirmacao-opcoes-dialog]", "ItResponse", this.response);
  }

  usaOpcao(opcao) {
    this.controller.ok(opcao);
  }
}
