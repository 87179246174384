import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {level_1} from "../../mobile-routes";
import {MobileBreadcrumb, MobileNavStruct} from "../../it-features/it-mobile-navigation/it-mobile-menu";
import environment from "../../environment";

@autoinject()
export class Index {
  message: string;
  navigation: any;
  breadcrumbs: any;

  constructor(private app: GlobalServices) {
    if (environment.debug) console.log("[index-level-1]", "constructor");
    this.navigation = MobileNavStruct.translateRoutes(level_1);
  }

  activate(p, a, b) {
    if (environment.debug) console.log("[level-1|index]", "activate", a, b);
    this.breadcrumbs = MobileBreadcrumb.buildBreadcrumbs(b);
  }
}