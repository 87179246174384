// Auto-gerado com typewriter
import { computedFrom } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { BaseViewModel } from "./BaseViewModel";
import { VmWrapper } from "./VmWrapper";
//imports locais
import { Assinante } from './Assinante';
import { CondicoesPagamento } from './CondicoesPagamento';
import { MeioPagamento } from './MeioPagamento';
import { MovimentosCaixaPagamentos } from './MovimentosCaixaPagamentos';
import { AssinanteAssinatura } from './AssinanteAssinatura';
import { AssinaturaPagamento } from "./AssinaturaPagamento";
//export classe
export class Pagamento extends BaseViewModel {
  public id: number = 0;
  public idAssinante: number = 0;
  public nvcMeioPagamento: string = null;
  public decValor: number = 0;
  public dtmData: string = null;
  public _dtmData: Date = new Date();
  public nvcCodigoInterno: string = "00000000-0000-0000-0000-000000000000";
  public idCodPagamentoMigracao: number = null;
  public intNumEmissoes: number = 0;
  public nvcNumCheque: string = null;
  public nvcRefTransferencia: string = null;
  public idCondicaoPagamento: number = null;
  public bitAnulado: boolean = null;
  public idPagamentoMultibanco: string = null;
  public nvcFaturaErp: string = null;
  public nvcReciboErp: string = null;
  public nvcNumeroFaturaErp: string = null;
  public nvcNumeroReciboErp: string = null;
  public bitFaturaRecibo: boolean = null;
  public bitLiquidado: boolean = null;
  public bitFaturaEmitidaErp: boolean = null;
  public bitReciboEmitidaErp: boolean = null;
  public nvcNumRequisicao: string = null;
  public nvcNumCabimento: string = null;
  public nvcNumCompromisso: string = null;
  public idAssinanteNavigation: Assinante = null;
  public idCondicaoPagamentoNavigation: CondicoesPagamento = null;
  public nvcMeioPagamentoNavigation: MeioPagamento = null;
  public assinaturaPagamento: AssinaturaPagamento[] = [];
  public movimentosCaixaPagamentos: MovimentosCaixaPagamentos[] = [];

  public lstIdsAssinaturas: number[] = [];
  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Pagamento>, ) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Pagamento {
    let model = new Pagamento();
    model.setState(obj);
    if (obj.nvcMeioPagamentoNavigation) model.nvcMeioPagamentoNavigation = MeioPagamento.fromPOJSO(obj.nvcMeioPagamentoNavigation);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Pagamento[] {
    if (objs && Array.isArray(objs)) return objs.map(Pagamento.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Pagamento>({ payload: this, confirmLevel: (+cl || 0) });
  }

  public cloneInstance(): Pagamento { return Pagamento.fromPOJSO(this.stateToPOJSO()); }
  //endregion

  public toString() {
    return `Pagamento`;
  }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Pagamento) => m.id).displayName("id").required()
  .ensure((m: Pagamento) => m.idAssinante).displayName("idAssinante").required()
  .ensure((m: Pagamento) => m.nvcMeioPagamento).displayName("Meio de Pagamento").required()
  .ensure((m: Pagamento) => m.decValor).displayName("Valor").required().satisfies(x => x > 0).withMessage("Valor de pagamento inválido")
  .ensure((m: Pagamento) => m.dtmData).displayName("Data").required()
  .ensure((m: Pagamento) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
  .ensure((m: Pagamento) => m.idCondicaoPagamento).displayName("Condição de Pagamento").required()
  .ensure((m: Pagamento) => m.idAssinanteNavigation).displayName("idAssinanteNavigation").required()
  .ensure((m: Pagamento) => m.nvcMeioPagamentoNavigation).displayName("nvcMeioPagamentoNavigation").required()
  .ensure((m: Pagamento) => m.movimentosCaixaPagamentos).displayName("movimentosCaixaPagamentos").required()
  .on(Pagamento);
