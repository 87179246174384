import {ValidationMessageProvider, ValidationMessages, ValidationRules} from 'aurelia-validation';
import {Expression} from 'aurelia-binding';
import environment from "../environment";
import {Api} from "../services/api";

export const validationMessages: ValidationMessages = {
  /**
   * The default validation message. Used with rules that have no standard message.
   */
  "default"           : `\${$displayName} não é válido.`,
  required            : `\${$displayName} é obrigatório.`,
  matches             : `\${$displayName} não segue a formatação necessária.`,
  email               : `\${$displayName} não é um email válido.`,
  minLength           : `\${$displayName} deve conter pelo menos \${$config.length} caracter\${$config.length === 1 ? '' : 'es'}.`,
  maxLength           : `\${$displayName} não pode conter mais do que \${$config.length} character\${$config.length === 1 ? '' : 'es'}.`,
  minItems            : `\${$displayName} deve conter pelo menos \${$config.count} elemento\${$config.count === 1 ? '' : 's'}.`,
  maxItems            : `\${$displayName} não pode conter mais do que \${$config.count} elemento\${$config.count === 1 ? '' : 's'}.`,
  equals              : `\${$displayName} deve ser igual a \${$config.expectedValue}.`,
  identity            : `\${$displayName} identity`,
  unique              : `Já existe um \${$displayName} com esse valor.`,
  validId             : `\${$displayName} tem de ter valor escolhido`,
  greaterThan         : `\${$displayName} tem de ser maior do que \${$config.limit}`,
  greaterThanOrEqual  : `\${$displayName} tem de ser maior ou igual do que \${$config.limit}`,
  'menorQueReferencia': `\${$config.hint}`,
  //sopocal exclusivo
  maiorQueRetencao    : `\${$displayName} tem de ser maior que o valor da retenção.`,
  menorQueGrot        : '${$displayName} deve ser menor que o valor a pagamento do movimento da Guia de Receita O.T.'
};

// fonte: https://github.com/aurelia/validation/issues/407
export function setupValidationRules(api: Api = null) {
  if (environment.debug) console.log("[custom-rules]", "setupValidationRules", "api:", api);
  ValidationRules.customRule(
    'digits',
    (value, obj) => value === null || value === undefined || value.length === 0 || /^[0-9]+$/.test(value),
    `\${$displayName} apenas pode conter dígitos`
  );

  ValidationRules.customRule(
    'integerRange',
    (value, obj, min, max) => value === null || value === undefined
      || Number.isInteger(value) && value >= min && value <= max,
    `\${$displayName} deve ser um número compreendido entre \${$config.min} e \${$config.max}.`,
    (min, max) => ({min, max})
  );

  ValidationRules.customRule(
    'greaterThan',
    (value, obj, limit) => value === null || value === undefined || +value > +limit,
    `\${$displayName} tem de ser maior que \${$config.limit}`,
    (limit) => ({limit})
    //(min, max) => ({ min, max })
  );

  ValidationRules.customRule(
    'greaterThanOrEqual',
    (value, obj, limit) => value === null || value === undefined || +value >= +limit,
    `\${$displayName} tem de ser maior ou igual que`,
    (limit) => ({limit})
    //(min, max) => ({ min, max })
  );

  ValidationRules.customRule(
    'lessThan',
    (value, obj, limit) => value === null || value === undefined || +value < +limit,
    `\${$displayName} tem de ser menor que \${$config.limit}`,
    (limit) => ({limit})
    //(min, max) => ({ min, max })
  );

  ValidationRules.customRule(
    'lessThanOrEqual',
    (value, obj, limit) => value === null || value === undefined || +value <= +limit,
    '${$displayName} tem de ser menor ou igual que ${$config.limit}',
    (limit) => ({limit})
    //(min, max) => ({ min, max })
  );

  ValidationRules.customRule(
    'validId',
    (value, obj) => value === null || value === undefined || ~~value > 0,
    '${$displayName} tem de ter valor'
  );

  ValidationRules.customRule(
    'menorQueReferencia',
    (value, obj: any, locator: string, hint: string) => {
      let valorComparar = obj[locator];
      if (environment.debug) console.log("[custom-rules]", "menorQueReferencia", value, obj, locator, hint, valorComparar);
      return value === null
        || value === undefined
        || value === ''
        || value <= valorComparar
    },
    '${$displayName} deve ser menor ou igual que o valor ${$config.hint}'
  );

  if (api) {
    ValidationRules.customRule(
      'unique',
      (value, obj, property, accessPoint) => {
        if (environment.debug) console.log("[custom-rules]", "setupValidationRules", "unique", value, obj, api);
        return api.getProcessed(accessPoint, {property: property, value: value})
          .then(r => {
            if (environment.debug) console.log("[custom-rules]", r);
            return value === null || value === undefined || +r <= 0;
          }).catch(err => {
            if (environment.debug) console.log("[custom-rules]", err);
            return false;
          });
        // return Promise.resolve(value === null || value === undefined || false);
      },
      `\${$displayName} unique!`,
      (property, accessPoint) => ({property, accessPoint})
      //(min, max) => ({ min, max })
    );

    ValidationRules.customRule(
      'identity',
      (value, obj) => {
        if (environment.debug) console.log("[custom-rules]", "setupValidationRules", value, obj, api);
        return Promise.resolve(value === null || value === undefined || false);
      },
      `\${$displayName} identity!`,
      (property, accessPoint) => ({property, accessPoint})
      //(min, max) => ({ min, max })
    );
  }

  //overrides às mensagens e meio de tradução iniciais
  //console.warn(ValidationMessageProvider.prototype.getMessage);
  (ValidationMessageProvider.prototype as any).standardGetMessage = ValidationMessageProvider.prototype.getMessage;
  (ValidationMessageProvider.prototype as any).getMessage         = function (key: string): Expression {
    // console.log("ValidationMessageProvider", key, this);
    let message: string;
    if (key in validationMessages) {
      message = validationMessages[key];
    } else {
      message = validationMessages['default'];
    }
    return this.parser.parse(message);
  };

  (ValidationMessageProvider.prototype as any).getDisplayName = function (propertyName, displayName) {
    //console.log("getDisplayName", propertyName, displayName);
    if (displayName !== null && displayName !== undefined) {
      return displayName;
    }

    // split on upper-case letters.
    const words = propertyName
      .replace("nvc", "")
      .replace("dtm", "")
      .replace("int", "")
      .replace("bit", "")
      .replace("flt", "")
      .replace("id", "")
      .split(/(?=[A-Z])/).join(' ');
    return words.charAt(0).toUpperCase() + words.slice(1);
  };
}