import {NavigationInstruction, Next, Redirect, Router, RouterConfiguration} from "aurelia-router";
import environment from "./environment";
import {Aurelia, autoinject, computedFrom} from "aurelia-framework";
import {GlobalServices} from "./services/global-services";
import {versao} from "./app";
import {mobileRoutes} from "./mobile-routes";
import {MobileNavStruct} from "./it-features/it-mobile-navigation/it-mobile-menu";

@autoinject()
export class MobileApp {
  public message = 'App';
  private router: Router;
  private versao = versao + versaoMobile;
  public navigation: MobileNavStruct;

  @computedFrom("app.auth.authenticated")
  get authenticated(): boolean {
    if (environment.debug) console.log("[mobile-app]", "get authenticated", this.app.auth.authenticated);
    return this.app.auth.authenticated;
  }

  constructor(private aurelia: Aurelia, private app: GlobalServices) {
    if (environment.debug) console.log("[mobile-app]", "constructor", "Aurelia app started", this.aurelia, this.versao);
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    // if (environment.debug) console.log("[mobile-app]", "configureRouter", config, router);
    let step = {
      run: (navigationInstruction: NavigationInstruction, next) => {
        if (environment.debug) console.log("[mobile-app]", "navigationInstruction", navigationInstruction.getAllInstructions());
        if (navigationInstruction.getAllInstructions().some(i => i.config.auth)) {
          let isLoggedIn = this.authenticated;
          if (!isLoggedIn) {
            return next.cancel(new Redirect('login'));
          }
        }
        return next();
      }
    };

    this.navigation = MobileNavStruct.translateRoutes(mobileRoutes);
    config.title    = 'Mobile-App';
    config.addAuthorizeStep(step);
    config.map(mobileRoutes);
    this.router = router;
  }
}

/**
 * Ainda está para se ver se a versão (comum)
 * @type {string}
 */
export let versaoMobile = "m0";
