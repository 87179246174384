import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../../../services/global-services";
import environment from "../../../../environment";
import {level_3} from "../../../../mobile-routes";
import {MobileBreadcrumb, MobileNavStruct} from "../../../../it-features/it-mobile-navigation/it-mobile-menu";

@autoinject()
export class Index {
  message: string;
  navigation: any;
  breadcrumbs: any;

  constructor(private app: GlobalServices) {
    if (environment.debug) console.log("[index-level-3]", "constructor");
    this.navigation = MobileNavStruct.translateRoutes(level_3);
  }

  activate(p, a, b) {
    if (environment.debug) console.log("[level-3|index]", "activate", a, b);
    this.breadcrumbs = MobileBreadcrumb.buildBreadcrumbs(b);
  }
}