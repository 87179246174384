import {autoinject, Aurelia} from "aurelia-framework";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";


//Este controlador representa o verdadeiro entrypoint da aplicação
@autoinject()
export class Index {
  message: string;
  
  constructor(private app: GlobalServices) {
    if(environment.debug) console.log("[index]", "constructor");
    this.message = 'Hello world';
  }

  //todo: configurar subRouter
  logout(){
    if(environment.debug) console.log("[index]","LOGOUT");
    this.app.auth.logout();
  }
}