import {FilterObjectInterface, SortObjectInterface, EntityInterface} from "../../it-v-grid/interfaces";

/**
 * Interface para fazer pedido de dados ao servidor. Pode ser usado para obtenção de uma janela de resultados.
 */
export interface ServerPayloadInterface {
  permanent: FilterObjectInterface[],
  filters: FilterObjectInterface[],
  sort: SortObjectInterface[],
  start: number,
  length: number,
  draw: number,
  top?: number
}

/**
 * Encapsula a resposta obtida do servidor (assemelha-se em tudo à do datatables)
 */
export interface ServerResponseInterface {
  draw: number,
  recordsTotal: number,
  recordsFiltered: number,
  data: any[],
  aggregation: any,
}

/**
 * Encapsula o intuito de uma acção
 * https://atena/j3lp/documentacao/wikis/remote_grid
 */
export interface ActionFromRemoteGrid {
  codigoTabela:string,
  event:Event,
  rowRef?: EntityInterface, //guarda os dados/modelo que são alterados
  tempRef?: EntityInterface,//guarda os dados/modelo originais
  field?: string,
  instance?: any
}

/**
 * constante que pré-define uma série de strings para facilitar a triagem das acções
 */
export const RemoteGridActions = {
  //células
  RG_CELL_CHANGE: "RG_CELL_CHANGE",
  //Linhas
  RG_ROW_CLICK: "RG_ROW_CLICK",
  RG_ROW_DBL_CLICK: "RG_ROW_DBL_CLICK",
};