// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {MovimentosCaixa} from './MovimentosCaixa';
import {Pagamento} from './Pagamento';
//export classe
export class MovimentosCaixaPagamentos extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public idMovimentoCaixa : number = 0;
    public idPagamento : number = 0;
    public nvcCodigoInterno : string = "00000000-0000-0000-0000-000000000000";
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    public idMovimentoCaixaNavigation : MovimentosCaixa = null;
    public idPagamentoNavigation : Pagamento = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<MovimentosCaixaPagamentos>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): MovimentosCaixaPagamentos {
        let model = new MovimentosCaixaPagamentos();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): MovimentosCaixaPagamentos[] {
        if (objs && Array.isArray(objs)) return objs.map(MovimentosCaixaPagamentos.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<MovimentosCaixaPagamentos>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): MovimentosCaixaPagamentos { return MovimentosCaixaPagamentos.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `MovimentosCaixaPagamentos`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: MovimentosCaixaPagamentos) => m.id).displayName("id").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.idMovimentoCaixa).displayName("idMovimentoCaixa").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.idPagamento).displayName("idPagamento").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.timestamp).displayName("timestamp").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.idMovimentoCaixaNavigation).displayName("idMovimentoCaixaNavigation").required()
    .ensure((m: MovimentosCaixaPagamentos) => m.idPagamentoNavigation).displayName("idPagamentoNavigation").required()
  .on(MovimentosCaixaPagamentos);
