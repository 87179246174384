/**
 * Created by herna on 5/9/2017.
 */
import {activationStrategy, RouteConfig} from "aurelia-router";


export const caixaRoutes: RouteConfig[] = [
  //@formatter:off
  { route: 'caixa-movimentos-lista', name: 'caixa-movimentos-lista', moduleId: 'routes/caixa/caixa-movimentos-lista', nav: true, auth: true, title: 'Movimentos de Caixa', settings: { icon: "fa fa-list", titulo: "Lista de assinante" } },
  { route: 'caixa-movimentos-detalhe', name: 'caixa-movimentos-detalhe', moduleId: 'routes/caixa/caixa-movimentos-detalhe', nav: false, auth: true, title: 'Movimento de Caixa - Resumo', settings: { icon: "fas fa-euro-sign", titulo: "Ficha de assinante" }, activationStrategy : activationStrategy.invokeLifecycle },
  //@formatter:on
];

export const assinantesRoutes: RouteConfig[] = [
  //@formatter:off
  { route: 'assinantes-lista', name: 'assinantes-lista', moduleId: 'routes/assinantes/assinantes-lista', nav: true, auth: true, title: 'Lista de Assinantes', settings: { icon: "fa fa-list", titulo: "Lista de assinante" } },
  { route: 'assinantes-detalhe', name: 'assinantes-detalhe', moduleId: 'routes/assinantes/assinantes-editar', nav: true, auth: true, title: 'Criar Assinante', settings: { icon: "fa fa-plus", titulo: "Ficha de assinante" } },
  { route: 'debitos-lista', name: 'debitos-lista', moduleId: 'routes/assinantes/debitos-lista', nav: true, auth: true, title: 'Lista de Débitos', settings: { icon: "fa fa-list", titulo: "Lista de débitos" } },
  //@formatter:on
];

export const tabelasRoutes: RouteConfig[] = [
  //@formatter:off
  { route: 'tipos-assinatura-lista', name: 'tipos-assinatura-lista', moduleId: 'routes/tabelas/tipos-assinatura-lista', nav: true, auth: true, title: 'Lista de tipos de assinatura', settings: { icon: "far fa-newspaper", titulo: "Lista de tipos de assinatura" } },
  { route: 'meios-pagamento-lista', name: 'meios-pagamento-lista', moduleId: 'routes/tabelas/meios-pagamento-lista', nav: true, auth: true, title: 'Lista de meios de pagamento', settings: { icon: "fas fa-euro-sign", titulo: "Lista de meios de pagamento" } },
  { route: 'condicoes-pagamento-lista', name: 'condicoes-pagamento-lista', moduleId: 'routes/tabelas/condicoes-pagamento-lista', nav: true, auth: true, title: 'Lista de condições de pagamento', settings: { icon: "fas fa-euro-sign", titulo: "Lista de condições de pagamento" } },
  { route: 'paises-lista', name: 'paises-lista', moduleId: 'routes/tabelas/paises-lista', nav: true, auth: true, title: 'Lista de países', settings: { icon: "fa fa-list", titulo: "Lista de países" } },
  { route: 'titulos-lista', name: 'titulos-lista', moduleId: 'routes/tabelas/titulos-lista', nav: true, auth: true, title: 'Lista de títulos', settings: { icon: "fa fa-list", titulo: "Lista de títulos" } },
  //@formatter:on
];


export const edicaoRoutes: RouteConfig[] = [
  //@formatter:off
  { route: 'edicao-fechar', name: 'edicao-fechar', moduleId: 'routes/edicao/edicao-fechar', nav: true, auth: true, title: 'Fechar Edição', settings: { icon: "far fa-newspaper", titulo: "Fechar Edição" } },
  //@formatter:on
];


export const mainRoutes: RouteConfig[] = [
//@formatter:off
{ route: '',              name: 'web',            moduleId: 'routes/bundle-web/index',                         nav: true,      title: 'Início',      auth: true },
{ route: 'inicio',        name: 'web_inicio',     moduleId: 'routes/bundle-web/index',                         nav: false,     title: 'Início',      auth: true },
{ route: 'login',         name: 'login',          moduleId: 'routes/login',                             nav: false},
{ route: 'caixa',         name: 'caixa',          moduleId: 'routes/caixa/caixa-component',             nav: true,      title: 'Caixa',       auth: true,   settings:{children: caixaRoutes, icon:'fas fa-euro-sign'}} ,
{ route: 'assinantes',    name: 'assinantes',     moduleId: 'routes/assinantes/assinantes-component',   nav: true,      title: 'Assinantes',  auth: true,   settings:{children: assinantesRoutes, icon:'fas fa-pen-nib'}} ,
{ route: 'tabelas',       name: 'tabelas',        moduleId: 'routes/tabelas/tabelas-component', nav: true, title: 'Tabelas', auth: true, settings: { children: tabelasRoutes, icon: 'fas fa-cog' } },
{ route: 'edicao',        name: 'edicao',         moduleId: 'routes/edicao/edicao-component', nav: true, title: 'Edição', auth: true, settings: { children: edicaoRoutes, icon: 'far fa-newspaper' } },
  { route: 'multibanco', name: 'multibanco', moduleId: 'routes/multibanco/index', nav: true, title: 'Multibanco', auth: true, settings: { icon: 'fas fa-euro-sign' } },

//@formatter:on
];
