// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./../BaseViewModel";
import {VmWrapper} from "./../VmWrapper";
//export classe
export class CancelarAssinatura extends BaseViewModel{
    public idAssinatura: number = 0;
    public decValor: number = 0;
    public nvcObservacoes: string = null;
    public intUltimaEdicao: number = null;

    public intPrimeiraEdicao: number = null;
    public decValorEdicao: number = null;
    public intNumEdicoes: number = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<CancelarAssinatura>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): CancelarAssinatura {
      let model = new CancelarAssinatura();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): CancelarAssinatura[] {
      if (objs && Array.isArray(objs)) return objs.map(CancelarAssinatura.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
      return new VmWrapper<CancelarAssinatura>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): CancelarAssinatura { return CancelarAssinatura.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
      return `CancelarAssinatura`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: CancelarAssinatura) => m.idAssinatura).displayName("idAssinatura").required()
    .ensure((m: CancelarAssinatura) => m.decValor).displayName("decValor").required()
    .ensure((m: CancelarAssinatura) => m.nvcObservacoes).displayName("nvcObservacoes").required()
    .ensure((m: CancelarAssinatura) => m.intUltimaEdicao).displayName("intUltimaEdicao").required()
  .on(CancelarAssinatura);
