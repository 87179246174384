import {bindable} from 'aurelia-framework';
import environment from "../../environment";

export class Keypad {
    @bindable value;
    @bindable placeholder = "Digite o código";
    innerValue: string = '';

    created() {
        if (environment.debug) console.log("[keypad]", "YELLOW");
        // this.innerValue = `${this.value}`
    }

    innerValueChanged() {
        console.log('innerValue', this.innerValue);
    }

    add(value: any) {
        this.innerValue += `${value}`;
    }

    clear() {
        this.innerValue = '';
    }

    check() {
        console.log('check', this.innerValue)
        //Do action
    }
}