import {autoinject} from 'aurelia-framework';

@autoinject()
export class FocoCustomAttribute {

  constructor(private element: Element) {
    //console.log("FocoCustomAttribute", this.element);
  }

  attached(){
    //console.log("FocoCustomAttribute", "attached");
    (this.element as any).focus();
  }
}

