import {autoinject, bindable} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import moment = require("moment");

@autoinject()
export class Multibanco {
  public isBusy: boolean = false;
  public dtmInicio: string = moment().startOf('month').format('YYYY-MM-DD');
  public dtmFim: string = moment().endOf('month').format('YYYY-MM-DD');
  public lstResultadosSync: any = null;
  public lstResultadosGen: any = null;

  constructor(private app: GlobalServices) {

  }

  canActivate() {
    if (environment.debug) console.log("[index]", "canActivate", this);
  }

  activate(p, a, b) {
    if (environment.debug) console.log("[index]", "activate", a, b);
   
  }


  /**
   * doAction Local
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[titulos-lista]", "{doAction}", action, payload);
    try {
      switch (action) {

        case "SYNC-MB": {
          return this.app.confirmaPopup(["PRETENDE SINCRONIZAR OS PAGAMENTOS MULTIBANCO?"])
            .then(response => {
              if (response === true) {
                this.isBusy = true;
                var py = { dtmInicio: this.dtmInicio, dtmFim: this.dtmFim };
                this.app.api.postProcessed('api/wallet/sync-pagamentos', py)
                  .then(response => {
                    this.app.notificationSuccess("Pagamentos sincronizados!");
                    this.lstResultadosSync = response;
                    return this.isBusy = false;
                  });
              } else {
                return this.isBusy = false;
              }
            })
            .catch(err => { this.isBusy = false; this.app.notificationErrorCompact(err) })
        }


        case "GENERATE-MB": {
          return this.app.confirmaPopup(["PRETENDE GERAR DADOS MULTIBANCO PARA ASSINANTES ACTIVOS?"])
            .then(response => {
              if (response === true) {
                this.isBusy = true;
                this.app.api.postProcessed('api/wallet/generate-all')
                  .then(response => {
                    this.app.notificationSuccess("Dados Multibanco gerados!");
                    this.lstResultadosGen = response;
                    return this.isBusy = false;
                  });
              } else {
                return this.isBusy = false;
              }
            })
            .catch(err => { this.isBusy = false; this.app.notificationErrorCompact(err) })
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }

  nav() {
    this.app.router.navigate('sub-module');
  }

}
