//exemplo de imports de packages npm
// import moment = require("moment");
// import * as marked from 'marked';
import {autoinject} from "aurelia-framework";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {Subscription} from "aurelia-event-aggregator";

//
import {GlobalServices} from "../services/global-services";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

// import {RecuperaPassDialog} from "../dialogs/recupera-pass-dialog";

@autoinject()
export class NotFound {
  public isBusy: boolean           = false;
  private validationController: ValidationController;
  private app: GlobalServices;

  constructor(app: GlobalServices, vcf: ValidationControllerFactory) {
    this.isBusy               = true;
    this.app                  = app;
    this.isBusy = false;
  }
}
