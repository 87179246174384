import {autoinject} from "aurelia-framework";
//import {LoginFormModel} from "../models/login-form-model";
import {GlobalServices} from "../services/global-services";
import {ValidationController, ValidationControllerFactory, ValidationRules} from "aurelia-validation";
//import {RecuperaPassDialog} from "../dialogs/recupera-pass-dialog";
import {Router} from "aurelia-router";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";
import environment from "../environment";
import {App, versao} from "../app";

@autoinject()
export class Login {
  // protected subscription: Subscription;
  public isBusy: boolean      = false;
  public user: LoginFormModel = new LoginFormModel();
  private validationController: ValidationController;

  //importar a versão para o namespace local
  private versao = versao;

  constructor(protected app: GlobalServices, vcf: ValidationControllerFactory, protected router: Router, protected webapp:App) {
    if (environment.debug) console.log("[login]", "Login constructor");
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  attached() {
    // this.subscription = this.app.ea.subscribe('auth:tryLogin', person => this.tryLogin(person));
    if (environment.debug) console.log("[login]", "Login attached");
    if (this.app.auth.authenticated) {
      if (environment.debug) console.log("[login]", "User is logged in redirecting to 'inicio'.");
      this.router.navigate("", {replace: true});
    }
    // this.webapp.isBusy = false;
  }

  tryLogin() {
    if (environment.debug) console.log("[login]", "tryLogin", this);

    this.isBusy = true;
    this.webapp.isBusy = true;
    return this.validationController.validate()
      .then(vr => {
        if (vr.valid === true) {
          if (environment.debug) console.log("[login]", "valid form data");
          return this.app.auth.login({nvcLogin: this.user.username, nvcPassword: this.user.password})
            .then(response => {
              if (environment.debug) console.log("[login]", "Resposta do login", response);
              // console.log("Auth ok", response, this.app.auth);
              this.app.notificationSuccess("Bem-vindo " + this.app.auth.userName);
              if (this.app.redirectFragment) {
                this.router.navigate(this.app.redirectFragment, {replace: true});
                this.app.redirectFragment = "";
              } else {
                this.router.navigate("", {replace: true});
              }
              return true;
            })
            .catch(r => {
              console.error(r);
              return this.webapp.isBusy = this.app.notificationError("Não foi possível estabelecer a ligação ao servidor");
            });
        } else {
          return this.webapp.isBusy = this.app.notificationError("O formulário apresenta erros");
        }
      });
  }

  // recuperar(){
  //   this.app.ds
  //     .open({user: RecuperaPassDialog, centerHorizontalOnly: true, rejectOnCancel: false})
  //     .whenClosed(response => {
  //       if (!response.wasCancelled) {
  //
  //       } else {
  //       }
  //     });
  // }*/
}

export class LoginFormModel {
  //username = 'sistema';
  //password = 'pass';
  username = '';
  password = '';
}

ValidationRules
  .ensure((a: LoginFormModel) => a.username).required().withMessage(`O nome de utilizador é obrigatório`)
  .ensure((a: LoginFormModel) => a.password).required().withMessage(`A password é obrigatória`)
  .on(LoginFormModel);
