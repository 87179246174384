import {autoinject} from "aurelia-framework";
//import {LoginFormModel} from "../models/login-form-model";
import {GlobalServices} from "../services/global-services";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
//import {RecuperaPassDialog} from "../dialogs/recupera-pass-dialog";
import {Router} from "aurelia-router";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";
import environment from "../environment";
import {versao} from "../app";

@autoinject()
export class Blankpage1 {
  // protected subscription: Subscription;
  public isBusy: boolean      = true;
  private validationController: ValidationController;

  //importar a versão para o namespace local
  private versao              = versao;

  constructor(protected app: GlobalServices, vcf: ValidationControllerFactory, protected router: Router) {
    if(environment.debug) console.log("[blankpage]","constructor");
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  attached() {
    // this.subscription = this.app.ea.subscribe('auth:tryLogin', person => this.tryLogin(person));
    if(environment.debug) console.log("[blankpage]","attached");
    this.isBusy = false;
  }


  log(what){
    console.log(what);
  }
}
