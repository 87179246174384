//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {GlobalServices} from "../../../../services/global-services";
import {Level2Component} from "../level-2-component";
import {level_3} from "../../../../mobile-routes";

@autoinject()
export class Level3Component {
  public app: GlobalServices;
  public childRouter: Router;
  public navigation;

  constructor(gs: GlobalServices, protected parent: Level2Component) {
    this.app = gs;
  }

  // if(environment.debug) console.log("[sub-module-component]","router",router);

  configureRouter(config: RouterConfiguration, router: Router) {
    // config.title = 'LEVEL 3';
    config.map(level_3);
    this.childRouter = router;
  }
}