import {FrameworkConfiguration} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    //choices
    './it-choices/multi-choices',
    './it-choices/remote-choices',
    './it-choices/remote-single-choices',
    './it-choices/single-choices',
    './it-choices/tags-choices',
    //choices


    //flatpicker
    './it-flatpickr/flat-pickr',
    //flatpicker

    //keypad
    './it-keypad/keypad',
    //keypad

    //mask-input
    './it-mask/mask-input',
    //mask-input

    //mobile-menu
    './it-mobile-navigation/it-mobile-menu',
    //mobile-menu

    //mobile-top-bar
    './it-mobile-top-bar/it-mobile-top-bar',
    //mobile-top-bar

    //navigation
    './it-navigation/it-navigation',
    //navigation

    //numeric-input
    './it-numeric/numeric-input',
    //numeric-input

    //tabs-list
    './it-tabs/tabs-list',
    //ntabs-list

    //remote-grid
    './remote-grid/remote-grid',
    './remote-grid/distinct-rg-col-filter',
    './remote-grid/filter-proxy.html',
    //remote-grid

    //switch
    './it-switch/it-switch'
    //switch
  ]);

  // value-converters
  config.globalResources([
    './value-converters/trim-10',
  ]);
}
