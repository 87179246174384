// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./../BaseViewModel";
import {VmWrapper} from "./../VmWrapper";
//export classe
export class AnularDocumento extends BaseViewModel{
  public idPagamento: number = null;
  public strMotivo: string = null;
  public strObservacoes: string = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
  public constructor(fields?: Partial<AnularDocumento>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

  public static fromPOJSO(obj: any): AnularDocumento {
    let model = new AnularDocumento();
        model.setState(obj);
        return model;
    }
    
  public static multipleFromPOJSO(objs: any | any[]): AnularDocumento[] {
    if (objs && Array.isArray(objs)) return objs.map(AnularDocumento.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
      return new VmWrapper<AnularDocumento>({payload: this, confirmLevel: (+cl || 0)});
    }

  public cloneInstance(): AnularDocumento { return AnularDocumento.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
      return `CancelarAssinatura`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
  .ensure((m: AnularDocumento) => m.strMotivo).displayName("Motivo").required()
  .on(AnularDocumento);
