import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../../services/global-services";
import environment from "../../../environment";
import {level_2} from "../../../mobile-routes";
import {MobileBreadcrumb, MobileNavStruct} from "../../../it-features/it-mobile-navigation/it-mobile-menu";

@autoinject()
export class Index {
  message: string;
  navigation: any;
  breadcrumbs: any;

  constructor(private app: GlobalServices) {
    if (environment.debug) console.log("[index-level-2]", "constructor");
    this.navigation = MobileNavStruct.translateRoutes(level_2);
  }

  activate(p, a, b) {
    if (environment.debug) console.log("[level-2|index]", "activate", a, b);
    this.breadcrumbs = MobileBreadcrumb.buildBreadcrumbs(b);
  }

}