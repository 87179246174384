import {autoinject, bindable} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import { MobileApp } from "../../mobile-app";
import { MobileBreadcrumb } from "../../it-features/it-mobile-navigation/it-mobile-menu";
import { RemoteGrid } from "../../it-features/remote-grid/remote-grid";

@autoinject()
export class Index {
  message: string;
  navigation: any;
  breadcrumbs: any;
  private rg: RemoteGrid;
  private vHeaderHeight: number                         = 50;
  public vRowHeight: number                            = 50;
  private vFooterHeight                                 = 25;

  constructor(private app: GlobalServices, protected mobileApp: MobileApp) {
    this.message    = 'Hello world';
    this.navigation = mobileApp.navigation;
  }

  canActivate() {
    if (environment.debug) console.log("[index]", "canActivate", this);
  }

  activate(p, a, b) {
    if (environment.debug) console.log("[index]", "activate", a, b);
    this.breadcrumbs = MobileBreadcrumb.buildBreadcrumbs(b);
  }

  public doAction(action: string, payload?: any) {
    switch (action) {
      case "write-value": {
        if (environment.debug) console.log("[index]", "payload", payload);
        if (payload != null) this.message = payload;
        break;
      }
    }
  }

  nav() {
    this.app.router.navigate('sub-module');
  }

}
