import {autoinject, bindable, bindingMode} from "aurelia-framework";
import environment from "../../environment";

/**
 * Um CE que filtra o input para aceitar apenas números
 */
@autoinject()
export class NumericInput {
  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean    = true;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string      = "";
  @bindable({defaultBindingMode: bindingMode.oneTime}) className: string = "form-control";
  @bindable({defaultBindingMode: bindingMode.oneTime}) placeholder: string    = "Inserir número...";

  //elemento HTML
  private inputElement: HTMLInputElement;
  //estado interno
  private _innerValue: string = "";

  attached() {
    if (this.debug) console.log("[numeric-input]", "attached");
    this.registerListeners();
    this.inputElement.value = "" + this.value;
  }

  detached() {
    if (this.debug) console.log("[single-choices]", "detached");
    this.deregisterListeners();
  }

  // bind(bindingContext, overrideContext: Object) {
  //   if (this.debug) console.log("[numeric-input]", "bind", bindingContext, overrideContext);
  //
  // }
  //
  // unbind() {
  //   if (this.debug) console.log("[numeric-input]", "unbind");
  // }
  //
  valueChanged(newValue, oldValue) {
    if (environment.debug) console.log("[numeric-input]", "valueChanged", newValue, oldValue);
    if (newValue != this._innerValue) {
      this._innerValue = "" + newValue;
      if (this.inputElement)
        this.inputElement.value = "" + newValue;
    }
  }

  registerListeners() {
    this.inputElement.onkeyup = (e) => {
      if (this.debug) console.log("[numeric-input]", "onkeyup", e);

      this._innerValue = this.inputElement.value;
      if (this._innerValue) {
        this._innerValue        = this._innerValue.replace(",", ".");
        this.inputElement.value = this._innerValue;
      }
      if (this._innerValue != "" + this.value) {
        //this.value = Number.parseFloat(this._innerValue || "0");
        this.value = this._innerValue;
      }
      return true;
    };

    this.inputElement.onkeypress = (e) => {
      if (this.debug) console.log("[numeric-input]", "onkeypress", e);
      const regex = /^[0-9.,]$/;

      if (e.key === "Dead") {
        if (this.debug) console.log("[numeric-input]", "onkeypress BLOQUEADO", e.key, this._innerValue);
        e.preventDefault();
        return false;
      }

      if (regex.test(e.key) || e.key.length > 1 || e.ctrlKey) {
        //e.preventDefault();
        //this._innerValue         = this.inputElement.value;
        if (this.debug) console.log("[numeric-input]", "onkeypress PERMITIDO", e.key, this._innerValue);
        //apenas um ponto
        if ((e.key === "." || e.key === ",") && this._innerValue.includes(".")) {
          if (this.debug) console.log("[numeric-input]", "onkeypress BLOQUEADO SEGUNDO PONTO", e.key, this._innerValue);
          e.preventDefault();
          return false;
        }
      } else {
        if (this.debug) console.log("[numeric-input]", "onkeypress BLOQUEADO", e.key, this._innerValue);
        e.preventDefault();
        return false;
      }

      // this.inputElement.value = this._innerValue;
      return true;
    };

    //lidar com paste
    this.inputElement.onpaste = (e) => {
      if (this.debug) console.log("[numeric-input]", "onpaste", e, "data");
      let content = "";

      e.preventDefault();
      if (e.clipboardData) {
        content = e.clipboardData.getData('text/plain');
        content = (content || "")
          .replace(/[^0-9.,]/gm, '')
          .replace(/,/g, "x")
          .replace(/\./g, "x")
          .replace("x", ".")
          .replace(/x/g, '')
        ;
        if (content == ".") content = "";
        this._innerValue = this.inputElement.value = content;

        if (this._innerValue != this.value) {
          //this.value = Number.parseFloat(this._innerValue || "0");
          this.value = this._innerValue;

          if ("createEvent" in document) {
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", true, true);
            this.inputElement.dispatchEvent(evt);
          }
          else
            (this.inputElement as any).fireEvent("onchange");
        }
      }
      return true;
    }
  }

  deregisterListeners() {
    this.inputElement.onkeydown  = null;
    this.inputElement.onkeyup    = null;
    this.inputElement.onkeypress = null;
    this.inputElement.onpaste    = null;
  }
}
