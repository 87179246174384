//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {tabelasRoutes} from "../../app-routes";
import {App} from "../../app";

@autoinject()
export class TabelasComponent {
  public app: GlobalServices;
  public childRouter: Router;

  constructor(gs: GlobalServices, protected parent: App) {
    this.app = gs;
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    if (environment.debug) console.log("[tabelas-component]","configureRouter", config, router);
    config.map(tabelasRoutes);
    this.childRouter = router;
  }

  logout() {
    if (environment.debug) console.log("[tabelas-component]","logout");
    this.parent && this.parent.logout();
  }
}
