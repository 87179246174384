/**
 * Created by herna on 4/30/2017.
 */
import {inject, noView} from 'aurelia-framework';
import {EventAggregator, Subscription} from "aurelia-event-aggregator";
import * as Humane from 'humane-js';
import environment from "../../environment";

@noView()
@inject(EventAggregator, Humane)
export class Notifications {
  protected debug: boolean                = environment.debug;
  protected subscriptions: Subscription[] = [];
  protected eventAggregator: EventAggregator;
  protected humane: Humane;

  constructor(ea: EventAggregator, h: Humane) {
    if (this.debug) {
      console.log("[notifications]", "constructor", ea, h, this);
      window["notifications"] = this;
    }
    this.eventAggregator = ea;
    this.humane          = h;
  }

  attached() {
    this.subscriptions = [
      this.eventAggregator.subscribe('notification:success', msg => {
        if (this.debug) console.log("notification", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-success", clickToClose: true});
      }),
      this.eventAggregator.subscribe('notification:short', msg => {
        if (this.debug) console.log("notification", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-success", timeout: 1000, clickToClose: true});
      }),
      this.eventAggregator.subscribe('notification:error', msg => {
        if (this.debug) console.log("notification:error", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-error", timeout: 7000, clickToClose: true, waitForMove: true});
      }),
      this.eventAggregator.subscribe('notification:warning', msg => {
        if (this.debug) console.log("notification:error", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-warning", timeout: 7000, clickToClose: true, waitForMove: true});
      }),
      this.eventAggregator.subscribe('notification:info', msg => {
        if (this.debug) console.log("notification:error", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-info", timeout: 7000, clickToClose: true, waitForMove: true});
      })
    ];

    if (this.debug) {
      console.log("[notifications]", "attached", this);
      //this.humane.log("test", {addnCls: "humane-flatty-success", clickToClose: true, timeout: 10000});
      this.humane.log(`Notificação Debug`, {addnCls: "humane-flatty-info", timeout: 7000, clickToClose: true, waitForMove: true});
    }
  }

  detached() {
    this.subscriptions.forEach(s => s.dispose());
    this.subscriptions = [];
  }
}

// export const success = "notification:success";
