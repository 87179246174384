import {autoinject, bindable, bindingMode, computedFrom} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {Api} from "../../services/api";
import Choices = require("choices.js");

/**
 * Um CE com choices de base que se comporta como o select2 mas bem.
 */
@autoinject()
export class SingleChoices {
  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean          = false;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string            = "";
//  @bindable modelValue: any[]                                                  = [];
  @bindable({defaultBindingMode: bindingMode.oneTime}) config: Choices.Options = {
    placeholder     : true,
    placeholderValue: "Procurar...",
    shouldSort      : true,
    searchFloor     : 2,     // or whatever value makes sense for your data / API
    //searchChoices   : false, // see bullet point 2 above
    duplicateItems  : false,  // this is ignored, see bullet point 3 above
    removeItemButton: true
  };

  /**
   * O valor da escolha (o id, código)
   * é tratado pelo plugin por value
   * @type {string}
   */
  @bindable valueKey: string = "id";

  /**
   * O texto a aparecer na escolha
   * é tratado pelo plugin por label
   * @type {string}
   */
  @bindable labelText: string = "text";

  /**
   * array de objetos usado para popular as opções
   * @type {any[]}
   */
  @bindable options: any[] = null;

  //a referência da instância do plugin
  public choices: any | Choices;

  //o elemento HTML select(multiple)
  private selElem: HTMLSelectElement = null;

  private localChoices;
  private timeout: any;

  private _innerValue: string;

  @computedFrom("_innerValue")
  get innerValue(): string {
    if (this.debug) console.log("[single-choices]", "get innerValue");
    return this._innerValue;
  }

  set innerValue(value: string) {
    if (this.debug) console.log("[single-choices]", "set innerValue", value);
    if (this.value != value) this.value = "" + value;
    this._innerValue = "" + value;
    if (this.choices) {
      if (this.choices.getValue(true) != value) {
        if (value)
          this.choices.setValueByChoice("" + value);
        else
          this.rebuildChoices();
      }
    }
  }

  constructor(private app: GlobalServices, private api: Api) {}

  attached() {
    if (this.debug) console.log("[single-choices]", "attached");

    if (!this.options) {
      console.error('O <single-choices> deve ser invocado com um array como options.bind="array"');
      return;
    } else {
      this.config.choices = this.generateChoices();
    }

    if (this.debug) console.log("[single-choices]", "inicializar o plugin com configuração:", this.config);
    if (this.debug) console.log("[single-choices]", "inicializar do plugin:", this.value, this._innerValue);

    this.choices    = new Choices(this.selElem, this.config);
    this.innerValue = this.value;
  }

  detached() {
    if (this.debug) console.log("[single-choices]", "detached");
    if (this.choices) this.choices.destroy();
  }

  /**
   * Função chamada quando o bindable value é alterado
   * @param newValue
   * @param oldValue
   */
  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[single-choices]", "valueChanged", newValue, oldValue);

    if (this.choices) {
      if (newValue) {
        if (this.config.choices.findIndex(el => el.value == this.value) >= 0) {
          this.innerValue = newValue;
        } else {
          console.error(`A opção ${newValue} não existe no conjunto de escolhas`);
          window.clearTimeout(this.timeout);
          this.timeout = window.setTimeout(() => {this.value = null;}, 200);
          //(() => {this.value = null})();
        }
      }
      else {
        this.rebuildChoices();
        this.choices.setValueByChoice("");
      }
    }
  }

  /**
   * Função chamada quando o bindable options é alterado
   * @param newOptions
   * @param oldOptions
   */
  optionsChanged(newOptions, oldOptions) {
    if (this.debug) console.log("[single-choices]", "optionsChanged", newOptions, oldOptions);
    if (newOptions) {
      if (this.choices) {
        if (this.debug) console.log("[single-choices]", "optionsChanged", "redefinição de choices após instanciação do plugin");

        //existe a possibilidade do valor não existir no novo array
        //this.choices.setChoices(this.config.choices, "value", "label", true);

        //todo: condicional sobre a existencia do novo valor no novo conjunto de escolhas
        this.config.choices = this.generateChoices();
        if (!this.value || this.config.choices.findIndex(el => el.value == this.value) >= 0) {
          this.choices.setChoices(this.config.choices, "value", "label", true);
        } else {
          this.value = null;
        }
      }
    }
  }

  /**
   * Função chamada quando o bindable config é alterado
   * @param newConfig
   * @param oldConfig
   */
  configChanged(newConfig, oldConfig) {
    if (this.debug) console.log("[single-choices]", "configChanged", newConfig, oldConfig);
  }

  /**
   * Utilitário que reconstrói o select
   */
  private rebuildChoices() {
    if (this.debug) console.log("[single-choices]", "rebuildChoices");
    this.choices.clearStore();
    this.choices.setChoices(this.config.choices, "value", "label", true);
  }

  /**
   * gera o array que representa options
   * @returns {any[]}
   */
  private generateChoices() {
    let choices: any[] = [];
    if (this.options)
      choices = this.options.map(el => ({value: "" + el[this.valueKey], label: "" + el[this.labelText], customProperties: el}));
    //há uma definição para a string de placeholder usa-a como escollha vazia (do tipo "escolha um elemento...")
    if (this.config.placeholder) {
      choices.unshift({value: "", label: " " + this.config.placeholderValue, placeholder: true, disabled: true, customProperties: {}})
    }

    return choices;
  }

  // handleChoice($event){
  //   if(this.debug) console.log("[single-choices]","handleChoice", $event);
  //   let data = $event.detail.choice;
  //   if(this.debug) console.log("[single-choices]","handleChoice", "selected", data);
  //   if(data.value != this.innerValue) this.innerValue = data.value;
  // }
  //

  /**
   * Lida com um change no plugin
   * @param $event
   */
  handleChange($event) {
    if (this.debug) console.log("[single-choices]", "handleChange", $event);
    let data = $event.detail.value;
    if (this.debug) console.log("[single-choices]", "handleChange", "selected", data);
    if (data != this.innerValue) {
      this.innerValue = "" + data;
    } else {
      this.innerValue = "";
    }
  }
}
