//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {MobileApp} from "../../mobile-app";
import {level_1} from "../../mobile-routes";

@autoinject()
export class Level1Component {
  public app: GlobalServices;
  public childRouter: Router;
  public navigation;

  constructor(gs: GlobalServices, protected parent: MobileApp) {
    this.app = gs;
  }

  // if(environment.debug) console.log("[sub-module-component]","router",router);

  configureRouter(config: RouterConfiguration, router: Router) {
    // config.title = 'LEVEL 1';
    config.map(level_1);
    this.childRouter = router;
  }
}