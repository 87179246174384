// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {MovimentosCaixaPagamentos} from './MovimentosCaixaPagamentos';
//export classe
export class MovimentosCaixa extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public nvcLogin : string = null;
    public dtmAbertura:string = null;
    public _dtmAbertura:Date = new Date();
    public dtmFecho:string = null;
    public _dtmFecho:Date = new Date();
    public bitValidado : boolean = false;
    public nvcCodigoInterno : string = "00000000-0000-0000-0000-000000000000";
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    public movimentosCaixaPagamentos : MovimentosCaixaPagamentos[] = [];
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<MovimentosCaixa>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): MovimentosCaixa {
        let model = new MovimentosCaixa();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): MovimentosCaixa[] {
        if (objs && Array.isArray(objs)) return objs.map(MovimentosCaixa.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<MovimentosCaixa>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): MovimentosCaixa { return MovimentosCaixa.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `MovimentosCaixa`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: MovimentosCaixa) => m.id).displayName("id").required()
    .ensure((m: MovimentosCaixa) => m.nvcLogin).displayName("nvcLogin").required()
    .ensure((m: MovimentosCaixa) => m.dtmAbertura).displayName("dtmAbertura").required()
    .ensure((m: MovimentosCaixa) => m.dtmFecho).displayName("dtmFecho").required()
    .ensure((m: MovimentosCaixa) => m.bitValidado).displayName("bitValidado").required()
    .ensure((m: MovimentosCaixa) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    .ensure((m: MovimentosCaixa) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: MovimentosCaixa) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: MovimentosCaixa) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: MovimentosCaixa) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: MovimentosCaixa) => m.timestamp).displayName("timestamp").required()
    .ensure((m: MovimentosCaixa) => m.movimentosCaixaPagamentos).displayName("movimentosCaixaPagamentos").required()
  .on(MovimentosCaixa);
