/**
 * Created by hernani on 2017-06-20.
 */
export class Trim10ValueConverter {
  toView(value) {
    if(!value) return "";
    if(typeof value === "string") return value.substr(0,10);
    return value;
  }

  fromView(value) {
    // let exp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
    //     result = exp.exec(hex);
    //
    // let euroStringToFloat2 = euroStringToFloat(value);
    // console.error(value, euroStringToFloat2);
    return value;
  }
}