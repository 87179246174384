// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Assinante} from './Assinante';
import {AssinanteTipo} from './AssinanteTipo';
import {Assinatura} from './Assinatura';
import {MeioPagamento} from './MeioPagamento';
import {Morada} from './Morada';
import {MoradaTipo} from './MoradaTipo';
import {MovimentosCaixa} from './MovimentosCaixa';
import {MovimentosCaixaPagamentos} from './MovimentosCaixaPagamentos';
import {Pagamento} from './Pagamento';
import {Pais} from './Pais';
import {SysConfig} from './SysConfig';
import {SysHumanLog} from './SysHumanLog';
import {SysLog} from './SysLog';
import {SysLogin} from './SysLogin';
import {SysView} from './SysView';
import {Titulo} from './Titulo';
import {Utilizador} from './Utilizador';
import {UtilizadorTipo} from './UtilizadorTipo';
//export classe
export class TheContext extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public assinante : Assinante[] = [];
   
    public assinanteTipo : AssinanteTipo[] = [];
    public assinatura : Assinatura[] = [];
    public meioPagamento : MeioPagamento[] = [];
    public morada : Morada[] = [];
    public moradaTipo : MoradaTipo[] = [];
    public movimentosCaixa : MovimentosCaixa[] = [];
    public movimentosCaixaPagamentos : MovimentosCaixaPagamentos[] = [];
    public pagamento : Pagamento[] = [];
    public pais : Pais[] = [];
    public sysConfig : SysConfig[] = [];
    public sysHumanLog : SysHumanLog[] = [];
    public sysLog : SysLog[] = [];
    public sysLogin : SysLogin[] = [];
    public sysView : SysView[] = [];
    public titulo : Titulo[] = [];
    public utilizador : Utilizador[] = [];
    public utilizadorTipo : UtilizadorTipo[] = [];
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<TheContext>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): TheContext {
        let model = new TheContext();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): TheContext[] {
        if (objs && Array.isArray(objs)) return objs.map(TheContext.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<TheContext>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): TheContext { return TheContext.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `TheContext`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: TheContext) => m.assinante).displayName("assinante").required()
    .ensure((m: TheContext) => m.assinanteTipo).displayName("assinanteTipo").required()
    .ensure((m: TheContext) => m.assinatura).displayName("assinatura").required()
    .ensure((m: TheContext) => m.meioPagamento).displayName("meioPagamento").required()
    .ensure((m: TheContext) => m.morada).displayName("morada").required()
    .ensure((m: TheContext) => m.moradaTipo).displayName("moradaTipo").required()
    .ensure((m: TheContext) => m.movimentosCaixa).displayName("movimentosCaixa").required()
    .ensure((m: TheContext) => m.movimentosCaixaPagamentos).displayName("movimentosCaixaPagamentos").required()
    .ensure((m: TheContext) => m.pagamento).displayName("pagamento").required()
    .ensure((m: TheContext) => m.pais).displayName("pais").required()
    .ensure((m: TheContext) => m.sysConfig).displayName("sysConfig").required()
    .ensure((m: TheContext) => m.sysHumanLog).displayName("sysHumanLog").required()
    .ensure((m: TheContext) => m.sysLog).displayName("sysLog").required()
    .ensure((m: TheContext) => m.sysLogin).displayName("sysLogin").required()
    .ensure((m: TheContext) => m.sysView).displayName("sysView").required()
    .ensure((m: TheContext) => m.titulo).displayName("titulo").required()
    .ensure((m: TheContext) => m.utilizador).displayName("utilizador").required()
    .ensure((m: TheContext) => m.utilizadorTipo).displayName("utilizadorTipo").required()
  .on(TheContext);
