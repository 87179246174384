// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Assinante} from './Assinante';
import {Pagamento} from './Pagamento';
//export classe
export class MeioPagamento extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public nvcMeioPagamento : string = null;
    public nvcDescricao : string = null;
    public bitActivo : boolean = false;
    public bitVisivel: boolean = false;
    public bitSistema: boolean = false;
    public intOrdem: number = 0;
    public bitMovimentaCaixa: boolean = false;
    public nvcTipo: string = null;
    public nvcCodigoInterno: string = "00000000-0000-0000-0000-000000000000";
    public nvcChaveErp: string = null;
    //public nvcUtilizadorI : string = null;
    //public dtmDataI:string = null;
    //public _dtmDataI:Date = new Date();
    //public nvcUtilizadorU : string = null;
    //public dtmDataU:string = null;
    //public _dtmDataU:Date = new Date();
    //public timestamp : number[] = [];
    public assinante : Assinante[] = [];
    public pagamento : Pagamento[] = [];
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<MeioPagamento>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): MeioPagamento {
        let model = new MeioPagamento();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): MeioPagamento[] {
        if (objs && Array.isArray(objs)) return objs.map(MeioPagamento.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<MeioPagamento>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): MeioPagamento { return MeioPagamento.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `MeioPagamento`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: MeioPagamento) => m.nvcMeioPagamento).displayName("Código").required()
    .ensure((m: MeioPagamento) => m.nvcDescricao).displayName("Descrição").required()
    .ensure((m: MeioPagamento) => m.bitActivo).displayName("bitActivo").required()
    .ensure((m: MeioPagamento) => m.bitVisivel).displayName("bitVisivel").required()
    .ensure((m: MeioPagamento) => m.intOrdem).displayName("intOrdem").required()
    .ensure((m: MeioPagamento) => m.bitMovimentaCaixa).displayName("bitMovimentaCaixa").required()
    .ensure((m: MeioPagamento) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    //.ensure((m: MeioPagamento) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    //.ensure((m: MeioPagamento) => m.dtmDataI).displayName("dtmDataI").required()
    //.ensure((m: MeioPagamento) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    //.ensure((m: MeioPagamento) => m.dtmDataU).displayName("dtmDataU").required()
    //.ensure((m: MeioPagamento) => m.timestamp).displayName("timestamp").required()
    .ensure((m: MeioPagamento) => m.assinante).displayName("assinante").required()
    .ensure((m: MeioPagamento) => m.pagamento).displayName("pagamento").required()
  .on(MeioPagamento);
