// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Utilizador} from './Utilizador';
//export classe
export class UtilizadorTipo extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public nvcTipo : string = null;
    public nvcDescricao : string = null;
    public bitActivo : boolean = false;
    public bitVisivel : boolean = false;
    public intOrdem : number = 0;
    public nvcCodigoInterno : string = "00000000-0000-0000-0000-000000000000";
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    public utilizador : Utilizador[] = [];
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<UtilizadorTipo>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): UtilizadorTipo {
        let model = new UtilizadorTipo();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): UtilizadorTipo[] {
        if (objs && Array.isArray(objs)) return objs.map(UtilizadorTipo.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<UtilizadorTipo>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): UtilizadorTipo { return UtilizadorTipo.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `UtilizadorTipo`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: UtilizadorTipo) => m.nvcTipo).displayName("nvcTipo").required()
    .ensure((m: UtilizadorTipo) => m.nvcDescricao).displayName("nvcDescricao").required()
    .ensure((m: UtilizadorTipo) => m.bitActivo).displayName("bitActivo").required()
    .ensure((m: UtilizadorTipo) => m.bitVisivel).displayName("bitVisivel").required()
    .ensure((m: UtilizadorTipo) => m.intOrdem).displayName("intOrdem").required()
    .ensure((m: UtilizadorTipo) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    .ensure((m: UtilizadorTipo) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: UtilizadorTipo) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: UtilizadorTipo) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: UtilizadorTipo) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: UtilizadorTipo) => m.timestamp).displayName("timestamp").required()
    .ensure((m: UtilizadorTipo) => m.utilizador).displayName("utilizador").required()
  .on(UtilizadorTipo);
