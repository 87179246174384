// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
//no imports

//export classe
export class SysHumanLog extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public nvcLogin : string = null;
    public dtmData:string = null;
    public _dtmData:Date = new Date();
    public nvcOperacao : string = null;
    public nvcErro : string = null;
    public nvcMisc : string = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<SysHumanLog>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): SysHumanLog {
        let model = new SysHumanLog();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): SysHumanLog[] {
        if (objs && Array.isArray(objs)) return objs.map(SysHumanLog.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<SysHumanLog>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): SysHumanLog { return SysHumanLog.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `SysHumanLog`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: SysHumanLog) => m.id).displayName("id").required()
    .ensure((m: SysHumanLog) => m.nvcLogin).displayName("nvcLogin").required()
    .ensure((m: SysHumanLog) => m.dtmData).displayName("dtmData").required()
    .ensure((m: SysHumanLog) => m.nvcOperacao).displayName("nvcOperacao").required()
    .ensure((m: SysHumanLog) => m.nvcErro).displayName("nvcErro").required()
    .ensure((m: SysHumanLog) => m.nvcMisc).displayName("nvcMisc").required()
  .on(SysHumanLog);
