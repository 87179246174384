// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
//no imports

//export classe
export class SysView extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public nvcLogin : string = null;
    public dtmData:string = null;
    public _dtmData:Date = new Date();
    public nvcTabela : string = null;
    public nvcIdentificador : string = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<SysView>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): SysView {
        let model = new SysView();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): SysView[] {
        if (objs && Array.isArray(objs)) return objs.map(SysView.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<SysView>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): SysView { return SysView.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `SysView`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: SysView) => m.id).displayName("id").required()
    .ensure((m: SysView) => m.nvcLogin).displayName("nvcLogin").required()
    .ensure((m: SysView) => m.dtmData).displayName("dtmData").required()
    .ensure((m: SysView) => m.nvcTabela).displayName("nvcTabela").required()
    .ensure((m: SysView) => m.nvcIdentificador).displayName("nvcIdentificador").required()
  .on(SysView);
