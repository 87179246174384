// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Assinante} from './Assinante';
import {Assinatura} from './Assinatura';
import { AssinaturaPagamento } from "./AssinaturaPagamento";
//export classe
export class AssinanteAssinatura extends BaseViewModel{
  public id: number = 0;
  public idAssinante: number = 0;
  public idAssinatura: number = 0;
  public intEdicaoInicio: number = null;
  public intEdicaoFim: number = null;
  public dtmEdicaoInicio: string = null;
  public _dtmEdicaoInicio: Date = new Date();
  public dtmEdicaoFim: string = null;
  public _dtmEdicaoFim: Date = new Date();
  public bitPago: boolean = false;
  public bitCancelado: boolean = false;
  public nvcObservacoes: string = null;
  public decValor: number = null;
  public bitEntregue: boolean = false;
  public decValorPago: number = null;
  public decValorDivida: number = null;
  public idAssinanteNavigation: Assinante = null;
  public idAssinaturaNavigation: Assinatura = null;
  public assinaturaPagamento: AssinaturaPagamento[] = [];

    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<AssinanteAssinatura>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): AssinanteAssinatura {
        let model = new AssinanteAssinatura();
        model.setState(obj);
        if (obj.idAssinaturaNavigation) model.idAssinaturaNavigation = Assinatura.fromPOJSO(obj.idAssinaturaNavigation);
        if (obj.idAssinanteNavigation) model.idAssinanteNavigation = Assinante.fromPOJSO(obj.idAssinanteNavigation);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): AssinanteAssinatura[] {
        if (objs && Array.isArray(objs)) return objs.map(AssinanteAssinatura.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<AssinanteAssinatura>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): AssinanteAssinatura { return AssinanteAssinatura.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `AssinanteAssinatura`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: AssinanteAssinatura) => m.id).displayName("id").required()
    .ensure((m: AssinanteAssinatura) => m.idAssinante).displayName("idAssinante").required()
    .ensure((m: AssinanteAssinatura) => m.idAssinatura).displayName("idAssinatura").required()
    .ensure((m: AssinanteAssinatura) => m.intEdicaoInicio).displayName("intEdicaoInicio").required()
    .ensure((m: AssinanteAssinatura) => m.intEdicaoFim).displayName("intEdicaoFim").required()
    .ensure((m: AssinanteAssinatura) => m.dtmEdicaoInicio).displayName("dtmEdicaoInicio").required()
    .ensure((m: AssinanteAssinatura) => m.dtmEdicaoFim).displayName("dtmEdicaoFim").required()
    .ensure((m: AssinanteAssinatura) => m.bitPago).displayName("bitPago").required()
    .ensure((m: AssinanteAssinatura) => m.idAssinanteNavigation).displayName("idAssinanteNavigation").required()
    .ensure((m: AssinanteAssinatura) => m.idAssinaturaNavigation).displayName("idAssinaturaNavigation").required()
  .on(AssinanteAssinatura);
