/**
 * Created by hernani on 2017-06-09.
 */
import {BaseViewModel} from "./BaseViewModel";
import environment from "../environment";

/**
 * Created by hernani on 2017-06-09.
 */
export class VmWrapper<T> {
  public payload: T;
  // o nivel deve ser um inteiro que vai crescendo consoante as repostas do utilizador
  public confirmLevel: number;
  // serve para comunicar uma opção ao servidor
  public confirmOption: string;
  public confirm: boolean;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<VmWrapper<T>>,) {
    if (fields) Object.assign(this, fields);
  }

  /**
   * Calcula o próximo nível de confirmação a considerar para o request a ser realizado à API.
   * @param {number} lvl
   * @return {VmWrapper}
   */
  public nextLevel(lvl?: number): VmWrapper<T> {
    if (!lvl) lvl = +this.confirmLevel + 1;
    this.confirmLevel = +lvl;
    return this;
  }

  /**
   * Adiciona uma opção à resposta de confirmação
   * @param {string} opt
   * @return {VmWrapper}
   */
  public withOption(opt: string): VmWrapper<T> {
    this.confirmOption = opt || "";
    return this;
  }

  /**
   *
   * @param serializerFn -> a função de serialização. Por defeito: "stateToPOJSO"
   * @return {{payload: T}}
   */
  public stateToPOJSO(serializerFn: string = "stateToPOJSO") {
    let appendix = {};
    // console.log("pick me up!");
    if (this.confirmLevel !== null && this.confirmLevel !== undefined) Object.assign(appendix, {confirmLevel: +this.confirmLevel});
    if (this.confirm !== null && this.confirm !== undefined) Object.assign(appendix, {confirm: (this.confirm === true)});
    if (this.confirmOption !== null && this.confirmOption !== undefined) Object.assign(appendix, {confirmOption: (this.confirmOption)});
    return {
      payload: (typeof (this.payload as any)[serializerFn] === "function") ? (this.payload as any)[serializerFn]() : this.payload, ...(appendix || {})
    }
  }

  /**
   * função identidade para facilitar o uso de VmWrappers não oriundos de modelos BaseViewModel
   * @return {this<T>}
   */
  public wrapIt() { return this; }

  /**
   * Factory estático
   * uso:
   * lista de ids dentro de um vmw
   *
   * let lista:number[] = [1, 2, 3...]
   *
   * VmWrapper.factory(lista);
   *
   * @param {T} pl
   * @param cl
   * @return {VmWrapper<T>}
   */
  static factory<T>(pl: T, cl: number = 0): VmWrapper<T> {
    return new VmWrapper<T>({payload: pl, confirmLevel: cl});
  }
}

export class VmWrapperTwo<T, TS> {
  public payload: T;
  public subPayload: TS;
  public confirmLevel: number;
  public confirm: boolean;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<VmWrapperTwo<T, TS>>,) {
    if (fields) Object.assign(this, fields);
  }

  public stateToPOJSO() {
    let appendix = {};
    // console.log("pick me up!");
    if (this.confirmLevel !== null && this.confirmLevel !== undefined) Object.assign(appendix, {confirmLevel: +this.confirmLevel});
    if (this.confirm !== null && this.confirm !== undefined) Object.assign(appendix, {confirm: (this.confirm === true)});
    return {
      payload   : (typeof (this.payload as any).stateToPOJSO === "function") ? (this.payload as any).stateToPOJSO() : this.payload,
      subPayload: (typeof (this.subPayload as any).stateToPOJSO === "function") ? (this.subPayload as any).stateToPOJSO() : this.subPayload,
      ...(appendix || {})
    }
  }
}

export type ItResponseType = "ok" | "confirm" | "option-confirm" | "warning" | "error" | "fatal-error" | "redirect" | "lock";

/**
 * Versão TS de resposta tipada da webapi
 */
export class ItResponse extends BaseViewModel {
  //Propriedades
  public type: ItResponseType = "ok";
  public payload: any         = null;
  public token: string        = null;

  public messages: Map<string, string[]>  = new Map<string, string[]>();
  public options: ItResponseTypedOption[] = [];

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ItResponse>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  static getClassName() { return 'ItResponse'; }

  getClassName() { return ItResponse.getClassName(); }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): ItResponse {
    let model     = new ItResponse();
    model.type    = <ItResponseType>obj.type;
    model.payload = obj.payload;
    model.token   = obj.token;
    if (obj.messages) {
      if (environment.debug) console.log("[vmwrapper]", "messages", obj.messages);
      let keys = Object.keys(obj.messages);
      keys.map(k => {
        if (obj.messages.hasOwnProperty(k)) model.messages.set(k, obj.messages[k])
      })
    }
    if (obj.options) {
      if (environment.debug) console.log("[vmwrapper]", "options", obj.options);
      model.options = ItResponseTypedOption.multipleFromPOJSO(obj.options);
    }
    model.__index = index;
    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  public static multipleFromPOJSO(objs: any[]): ItResponse[] {
    if (objs && Array.isArray(objs)) return objs.map(ItResponse.fromPOJSO);
    return [];
  }

  public cloneInstance(): ItResponse {
    return ItResponse.fromPOJSO(this.stateToPOJSO());
  }
}

/*
 * Versão TS de opções de resposta tipada da webapi
 */
export class ItResponseTypedOption extends BaseViewModel {
  //Propriedades
  public value: string;
  public valueTitle: string;
  public description: string      = null;
  public title: string            = null;
  // public uniqueId: string         = null;
  public type: "normal" | "label" = "normal";

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ItResponseTypedOption>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  static getClassName() { return 'ItResponseTypedOption'; }

  getClassName() { return ItResponseTypedOption.getClassName(); }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): ItResponseTypedOption {
    let model         = new ItResponseTypedOption();
    //model.setState(obj);
    model.value       = obj.value || null;
    model.valueTitle  = obj.valueTitle || obj.value || null;
    model.description = obj.description || null;
    model.title       = obj.title || null;
    // model.uniqueId    = obj.uniqueId || null;
    model.type        = obj.type || "normal";
    model.__index     = index;
    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  public static multipleFromPOJSO(objs: any[]): ItResponseTypedOption[] {
    if (objs && Array.isArray(objs)) return objs.map(ItResponseTypedOption.fromPOJSO);
    return [];
  }

  public cloneInstance(): ItResponseTypedOption {
    return ItResponseTypedOption.fromPOJSO(this.stateToPOJSO());
  }
}