// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Assinante} from './Assinante';
import {MeioPagamento} from './MeioPagamento';
//export classe
export class AssinanteCredito extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public idAssinante : number = 0;
    public nvcMeioPagamento : string = null;
    public decValor : number = 0;
    public dtmData:string = null;
    public _dtmData:Date = new Date();
    public bitAnulado : boolean = false;
    public idPagamentoMultibanco : string = null;
    public nvcCodigoInterno : string = "00000000-0000-0000-0000-000000000000";
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    public idAssinanteNavigation : Assinante = null;
    public nvcMeioPagamentoNavigation : MeioPagamento = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<AssinanteCredito>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): AssinanteCredito {
        let model = new AssinanteCredito();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): AssinanteCredito[] {
        if (objs && Array.isArray(objs)) return objs.map(AssinanteCredito.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<AssinanteCredito>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): AssinanteCredito { return AssinanteCredito.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `AssinanteCredito`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: AssinanteCredito) => m.id).displayName("id").required()
    .ensure((m: AssinanteCredito) => m.idAssinante).displayName("idAssinante").required()
    .ensure((m: AssinanteCredito) => m.nvcMeioPagamento).displayName("nvcMeioPagamento").required()
    .ensure((m: AssinanteCredito) => m.decValor).displayName("decValor").required()
    .ensure((m: AssinanteCredito) => m.dtmData).displayName("dtmData").required()
    .ensure((m: AssinanteCredito) => m.bitAnulado).displayName("bitAnulado").required()
    .ensure((m: AssinanteCredito) => m.idPagamentoMultibanco).displayName("idPagamentoMultibanco").required()
    .ensure((m: AssinanteCredito) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    .ensure((m: AssinanteCredito) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: AssinanteCredito) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: AssinanteCredito) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: AssinanteCredito) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: AssinanteCredito) => m.timestamp).displayName("timestamp").required()
    .ensure((m: AssinanteCredito) => m.idAssinanteNavigation).displayName("idAssinanteNavigation").required()
    .ensure((m: AssinanteCredito) => m.nvcMeioPagamentoNavigation).displayName("nvcMeioPagamentoNavigation").required()
  .on(AssinanteCredito);
 