//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import { ComposeDialog, ComposeDialogOptions } from "../../dialogs/compose-dialog";
import { Titulo } from "../../models/Titulo";

@autoinject()
export class TitulosLista {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  constructor(public app: GlobalServices) { }

  fetchModel(obj: any) {
    return obj ? this.app.api.getProcessed("api/titulo", { id: obj.id }) : Promise.resolve(new Titulo({ bitActivo: true }));
  }

  /**
* Instancia o modelo
* @param payload
*/
  parseModel(payload: any) {
    return payload ? Titulo.fromPOJSO(payload) : null;
  }

  /**
   * doAction Local
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[titulos-lista]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {

        case 'DETALHE': {
          this.isBusy = false;
          return this.fetchModel(payload)
            .then(x => {
              var modelo = this.parseModel(x);
              this.app.ds.open({
                viewModel: ComposeDialog,
                model: {
                  modelo: modelo,
                  invoker: this,
                  options: new ComposeDialogOptions({
                    title: modelo.nvcTitulo ? "Editar título" : "Adicionar título",
                    withDefaultFooter: true,
                    okLabel: "Gravar",
                    okIcon: "fa- check",
                    mainView: '../routes/tabelas/ce/titulo-form.html',
                    postUri: 'api/titulo'
                  })
                },
                //position: centerDialog,
                centerHorizontalOnly: true
              }).whenClosed(r => {
                if (!r.wasCancelled) {
                  if (r.output.payload) {
                    this.rg.refreshPage();
                    this.app.notificationSuccess("Registo guardado!");
                  }
                }
                return r.wasCancelled
              })
            });
        }


        case 'REMOVER': {
          if (!payload) break;
          return this.app.confirmaPopup(["Pretende remover o título " + payload.nvcDescricao + "?"])
            .then(response => {
              if (response === true) {
                return this.app.api.postProcessed('api/titulo/remover', payload.id);
              } else {
                return;
              }
            })
            .then(result => {
              if (result === true) {
                this.rg.refreshPage();
                this.app.notificationSuccess("Registo removido!");
              } else {
                if (result.status == 'error') this.app.notificationError(result.message);
              }
              return this.isBusy = false;
            })
            .catch(err => this.app.notificationErrorCompact(err));
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[titulo-lista]", "RG_CELL_CHANGE", payload);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          this.doAction('DETALHE', payload.rowRef);
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[titulo-lista]", "RG_ROW_CLICK", payload);
          this.isBusy = false;
          break;
        }

        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[titulo-lista]", "Acção DESCONHECIDA [titulos-lista]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
