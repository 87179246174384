import { Aurelia } from 'aurelia-framework'
import environment from './environment';
import * as moment from "moment";
import { setupValidationRules } from "./validation/custom-rules";
import { jsRedirect } from "./utils/ItMultiPurpose";

if (document.location.protocol.startsWith("https")) {
  if (environment.debug) console.log("[main]", "HTTPS OK!");
} else {
  if (environment.debug) console.log("[main]", "REDIRECTING TO HTTPS", document.location.hostname, document.location.hostname.endsWith("localhost"));
  if (!environment.debug && !document.location.hostname.endsWith("localhost")) jsRedirect("https://" + document.location.host);
}

//Configure Bluebird Promises.
if (environment.debug)
  (<any>Promise).config({ warnings: { wForgottenReturn: false } });
else
  (<any>Promise).config({ warnings: false });

//Configure Moment
moment.locale("pt");


export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin('aurelia-validation')
    .plugin('aurelia-dialog', config => {
      config.useDefaults();
      config.settings.lock = true;
      config.settings.startingZIndex = 200;
      config.settings.keyboard = [/*'Enter', */'Escape'];
      config.settings.centerHorizontalOnly = true;
      config.useCSS("");
      //config.settings.rejectOnCancel = true;
    })
    .feature('resources')
    .feature('it-v-grid')
    .feature('it-features');

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  if (environment.testing) {
    aurelia.use.plugin('aurelia-testing');
  }

  setupValidationRules();

  if (environment.debug) console.log("[main]", "Setting isMobile to false.");
  window && (window["isMobile"] = (window.innerWidth < 900 || false));

  return aurelia.start().then(() => aurelia.setRoot());
}
