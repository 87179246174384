import {autoinject, bindable, bindingMode} from "aurelia-framework";
import {DialogCancellableOpenResult, DialogController, DialogOpenPromise, DialogService, DialogSettings} from "aurelia-dialog";
import {ColConfigInterface, EntityInterface, FilterObjectInterface} from "../../it-v-grid/interfaces";
import environment from "../../environment";
import {dateISOString} from "../../utils/ItNumeric";
import {RemoteGrid} from "./remote-grid";

@autoinject()
export class DistinctRgColFilter {
  protected controller: DialogController;

  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean = true;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string   = "";

  /**
   * Instância da remoteGrid
   */
  @bindable({defaultBindingMode: bindingMode.oneTime}) rg: RemoteGrid;
  @bindable({defaultBindingMode: bindingMode.oneTime}) col: string;
  @bindable({defaultBindingMode: bindingMode.oneTime}) usePopup: boolean = true;

  /**
   * flag que determina a visibilidade do popup?
   * @type {boolean}
   */
  private showPopup: boolean = false;

  private isFetching: boolean = false;

  //resultados do servidor para a pesquisa sobre a coluna atual
  private resultadosPesquisa: string[] = [];

  private selecaoActual: string[] = [];

  private s:any = {};

  private internalValue: string = "";

  private inputElement:HTMLInputElement = null;

  bind(a, b) {
    if (this.debug) console.log("[distinct-rg-col-filter]", "bind", a, b, this);
    if (!this.rg) console.error("[DistinctRgColFilter]", "É necessário fazer bind a uma RemoteGrid");
    if (!this.col) console.error("[DistinctRgColFilter]", "É necessário fazer bind da coluna que se pretende filtrar");

    if(this.value) {
      this.selecaoActual = this.value.split(";");
      this.selecaoActual.forEach(e => this.s[e] = true);
    }
  }

  attached() {
    if (this.debug) console.log("[distinct-rg-col-filter]", "attached", this);
    if (this.inputElement) {
      if(environment.debug) console.log("[filter-dialog]","AutoFocusing");
      this.inputElement.focus();
    }
  }

  detached() {
    if (this.debug) console.log("[distinct-rg-col-filter]", "deattached", this);
  }

  unbind() {
    if (this.debug) console.log("[distinct-rg-col-filter]", "unbind", this);
  }

  todosResultados() {
    this.getData();
  }

  getData() {
    if (this.debug) console.log("[distinct-rg-col-filter]", "getData", this.internalValue);

    if (!this.isFetching) {
      this.isFetching = this.showPopup = true;
      return this.rg.getDistinctDataFor(this.col, this.internalValue)
        .then(r => {
          if (this.debug) console.log("[distinct-rg-col-filter]", "reposta servidor", r);
          this.resultadosPesquisa = r.data.map(el => el[this.col]);
          this.isFetching         = false;
        })
    }
    if (this.debug) console.log("[distinct-rg-col-filter]", "onHold!");
  }

  aplicaFiltro() {
    if (this.debug) console.log("[distinct-rg-col-filter]", "aplicaFiltro", this.selecaoActual);
    this.rg.filter([{attribute: this.col, operator: "in", value: this.selecaoActual.join(";")}]);
    this.showPopup = false;
  }

  toggleElement(r, e:Event) {
    if (this.debug) console.log("[distinct-rg-col-filter]", "toggleElement", r);
    let idx = this.selecaoActual.indexOf(r);
    if (idx >= 0) {
      this.selecaoActual.splice(idx, 1);
      this.s[r] = false;
      // e && e.srcElement.classList.remove("selected")
    } else {
      this.s[r] = true;
      this.selecaoActual.push(r);
      // e && e.srcElement.classList.add("selected")
    }
    this.value = this.selecaoActual.join(";");
    if (this.debug) console.log("[distinct-rg-col-filter]", "toggleElement", this.selecaoActual, this.selecaoActual.includes(r));
    return true;

  }

  cancelaSelecao() {
    this.internalValue      = "";
    this.selecaoActual      = [];
    this.resultadosPesquisa = [];
    this.showPopup          = false;
  }
}
