//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";


@autoinject()
export class DebitosLista {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  constructor(public app: GlobalServices) { }

  /**
   * doAction Loacl
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[logs-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "EDITAR": {
          if (environment.debug) console.log("[assinantes-listagem]", "EDITAR", payload);
          this.app.router.navigate("#/assinantes/assinantes-detalhe?id=" + payload.id, {replace: true});
          break;
        }

        case "IMPRIMIR-DEBITOS": {
          return this.app.confirmaPopup(["PRETENDE IMPRIMIR " + this.rg.totalFiltrados + " NOTAS DE DÉBITO?"])
            .then(response => {
              if (response === true) {
                this.app.notificationInfo("A imprimir " + this.rg.totalFiltrados + " notas de débito. Este processo poderá demorar vários minutos!");
                var py = this.rg.serverPayload();
                this.isBusy = true;
                this.app.api.postProcessed('api/assinanteassinatura/imprime-debitos-assinantes-filtrado', py)
                  .then(response => {
                    Array.from(response['files']).forEach(file => {
                      let linkSource = `data:${file['contentType']};base64,${file['fileContents']}`;
                      let downloadLink = document.createElement("a");
                      let fileName = file['fileDownloadName'];
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    });
                    return this.isBusy = false;
                  })
              } else {
                return this.isBusy = false;
              }
            })
            .catch(err => { this.isBusy = false; this.app.notificationErrorCompact(err) })
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[logs-listagem]", "RG_CELL_CHANGE", payload);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          if (environment.debug) console.log("[logs-listagem]", "RG_ROW_CLICK", payload);
          this.isBusy = false;
          break;
        }

        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[movimentos-caixa-listagem]", "RG_ROW_CLICK", payload);
          this.isBusy = false;
          break;
        }

        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[logs-listagem]", "Acção DESCONHECIDA [logs-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
