import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../../../services/global-services";
import environment from "../../../../environment";

@autoinject()
export class Page1 {
  message: string;

  constructor(private app: GlobalServices) {
    if(environment.debug) console.log("[page-1]","constructor");
    this.message = 'Hello world';
  }
}