import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../../services/global-services";
import {RemoteGridActions} from "./interfaces";
import {FilterObjectInterface, SortObjectInterface} from "../../it-v-grid";
import {RemoteGrid} from "./remote-grid";

@autoinject()
export class PickerRemoteGrid {
  protected controller: DialogController;
  private app: GlobalServices;
  private isReady: boolean = false;

  //region bindables da RemoteGrid
  private debug: boolean                         = false;
  private codigoTabela: string                   = "check";
  private vgridStyle: string                     = "height:60vh; width:100%";
  private paginacao: string                      = "auto";
  private defaultSort: SortObjectInterface[]     = [];
  private defaultFilter: FilterObjectInterface[] = [];

  private descricao:string = null;

  //endregion bindables da RemoteGrid

  //region propriedades específicas do dialog
  private informacao: string = null;
  //endregion propriedades específicas do dialog

  /**
   * por enquanto, sem uso no contexto do picker
   */
  private doAction: any;
  private selectionMode: "none" | "single" | "multiple" = "single";
  private rgPicker: RemoteGrid;

  constructor(controller: DialogController) {
    this.controller = controller;
    // this.as         = auth;
  }

  canActivate(p) {
    if (this.debug) console.log("[picker-remote-grid]", "canActivate", p);
    if (!p) {
      console.error("É necessária a introdução de alguns parâmetros para uso deste Picker");
      return false;
    } else {
      if (!p.codigoTabela) {
        console.error("O codigoTabela não foi especificado. Não é possível invocar o picker.");
        return false;
      }
      this.codigoTabela = p.codigoTabela;
      //inicializar variáveis ligadas à RG
      //todo: usar uma classe/interface para passagem destes parametros.
      if (p.vgridStyle) this.vgridStyle = p.vgridStyle;
      if (p.paginacao) this.paginacao = p.paginacao;
      if (p.defaultSort) this.defaultSort = p.defaultSort;
      if (p.defaultFilter) this.defaultFilter = p.defaultFilter;
      if (p.doAction) this.doAction = p.doAction;
      if (p.selectionMode) this.selectionMode = p.selectionMode;
      if (p.descricao) this.descricao = p.descricao;
      if (p.debug) this.debug = true;

      //
      if (p.informacao) this.informacao = p.informacao;
    }
    return true;
  }

  attached() {
    this.isReady = true;
  }

  canDeactivate() {
    if (this.debug) console.log("[picker-remote-grid]", "canDeactivate");
    return true;
  }

  public doActionPicker(action: string, payload?: any) {
    if (this.debug) console.log("[picker-remote-grid]", "doAction Picker");

    //um picker reage a.... selecções (picks)
    //tada!
    try {
      switch (action) {
        case "OK": {
          let selection = this.rgPicker.selecaoAtual();
          // let objs = selection.map(el => Object.assign({}, el));
          let objs      = JSON.parse(JSON.stringify(selection));
          //console.log("OK", selection, objs);
          this.controller.ok(objs);
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (this.selectionMode != "multiple")
            this.controller.ok(payload);
          break;
        }
      }
    } catch (err) {
      console.error("[picker-remote-grid]", "Erro", err);
    }
  }
}
