//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {GlobalServices} from "../../../services/global-services";
import {level_2} from "../../../mobile-routes";
import {Level1Component} from "../level-1-component";

@autoinject()
export class Level2Component {
  public app: GlobalServices;
  public childRouter: Router;
  public navigation;

  constructor(gs: GlobalServices, protected parent: Level1Component) {
    this.app        = gs;
  }

  // if(environment.debug) console.log("[sub-module-component]","router",router);

  configureRouter(config: RouterConfiguration, router: Router) {
    // config.title = 'LEVEL 2';
    config.map(level_2);
    this.childRouter = router;
  }
}