import {autoinject, bindable, computedFrom} from "aurelia-framework";
import {CDataSource} from "./cDataSource";
import {CGridConnector} from "./cGridConnector";
import {Api} from "../../services/api";
// import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions, ServerPayloadInterface, ServerResponseInterface} from "./interfaces";
import {BindingContextInterface, ColConfigInterface, EntityInterface, OverrideContextInterface, Selection} from "../../it-v-grid/interfaces";
import {FilterObjectInterface, SortObjectInterface, VGrid} from "../../it-v-grid";
import {copyProperties} from "../../utils/ItMultiPurpose";
import {DialogService} from "aurelia-dialog";
import {FilterDialog, FilterDialogOptions} from "./filter-dialog";
import environment from "../../environment";

@autoinject()
export class RemoteGrid {
  /**
   * wrapper de uma v-grid que, mediante um localizador, vai à webapi buscar a definição da tabela.
   *
   * Requisitos:
   * ~~não precisa da instalação do plugin aurelia-v-grid e da sua configuração.~~
   * precisa da derivação da v-grid: `it-v-grid`
   *
   */
  private ds: CDataSource;
  private gridConnector: CGridConnector;
  private collection: EntityInterface[];
  private columns: ColConfigInterface[] = [];
  private api: Api;

  //internals
  private baseUri: string;
  private hasError: string = null;
  private vg: VGrid;
  private htmlElement: HTMLDivElement;

  //determina se a VM está a correr em ambiente mobile.
  private isMobile: boolean = true;

  private emitTimer;
  private debounceInterval = 400;

  //memoriza uma referência numérica para se poder desabilitar o registo de eventos
  dsEventID: number;

  // tipo de paginação a aplicar à grid
  private pagingType: "none" | "page" | "infinite" = "none";

  // bitsyspiders
  private hasGridDefinition: boolean = false;
  private hasPager: boolean;

  //endregion variáveis de alturas para v-grid

  //region variáveis de paginação
  public start: number              = 0;
  public tamanhoPagina: number      = 0; // se tamanhoPagina <= 0 é calculado um valor com base na altura do container da v-grid
  public draw: number               = 1;
  public totalRegistos: number      = 0;
  public totalFiltrados: number = 0;
  public aggregation: any = null;
  private dialogService: DialogService;
  private isTransfering: boolean    = false;
  private isTransferingPdf: boolean = false;

  @computedFrom("totalRegistos", "totalFiltrados", "start")
  public get pagina() {
    if (this.totalRegistos > 0) {
      return Math.ceil(this.start / this.tamanhoPagina) + 1;
    }
    return 0;
  }

  @computedFrom("totalRegistos", "totalFiltrados", "tamanhoPagina")
  public get totalPaginas() {
    if (this.totalRegistos > 0) {
      if (this.totalRegistos <= this.totalFiltrados)
        return Math.ceil(this.totalRegistos / this.tamanhoPagina) || 1;
      return Math.ceil(this.totalFiltrados / this.tamanhoPagina) || 1;
    }
    return 0;
  }

  //endregion variáveis de paginação

  //region bindables
  @bindable debug: boolean       = false;
  @bindable codigoTabela: string = "check";
  //porque é que é necessário um códigoExcel?
  //@bindable codigoTabelaExcel: string                     = "check";
  @bindable vgridStyle: string                            = "height:80vh; min-height:400px; width:100%;";
  @bindable selectionMode: "none" | "single" | "multiple" = "single";
  @bindable vHeaderHeight: number                         = 50;
  @bindable vRowHeight: number                            = 25;
  @bindable vPanelHeight                                  = 1;
  @bindable vFooterHeight                                 = 50;
  @bindable columnsOverride: ColConfigInterface[]         = [];

  private hasManualSelection: boolean = false;

  /**
   * O parametro paginação pode ser:
   * "none" - coloca a pagina a 100000
   * "auto" - adivinha o tamanho da página com base nos valores conhecidos
   * ou um valor e.g. "50"
   * @type {string}
   */
  @bindable paginacao: string = "auto";

  /**
   * Conjunto de propriedades que devem ser ordenadas por defeito
   */
  @bindable defaultSort: SortObjectInterface[] = [];

  /**
   * Conjunto de propriedades que devem ser ordenadas por defeito
   */
  @bindable defaultFilter: FilterObjectInterface[] = [];
  // defaultFilter: FilterObjectInterface[] = [];

  //todo: ... formalizar os filtros numa estrutura sempre acessível
  currentFilter: FilterObjectInterface[] = [];

  public filter(filterDef) {
    this.ds.query(filterDef);
    this.gridConnector.raiseEvent("filterUpdateDefaultValues");
    return true;
  }

  /**
   * referência à dispatcher do componente parente
   *
   * A função existente no viewModel deve seguir a seguinte assinatura.
   * dispatcher (action:string, payload?:any)
   *
   */
  @bindable dispatcher: any;

  public bind(bindingContext: BindingContextInterface, overrideContext: OverrideContextInterface): void {
    //apenas usa o debug se o ambiente for esse
    this.debug = this.debug && environment.debug;
    if (this.debug) console.log("[remote-grid]", "bind", bindingContext, overrideContext, this);

    //falou-se em se colocar esta atribuição de tamanhos no construtor, contudo tendo em conta a natureza do vgridStyle o ideal será colocar aqui para nos casos onde o vgridStyle já vem preenchido
    //A atribuição do isMobile é feita no construtor consoante a global da window do mesmo nome
    if (this.isMobile) {
      this.vHeaderHeight = 100;
      this.vRowHeight    = 50;
      this.vFooterHeight = 50;
      // if(!this.vgridStyle.includes('font-size'))
      this.vgridStyle += " font-size: 16px;"
    }
    this.bootGrid();
  }

  public detached() {
    if (this.debug) console.log("[remote-grid]", "detached", this.ds, this.gridConnector, this.vg);
    // this.vg && this.vg.destroy();
    // this.vg            = null;
    // this.gridConnector = null;
    // this.ds            = null;
    if (this.debug) console.log("[remote-grid]", "detached", this.vg);
  }

  // public unbind() {
  //   if(this.debug) console.log("[remote-grid]","unbind", this.ds, this.gridConnector);
  //   this.hasGridDefinition = false;
  //   this.columns = [];
  //   this.ds = null;
  //   this.gridConnector = null;
  //   if(this.debug) console.log("[remote-grid]","unbind", this.ds, this.gridConnector);
  // }
  //
  /**
   * O código da tabela alterou-se? (reinicia-se tudo)
   * rotina de efeito do observer existente pelo binding à propriedade "codigoTabela"
   * @param {string} newValue
   * @param {string} oldValue
   */
  codigoTabelaChanged(newValue: string, oldValue: string) {
    if (this.debug) console.log("[remote-grid]", "codigoTabelaChanged", newValue, oldValue);
    // this.hasGridDefinition = false;
    this.refreshPage(false)
      .then(_ => this.resetFiltering())
      .then(_ => this.hasGridDefinition = true);
  }

  //endregion bindables

  constructor(api: Api, ds: DialogService) {
    if (this.debug) console.log("[remote-grid]", "constructor", this);
    this.api           = api;
    this.dialogService = ds;

    //Assumir o ambiente consoante a global isMobile
    if (window) {
      if (window["isMobile"] !== undefined) {
        this.isMobile = !!window["isMobile"];
      } else {
        //a global não existe, fitamo-nos na largura do ecrã
        this.isMobile = window.innerWidth < 1281;
      }
    }

    //esta é uma definição está hard-coded
    //todo: procurar um meio de configurar externamente
    this.baseUri = environment.endpoint + 'api/it-remote-grid/';
  }

  //region configurações

  /**
   * Boots the grid
   */
  public bootGrid() {
    this.hasGridDefinition = false;
    this.initGridDependencies();

    switch (this.paginacao) {
      case "none": {
        this.tamanhoPagina = 1000000;
        this.hasPager      = false;
        break;
      }
      case "auto": {
        //força o cálculo do tamanho de página
        this.tamanhoPagina = 0;
        this.hasPager      = true;
        break;
      }
      default:
        this.tamanhoPagina = ~~this.paginacao || 0;
        this.hasPager      = true;
    }

    if (this.paginacao == "none") this.tamanhoPagina = 1000000;

    this.configureGrid()
    //.then(_ => this.refreshPage(false));
  }

  /**
   * Inicializa as dependências da RemoteGrid
   */
  private initGridDependencies() {
    if (this.debug) console.log("[remote-grid]", "initGridDependencies");
    //cria um datasource (o dono dos dados (collection), e do estado de sort/filtro/grupo (arrayutil))
    this.ds = new CDataSource(new Selection(this.selectionMode));

    //cria um grid connector (atua como frontend do controlador associado à v-grid)
    this.gridConnector = new CGridConnector(this.ds, this);

    this.dsEventID = this.ds.addEventListener(this.dsEvents.bind(this));
  }

  private dsEvents(e, pl) {
    // if(this.debug)
    console.log("[remote-grid]", "dsEvents e", e, pl);
    this.ds && console.log("[remote-grid]", "dsEvents", this.ds.getCurrentFilter());
    return true;
  }

  /**
   * Funde as definições recebidas do servidor com quaisquer sobreposições válidas
   */
  mergeSobreposicoes(r: {titulo: string, columns: ColConfigInterface[]}) {
    if (Array.isArray(this.columnsOverride)) {
      this.columnsOverride.forEach(ovr => {
        let idx = r.columns.findIndex(c => c.colField == ovr.colField);
        if (idx >= 0) { r.columns[idx] = Object.assign({}, r.columns[idx], ovr)}
        else { r.columns.push(ovr); }
      })
    }
    return r.columns;
  }

  /**
   * pede a uma store local as colunas da tabela,
   * Se não existirem pede-as ao servidor e regista-as na store local.
   */
  private configureGrid() {
    //todo: meio de forçar o pedido das colunas
    //todo: meio de interceptar e evitar o pedido com uma store física que pode ser injetada no <remote-grid>
    return this.getGridConfig()
      .then(r => this.mergeSobreposicoes(r))
      .then(r => this.columns = r.map(this.traduzPseudoTipos))
      .then(cols => this.hasManualSelection = !!(cols.find(c => c.colType === "selection")))
      .then(r => {
        if (this.debug) console.log("[remote-grid]", "a vgrid já foi binded?");
        //por esta altura conhecem-se todas as definições oriundas do servidor + sobreposiçoes colunas no viewModel parente da remoteGrid
        //a execução ficam em hold até que o gridConnnector dê o aviso
        return this.hasGridDefinition = true;
      })
      .catch(err => {
        console.error("[remote-grid]", "configureGrid", err);
        this.hasError = err && (err.message || err.error || err) || 'Ocorreu um erro';
      })
    //.then(_ => this.)
  }

  public continueConfigureGrid() {
    if (this.debug) console.log("[remote-grid]", "continueConfigureGrid");

    return this.configurePageSize()
      .then(_ => this.ajustaLargurasColunasLivres())
      .then(_ => this.assumeDefaultFiltering())
      .then(_ => console.log("continueConfigureGrid end"))
      .catch(err => {
        console.error("[remote-grid]", "continueConfigureGrid", err);
        this.hasError = err && (err.message || err.error || err) || 'Ocorreu um erro';
      })
  }

  /**
   * Heavy-lift de pseudo tipos
   * todo: meio de injetar/sobrepor pseudo-tipos a partir do view-model
   */
  private traduzPseudoTipos(col: ColConfigInterface, i: number) {
    /*
        //condição que desativa o comportamento comtrol e shift
        if(col.colType === "selection") this.hasManualSelection = true;
    */
    switch (col.colType) {
      case "it-checkbox":
        col.colType             = "checkbox";
        col.colWidth            = col.colWidth || 40;
        col.colResizeable       = "false";
        col.colFilter           = `field:${col.colField};operator:=`;
        col.colAddRowAttributes = `change.delegate='dispatch($event, rowRef, tempRef, "${col.colField}")' tabindex.bind="tempRef.__avgKey"`;
        break;
      case "it-checkbox-readonly":
        col.colType             = "checkbox";
        col.colWidth            = col.colWidth || 40;
        col.colResizeable       = "false";
        col.colFilter           = `field:${col.colField};operator:=`;
        col.colAddRowAttributes = `readonly disabled tabindex="-1"`;
        break;
      case "it-bool-sim-nao":
        col.colWidth = col.colWidth || 40;
        col.colRowTemplate = col.colRowTemplate || `<span if.bind="rowRef.${col.colField}">Sim</span><span else>Não</span>`;
        col.colHeaderTemplate = `<p class="avg-label-top">${col.colHeaderName}</p>
            <select class="avg-header-input-bottom" v-filter-observer="field:${col.colField}; operator:*; converter:identity; value.bind:sn${col.colField}" value.two-way="sn${col.colField}">
              <option model.bind="null">Todos</option>
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>`;
        col.colCss            += "text-align: center";
        //col.colResizeable       = "false";
        //col.colFilter           = `field:${col.colField};operator:=`;
        //col.colAddRowAttributes = `readonly disabled tabindex="-1"`;
        break;
      case "it-text":
        col.colType             = "text";
        col.colAddRowAttributes = `change.delegate='dispatch($event, rowRef, tempRef, "${col.colField}")' tabindex.bind="tempRef.__avgKey"`;
        break;
      case "it-date-readonly":
        col.colType             = "static";
        col.colWidth            = col.colWidth || 80;
        // col.colHeaderTemplate = `<p class="avg-label-top">${col.colHeaderName}</p><ej-date-picker class="avg-header-input-bottom" v-filter-observer="field:${col.colField}; operator:*; converter:identity; value.bind:dp${col.colField}" value.two-way="dp${col.colField}"></ej-date-picker>`;
        col.colFilter           = `field:${col.colField};operator:=`;
        col.colAddRowAttributes = `readonly disabled tabindex="-1"`;
        col.colField            = col.colField + " | trim10";
        col.colCss              += "text-align: center";
        break;
      case "it-date-time-readonly":
        col.colType             = "static";
        col.colWidth            = col.colWidth || 140;
        col.colFilter           = `field:${col.colField};operator:=`;
        col.colAddRowAttributes = `readonly disabled tabindex="-1"`;
        col.colField            = col.colField + " | cleanIsoDate";
        col.colCss              += "text-align: center";
        break;
      case "static":
        col.colType             = "text";
        col.colAddRowAttributes = `tabindex.bind="-1" readonly`;
        col.colRowTemplate      = `\${rowRef.${col.colField}}`;
        break;
    }
    return col;
  }

  /**
   * Identidica o espaço "a mais" disponível no container da v-grid
   * Aumenta a largura das colunas "variáveis" (visiveis e não fixas) relativamente à sua proporção original
   */
  private ajustaLargurasColunasLivres() {
    //if(this.debug) console.log("[remote-grid]","ajustaLargurasColunasLivres");
    let gridContainer      = this.vg.container && ((this.vg.container as any).element as HTMLElement);
    let gridContainerWidth = gridContainer && gridContainer.clientWidth;

    let colunasVisiveis = this.columns.filter(c => !c.colHidden);

    let larguraColunasFixas      = colunasVisiveis.filter(c => c.colPinLeft || c.colPinRight).reduce((acc, el) => acc + +el.colWidth, 0);
    let colunasVisiveisVariaveis = colunasVisiveis.filter(c => !c.colPinLeft && !c.colPinRight);
    let larguraColunasVariaveis  = colunasVisiveisVariaveis.reduce((acc, el) => acc + +el.colWidth, 0);

    let espacoDisponivelVariavel = gridContainerWidth - larguraColunasFixas/* - 17*/;
    if (espacoDisponivelVariavel > 0 && larguraColunasVariaveis > 0 && espacoDisponivelVariavel > larguraColunasVariaveis) {
      //devem-se alargar as colunas variáveis
      let fator = espacoDisponivelVariavel / larguraColunasVariaveis;
      if (this.debug) console.log("[remote-grid]", "ajustaLargurasColunasLivres Factor", fator);
      if (fator >= 1) {
        colunasVisiveisVariaveis.forEach(c => c.colWidth = Math.floor(+c.colWidth * fator));

        let espacoRealOcupado = larguraColunasFixas + colunasVisiveisVariaveis.reduce((acc, el) => acc + +el.colWidth, 0);
        let remanescente      = gridContainerWidth - espacoRealOcupado;
        colunasVisiveisVariaveis[0].colWidth += remanescente;
        this.gridConnector.setColConfig(this.columns);
      }
    }
  }

  private reajustaLargurasColunasLivres() {
    let gridContainer      = this.vg.container && ((this.vg.container as any).element as HTMLElement);
    let gridContainerWidth = gridContainer && gridContainer.clientWidth;

    let larguraOcupada = this.columns.filter(c => !c.colHidden).reduce((acc, el) => acc + +el.colWidth, 0);
    if (gridContainerWidth != larguraOcupada) {
      if (this.debug) console.log("[remote-grid]", "reajustaLargurasColunasLivres", gridContainerWidth, larguraOcupada);
      this.ajustaLargurasColunasLivres();
    }
  }

  /**
   * Infere o tamanho da página (previne o scroll vertical)
   * Para esta operação ser bem sucedida assume-se que a altura das linhas é constante
   */
  private configurePageSize() {

    if (this.tamanhoPagina <= 0) {
      if (this.debug) console.log("[remote-grid]", "configurePageSize", this, this.vg);
      window["vg"]            = this.vg;
      window["vge"]           = this.htmlElement;
      let rowHeight           = this.vg.attRowHeight;
      let fixedSizeOnGrid     = this.vg.attPanelHeight + this.vg.attHeaderHeight + this.vg.attFooterHeight;
      let gridContainer       = this.vg.container && ((this.vg.container as any).element as HTMLElement);

      let gridContainerHeight = gridContainer && gridContainer.clientHeight;
      if (gridContainerHeight <= 0) {
        console.warn("O primeiro cálculo para o tamanho de página falhou!", gridContainer, gridContainer.style.height);
        let cssHeight = gridContainer.style.height;
        if(cssHeight.endsWith("vh")) {
          gridContainerHeight = Math.round(window.innerHeight * Number.parseFloat("." + cssHeight));
        } else if (cssHeight.endsWith("px")) {
          gridContainerHeight = Number.parseInt(cssHeight);
        }

        if (this.debug) console.log("[remote-grid]", "configurePageSize", fixedSizeOnGrid, gridContainer, gridContainerHeight);
      }


      this.tamanhoPagina      = Math.floor((gridContainerHeight - fixedSizeOnGrid) / rowHeight);
      this.start              = 0;
      if (this.debug) console.log("[remote-grid]", "configurePageSize", "fixedSizeOnGrid", fixedSizeOnGrid, "gridContainer", gridContainer, "gridContainerHeight", gridContainerHeight, "this.tamanhoPagina", this.tamanhoPagina);
    }

    return Promise.resolve(true);
  }

  /**
   * Pede ao servidor uma "janela" de dados e rederiza-os no elemento
   * @returns {Promise<void>}
   */
  public refreshPage(keepFilters: boolean = true) {
    if (this.debug) console.log("[remote-grid]", "refreshPage");
    this.reajustaLargurasColunasLivres();

    return this.getData()
      .then((r: ServerResponseInterface) => {
        if (r.draw != this.draw) console.warn("misplaced draw!");
        this.totalRegistos  = r.recordsTotal;
        this.totalFiltrados = (r.aggregation && r.aggregation.recordsFiltered) || 0;
        if (keepFilters) {
          this.ds.refresh(r.data);
        } else {
          //this.ds.arrayUtils
          this.ds.setArray(r.data);
          if (Array.isArray(this.defaultSort) && this.defaultSort.length > 0) {
            this.ds.setOrderByInternal(this.defaultSort);
          }
        }

        this.aggregation = r.aggregation;
        

        //this.setLoader(false);
        // this.gridConnector.raiseEvent("filterUpdateValues");

        //todo: tratar das agregações (custom element tipo quadro?)

        //argquivo: experiência com tipo?
        //a vantagem é que todos os setters e getters vêm agarrados (e.g. coloração de uma célula fica na classe ao invés de ser realizada na configuração da tabela)
        //as desvatagens:
        //* preformance
        //* megaFactory
        // let tipado = Utilizador.multipleFromPOJSO(r.data);
        //this.ds.refresh(tipado);
      })
  }

  public resetFiltering() {
    if (this.debug) console.log("[remote-grid]", "resetFiltering", this.defaultFilter, this.defaultFilter[0]);
    this.setPage(0);
    this.assumeDefaultFiltering();
  }

  private assumeDefaultFiltering() {
    let options = this.defaultFilter.map(el => Object.assign({}, el));
    if (this.debug) console.log("[remote-grid]", "assumeDefaultFiltering", options);
    if (Array.isArray(this.defaultSort) && this.defaultSort.length > 0) {
      this.ds.setOrderByInternal(this.defaultSort);
    }
    this.ds.query(options);
    this.gridConnector.raiseEvent("filterUpdateDefaultValues");
  }

  /**
   * define as variáveis para visualização duma certa página
   * @param {number} nextPage
   * @return {boolean} - se necessita refresh
   */
  public setPage(nextPage: number) {
    if (nextPage <= 0) nextPage = 1;
    if (nextPage > this.totalPaginas) nextPage = this.totalPaginas;

    //preparar o valor de start
    let newStart = (nextPage - 1) * this.tamanhoPagina;
    if (newStart != this.start) {
      this.start = newStart;
      this.ds.getSelection().deSelectAll();
      return true;
    }
    return false;
  }

  /**
   * Define nova página e pede refresh caso seja necessário
   * @param {number} nextPage
   */
  public goToPage(nextPage: number) {
    if (this.setPage(nextPage)) this.refreshPage();
  }

  //endregion configurações

  // region chamadas ao servidor
  /**
   * pede ao servidor o array de colunas de definição da tabela
   *
   * todo: memorizar este pedido em localStorage? sessionStorage? ou IndexedDb? ou... nenhum?
   */
  private getGridConfig() {
    return this.api.getProcessed(this.baseUri + this.codigoTabela)
  }

  // pede ao servidor uma página conforme o estado atual da grid
  private getData() {
    if (this.debug) console.log("[remote-grid]", "getData");

    this.setLoader();
    return this.api.postProcessed(this.baseUri + this.codigoTabela, this.serverPayload())
  }

  /**
   * Pede ao servidor uma lista de valores distintos de uma coluna
   * @return {Promise<Response>}
   */
  public getDistinctDataFor(col: string, searchFragment) {
    if (this.debug) console.log("[remote-grid]", "getDistinctDataFor", col);
    if (!col) throw new Error("O parâmetro col é obrigatório");
    let payload = this.serverPayload();

    //clone barato dos filtros atuais
    let currentFilters = [...payload.filters];

    let filter = currentFilters.find(el => el.attribute == col);

    //existe algum fragmento de filtro?
    if (searchFragment) {
      if (filter) {
        filter.operator = "*";
        filter.value    = searchFragment;
      } else {
        currentFilters.push({attribute: col, operator: "*", value: searchFragment});
      }
    } else {
      //remover o filtro se existe um
      if (filter) {
        currentFilters.splice(currentFilters.indexOf(filter), 1);
      }
    }
    payload.filters = currentFilters;

    //this.setLoader();
    return this.api.postProcessed(this.baseUri + "d/" + col + "/" + this.codigoTabela, payload)
  }

  /**
   * Exportação para excel (via EPPlus)
   * @return {Promise<Response>}
   */
  public getExcel(nomeFicheiro: string = null) {
    this.isTransfering = true;
    // if (this.debug) console.log("[remote-grid]", "getExcel");
    // if (!this.codigoTabelaExcel) return null;
    let parts = this.codigoTabela.split("?");

    return this.api.post(this.baseUri + 'export-xls/' + this.codigoTabela, this.serverPayload())
      .then(r => this.api.processBlobResponse(r))
      .then(b => this.api.processBlobDownload(b, `${nomeFicheiro || parts[0]}.xlsx`))
      .catch(err => {
        console.error("getExcel", err);
      })
      .then(_ => this.isTransfering = false)
  }

  /**
   * Exportação para PDF (via iTextSharp)
   * @return {Promise<Response>}
   */
  public getPdf() {
    this.isTransferingPdf = true;
    // if (this.debug) console.log("[remote-grid]", "getExcel");
    // if (!this.codigoTabelaExcel) return null;
    let parts = this.codigoTabela.split("?");

    return this.api.post(this.baseUri + 'export-pdf/' + this.codigoTabela, this.serverPayload())
      .then(r => this.api.processBlobResponse(r))
      .then(b => this.api.processBlobDownload(b, `${parts[0]}.pdf`))
      .catch(err => {
        console.error("getPdf", err);
      })
      .then(_ => this.isTransferingPdf = false)
  }

  /**
   * Exportação em RDL para XLS|PDF (via WCF localReport)
   *
   * @param template - o template RDL (tem de existir no servidor)
   * @param tipo - o formato a obter (um de pdf, xls)
   * @param locator - um prefixo para o url
   */
  public getReport(template = null, tipo = "xls", locator = "local-") {
    if (this.debug) console.log("[remote-grid]", "getReport", tipo);

    //força default no tipo
    if (!["xls", "pdf"].includes(tipo)) tipo = "xls";

    let parts = this.codigoTabela.split("?");
    //let tipoUri = (tipo == "xls") ? "report-xls/" : "report-pdf/";

    if(!locator) locator = "";

    let route = `${this.baseUri}${locator}report/${tipo}/${this.codigoTabela}`;
    if (template) {route += "/" + template}
    return this.api.post(route, this.serverPayload())
      .then(r => this.api.processBlobResponse(r))
      .then(b => this.api.processBlobDownload(b, `${parts[0]}.${tipo}`))
      .catch(err => {
        console.error("getReport", err);
      })
      .then(_ => this.isTransferingPdf = false)
  }

  /**
   * Wrapper, por conveniência
   */
  public getReportPdf(template = null) {
    return this.getReport(template, "pdf", "local-");
  }

  /**
   * Exportação em RDL para XLS|PDF (via WCF localReport)
   *
   * @param template - o template RDL (tem de existir no servidor)
   * @param tipo - o formato a obter (um de pdf, xls)
   */
  public getWcfReport(template = null, tipo = "xls") {
    return this.getReport(template, tipo, "");
  }

  /**
   * Wrapper, por conveniência
   */
  public getWcfReportPdf(template = null) {
    return this.getReport(template, "pdf", "");
  }

  // endregion chamadas ao servidor

  // region estado
  /**
   * Cria um payload para sincronia de visualização com o servidor
   *
   * @param {string} fieldToExclude
   * @return {ServerPayloadInterface}
   */
  public serverPayload(fieldToExclude?: string): ServerPayloadInterface {
    if (this.debug) console.log("[remote-grid]", "fn serverPayload");
    let filters = this.gridConnector.getCurrentFilter();
    let sort    = this.gridConnector.getCurrentOrderBy();

    if (fieldToExclude) {
      filters = filters.filter(el => el.attribute != fieldToExclude)
    }

    return {
      permanent: [],
      sort     : sort,
      filters  : filters,
      start    : this.start,
      length   : this.tamanhoPagina,
      draw     : this.draw
    };
  }

  /**
   * manipula o componente de loader da remote-grid
   * @param {boolean} val
   */
  setLoader(val = true) {
    if (this.debug) console.log("[remote-grid]", "setLoader");
    this.vg.controller.setLoadingScreen(val, 'A carregar...', 20000)
  }

  // endregion estado

  //region selecção
  public selecaoAtual() {
    let idx  = this.gridConnector.getSelection().getSelectedRows();
    let data = idx.map(el => this.ds.getElement(el));
    if (this.debug) console.log("[remote-grid]", "selecaoAtual", idx, data);
    return data;
  }

  public limpaSelecao() {
    if (this.debug) console.log("[remote-grid]", "limpaSelecao");
    this.ds.getSelection().deSelectAll();

    // https://stackoverflow.com/a/6562764
    // limpar qualquer seleção (HTML) resultante de arrasto
    try {
      if (window.getSelection) {window.getSelection().removeAllRanges();}
      else if ((document as any).selection) {(document as any).selection.empty();}
    } catch (e) {}
    this.refreshPage();
  }

  //endregion selecção

  //region obter Resultados
  public getDsResult() {
    return this.ds.getMainArray();
  }
//endregion obter Resultados


  //region eventos & action trigger

  /**
   * Faz a chamada envolvida ao dispatcher passado por bind
   */
  doAction(action, payload) {
    this.dispatcher && typeof this.dispatcher === "function" && this.dispatcher({action: action, payload: payload});
  }

  singleClick(ev) {
    if (this.debug) console.log("[remote-grid]", "singleClick", ev);
    this.actionCreator(RemoteGridActions.RG_ROW_CLICK, {codigoTabela: this.codigoTabela, event: ev, rowRef: ev.data || ev.detail.data});
    return true;
  }

  dblClick(ev) {
    if (this.debug) console.log("[remote-grid]", "dblClick", ev);
    console.log("[remote-grid]", "dblClick", ev);
    this.actionCreator(RemoteGridActions.RG_ROW_DBL_CLICK, {codigoTabela: this.codigoTabela, event: ev, rowRef: ev.data || ev.detail.data})
  }

  /**
   * Despacha um evento do tipo 'RG-CELL-CHANGE' ao parente
   * @param event
   * @param rowRef
   * @param tempRef
   * @param {string} field
   */
  dispatch(event, rowRef?, tempRef?, field?: string) {
    if (this.debug) console.log("[remote-grid]", "dispatch", event, rowRef, tempRef, field);
    if (this.dispatcher && typeof this.dispatcher === "function") {
      this.actionCreator(RemoteGridActions.RG_CELL_CHANGE, {codigoTabela: this.codigoTabela, event: event, rowRef: rowRef, tempRef: tempRef, field: field})
    }
  }

  /**
   * wrapper para fazer o pedido, ou a chamada ao dispatcher.
   * Se se achar necessãrio pode ser trocado po r um eventEmitter, ou messagePublisher
   *
   * a emissão é debounced
   *
   * @param {string} action
   * @param {ActionFromRemoteGrid} localPayload
   */
  private actionCreator(action: string, localPayload?: ActionFromRemoteGrid) {
    if (this.debug) console.log("[remote-grid]", "actionCreator", action, localPayload);
    this.cancelPendingAction();
    if (this.dispatcher && typeof this.dispatcher === "function") {
      localPayload.instance = this;
      this.emitTimer        = window.setTimeout(() => {
        this.dispatcher && this.dispatcher({action: action, payload: localPayload});
      }, this.debounceInterval);
    }
  }

  //há uma acção a ser criada?, remove o timer para a cancelar
  private cancelPendingAction() {
    if (this.emitTimer) {
      window.clearTimeout(this.emitTimer);
      this.emitTimer = null;
    }
  }

  /**
   * Reverte os dados alterados pelo utilizador (sem necessidade de passar pela casa da partida e sem ganhar 2000)
   * @param {ActionFromRemoteGrid} localPayload
   */
  public rollback(localPayload: ActionFromRemoteGrid) {
    copyProperties(localPayload.tempRef, localPayload.rowRef)
  }

  /**
   * Aceita os dados alterados pelo utilizador como correntes (sem necessidade de passar pela casa da partida e sem ganhar 2000)
   * @param {ActionFromRemoteGrid} localPayload
   */
  public commit(localPayload: ActionFromRemoteGrid) {
    copyProperties(localPayload.rowRef, localPayload.tempRef);
  }

  //endregion eventos & action trigger

  //region assorted

  /**
   * Abre uma dialog para filtrar a vgrid
   *
   * 1. localiza a definição da coluna a filtrar
   * 2. determina a dimensão da célula do filtro
   * A. abre o popup
   * B. Se o popup foi aceite aplica o filtro,
   * B. Se não não faz nada
   *
   * @param {MouseEvent} e
   * @param keyField
   */
  openFilter(e: MouseEvent, keyField) {
    if (this.debug) console.log("[remote-grid]", "openFilter", e, keyField);
    e.preventDefault();
    e.stopPropagation();

    //determinar a coluna associada à procura
    let col = this.columns.find(el => el.key == keyField);
    if (!col) {
      console.error("openFilter", "A definição da coluna não existe para o " + keyField, this.columns, e);
      return;
    }
    if (this.debug) console.log("[remote-grid]", "column", col);
    if (col.colFilter === "false") {
      console.error("openFilter", "A coluna " + keyField + " não prevê filtros introduzidos pelo utilizador", col);
      return;
    }

    //determinar o filtro atual para a coluna
    let currFilters = this.gridConnector.getCurrentFilter();
    let f           = currFilters.find(el => el.attribute == keyField);
    if (this.debug) console.log("[remote-grid]", "Applied filter", currFilters, f);
    let newFilter = false;
    if (!f) {
      newFilter = true;
      //a coluna ainda não foi filtrada, cria um filtro "vazio" e adiciona-o à lista de filtros aplicados
      if (this.debug) console.warn("[remote-grid]", "Applied filter not found!");
      f = {attribute: keyField, operator: "*", value: ""};
      currFilters.push(f);
    }

    //determinar offset do <filter-proxy> ou <div> mais próximo
    let offset: any = null;
    if (e.srcElement) {
      let origin = e.srcElement.closest("filter-proxy") || e.srcElement.closest("div");
      offset     = this.getOffset(origin);
    }

    let options: FilterDialogOptions = {
      openAs        : this.isMobile ? "popup" : "menu",
      offset,
      align         : col.colProxyAlign || "left",
      type          : col.colProxyFilterType,
      title         : col.colHeaderName,
      keyVal        : col.colProxyKeyVal,
      filterValue   : f.value,
      filterOperator: f.operator,
    };
    // adicionar informação extra no caso de distinct-rg-col-filter
    if (options.type === "distinct-rg-col-filter") {
      options.field = keyField;
      options.rg    = this;
    }

    if (this.debug) { console.log("[remote-grid]", "openFilter", options);}

    //Abre o FilterDialog. No caso de este não ser cancelado aplica a nova filtragem.
    FilterDialog.Open(this.dialogService, options, {overlayDismiss: true, ignoreTransitions: true, keyboard: ["Enter", "Escape"]})
      .whenClosed(r => {
        if (!r.wasCancelled) {
          if (this.debug) console.log("[remote-grid]", "dialog Ok", r);
          if ((f.value !== r.output.value) || (!newFilter && f.operator != r.output.operator)) {
            f = Object.assign(f, r.output);
            if (this.debug) console.log("[remote-grid]", "dialog Ok wil apply", f, currFilters);

            //é para remover o filtro?
            if (!f.value) {
              currFilters = currFilters.filter(el => el.attribute !== f.attribute);
              if (this.debug) console.log("[remote-grid]", "A remover o filtro", f, currFilters);
            }
            if (currFilters.length > 0) {
              this.filter(currFilters);
            } else {
              this.resetFiltering();
            }
          }
        } else {
          if (f.value === "") {
            f = Object.assign(f, r.output);
            if (this.debug) console.log("[remote-grid]", "dialog Ok wil apply", f, currFilters);

            //é para remover o filtro? sim
            currFilters = currFilters.filter(el => el.attribute !== f.attribute);
            if (currFilters.length > 0) {
              this.filter(currFilters);
            } else {
              this.resetFiltering();
            }
          }
          //O dialog foi fechado, deve-se limpar o filtro?
        }
      });

    //this.dialogService.open({viewModel: FilterDialog, model: {col, offset}, overlayDismiss: true, ignoreTransitions: true})
  }

  /**
   * Obtém as dimensões relevantes associadas a um elemento
   * @param {Element} el
   * @returns {{left: number; top: number; width: number}}
   */
  getOffset(el: Element) {
    const rect = el.getBoundingClientRect();
    var topPosition = (window.innerHeight - rect.top) <= 200 ? rect.top-200 : rect.top;
    return {
      left : rect.left + window.scrollX,
      top: topPosition,
      width: rect.width
    };
  }

  //endregion assorted
}
