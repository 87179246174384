// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
//no imports

//export classe
export class SysLog extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public nvcSessao : string = "00000000-0000-0000-0000-000000000000";
    public nvcLogin : string = null;
    public dtmData:string = null;
    public _dtmData:Date = new Date();
    public nvcOperacao : string = null;
    public nvcTabela : string = null;
    public nvcIdentificador : string = null;
    public nvcAlteracoes : string = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<SysLog>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): SysLog {
        let model = new SysLog();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): SysLog[] {
        if (objs && Array.isArray(objs)) return objs.map(SysLog.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<SysLog>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): SysLog { return SysLog.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `SysLog`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: SysLog) => m.id).displayName("id").required()
    .ensure((m: SysLog) => m.nvcSessao).displayName("nvcSessao").required()
    .ensure((m: SysLog) => m.nvcLogin).displayName("nvcLogin").required()
    .ensure((m: SysLog) => m.dtmData).displayName("dtmData").required()
    .ensure((m: SysLog) => m.nvcOperacao).displayName("nvcOperacao").required()
    .ensure((m: SysLog) => m.nvcTabela).displayName("nvcTabela").required()
    .ensure((m: SysLog) => m.nvcIdentificador).displayName("nvcIdentificador").required()
    .ensure((m: SysLog) => m.nvcAlteracoes).displayName("nvcAlteracoes").required()
  .on(SysLog);
