// Auto-gerado com typewriter
import { computedFrom } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { BaseViewModel } from "../BaseViewModel";
import { VmWrapper } from "../VmWrapper";
import { AssinaturaAssinante } from "./AssinaturaAssinante";
import { Assinante } from "../Assinante";

//export classe
export class OperacoesAssinatura extends BaseViewModel {
  public lstAssinaturas: AssinaturaAssinante[] = null;
  public oAssinante: Assinante = null;

  public constructor(fields?: Partial<OperacoesAssinatura>, ) {
    super();
    if (fields) Object.assign(this, fields);
  }

  get PodePagar(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.lstAssinaturas.every(obj => { return !obj.bitPago && !obj.bitFatura });
    return false;
  }

  get PodeLiquidar(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length == 1)
      return !this.lstAssinaturas[0].bitPago && this.lstAssinaturas[0].bitFatura;
    return false;
  }

  get PodePagarMB(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.oAssinante.nvcEntidadeMb != null && this.oAssinante.nvcEntidadeMb != '' && new Date(this.oAssinante.dtmExpiraMb) >= new Date() && this.PodePagar;
    return false;
  }

  get PodeCancelar(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length == 1)
      return !this.lstAssinaturas[0].bitCancelado && !this.lstAssinaturas[0].bitEntregue;
    return false;
  }

  get PodeAtivar(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length == 1)
      return this.lstAssinaturas[0].bitCancelado && !this.lstAssinaturas[0].bitEntregue;
    return false;
  }

  get PodeEditar(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length == 1)
      return true;
      //return !this.lstAssinaturas[0].bitEntregue;
    return false;
  }

  get TotalPagar(): number {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.lstAssinaturas.reduce(function (tot, el) {
        return tot + el.decValorDivida;
      }, 0);
    return 0;
  }

  get count(): number {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.lstAssinaturas.length;
    return 0;
  }

  get ids(): number[] {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.lstAssinaturas.map(obj => obj.id);
    return null;
  }

  get first(): AssinaturaAssinante {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.lstAssinaturas[0];
    return null;
  }

  get TemPagamentos(): boolean {
    if (this.lstAssinaturas && this.lstAssinaturas.length == 1)
      return this.lstAssinaturas[0].pagamentos && this.lstAssinaturas[0].pagamentos.length > 0
    return false;
  }

  get SelectedItemsLength(): number {
    if (this.lstAssinaturas && this.lstAssinaturas.length > 0)
      return this.lstAssinaturas.length;
    return 0;
  }
}
