import {autoinject, bindable, bindingMode} from "aurelia-framework";
import * as Cleave from 'cleave.js';

//import Cleave = require('cleave.js');

/**
 * Um CE que filtra o input para aceitar apenas números
 */
@autoinject()
export class MaskInput {
  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean           = true;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string             = "";
  @bindable({defaultBindingMode: bindingMode.oneTime}) className: string        = "form-control";
  @bindable({defaultBindingMode: bindingMode.oneTime}) placeholder: string      = "";
  @bindable({defaultBindingMode: bindingMode.oneTime}) config: MaskInputOptions = new MaskInputOptions();

  //elemento HTML
  private inputElement: HTMLInputElement;
  //estado interno
  private _innerValue: string = "";
  private instance: Cleave    = null;

  attached() {
    if (this.debug) console.log("[mask-input]", "attached", this, this.instance);
    var options = MaskInputOptions.createOptions(this.config);

    this.instance = new Cleave(this.inputElement, options,
      // onchange = () => {
      //   if(this.debug) console.log("[mask-input]", "cleave change", this);
      //   let currentValue = this.instance.getFormattedValue();
      //   if (currentValue != this.value) this.value = currentValue;
      // }
    );
    this.instance.setRawValue(this.value);
    this.changeHandler();
    if (this.debug) console.log("[mask-input]", "attached end", this, this.instance);
    //window['c'] = this.instance;
  }

  //o value é um array de strings com as keys das opções selecionadas muda em duas ocasiões
  //1. inicialização do plugin
  //2. escolha de um elemento
  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[mask-input]", "valueChanged", newValue, oldValue);
    //comparaçao do newValue com o formattedValue. Se for diferente o newValue sobrepoe-se ao valor com setRawValue
    if (this.instance) {
      if (newValue !== this.instance.getFormattedValue()) {
        this.instance.setRawValue(newValue);
      }
    }
  }

  changeHandler() {
    if (this.debug) console.log("[mask-input]", "changeHandler"
      , this.value
      , this.instance
    );

    let currentValue = this.config.numeric ? this.instance.getRawValue() : this.instance.getFormattedValue();
    if(this.debug) console.log("[mask-input]","currentValue", currentValue);
    if (currentValue !== this.value) this.value = currentValue;
  }
}

export class MaskInputOptions {
  //TIPOS DE MASK POSSIVEIS
  date: boolean  = false;
  time: boolean  = false;
  block: boolean = false;
  numeric: boolean = false;

  prefix: string       = null;
  delimiter: string    = null;
  delimiters: string[] = [];
  blocks: number[]     = [];
  uppercase: boolean   = null;
  pattern: string[]    = [];

  public constructor(fields?: Partial<MaskInputOptions>,) {
    if (fields) Object.assign(this, fields);
  }

  public static createOptions(config: MaskInputOptions) {
    var options = {};

    if (config.date) {
      options['date'] = true;
      if (config.pattern.length > 0) options['datePattern'] = config.pattern;

    } else if (config.numeric) {
      options['numeral'] = true;
    } else if (config.time) {
      options['time'] = true;
      if (config.pattern.length > 0) options['timePattern'] = config.pattern;

    } else if (config.block && config.blocks.length > 0) {
      options['blocks'] = config.blocks;
      if (config.uppercase != null) options['uppercase'] = config.uppercase;
      if (config.delimiter != null)
        options['delimiter'] = config.delimiter
      else if (config.delimiters.length > 0)
        options['delimiters'] = config.delimiters;
      if (config.prefix != null) options['prefix'] = config.prefix;
    }

    return options;
  }
}
