/**
 * Revisão on 2018-09-03.
 */



/** * * @param to Url parcial (deve começar com /, ex: "/dashboard/index.php) */
export function jsRedirect(to: string) {
  let target = to; if (to == null) {
    jsRefresh(); return;
  } else if (to.startsWith("http")) {
    target = to;
  } else if (to.startsWith("/")) {
    target = location.origin + to;
  } else if (to.startsWith("?")) {
    target = location.origin + location.pathname + to;
  }

  window.location.href = target;
}

/** */export function jsRefresh()
{
  window.location.href = window.location.origin + window.location.pathname + window.location.search;
}




//region strings
/**
 * Naive Camelize -> na realidade é um lcfirst
 * Passa uma string "NvcPropriedade" para a forma "nvcPropriedade"
 * @param str
 */
export const camelCase = (str) => str.charAt(0).toLowerCase() + str.slice(1);

/**
 * Separa uma string nas suas maíusculas num array de strings
 * @param str
 */
export const splitWords = (str) => str.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g);

/**
 * Passa uma string da forma "UmaStringPascalCase" para "uma-string-pascal-case"
 * @param str
 */
export const hiphenize = (str) => splitWords(str).map(camelCase).join("-");

// discuss at: http://locutus.io/php/lcfirst/
// original by: Brett Zamir (http://brett-zamir.me)
//   example 1: lcfirst('Kevin Van Zonneveld')
//   returns 1: 'kevin Van Zonneveld'
export function lcfirst(str) {
  str += '';
  let f = str.charAt(0).toLowerCase();
  return f + str.substr(1);
}

/**
 * Remove os caracteres de acentuação
 * @param str
 */
export const removeDiacritics = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
export const removeDiacriticsLower = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

/**
 * transforma uma string para outra amigável com as conveções de nomes de ficheiros
 * @param str
 */
export const filenameFriendly = (str) => removeDiacritics(str).replace(/[^a-z0-9\-]/gi, '_');

export const trimLongToSpan = (data: string, len = 150) => {
  //const len = 150;
  if (data && data.length > len) {
    return `<span title='${data}'>${data.substr(0, len)} ...</span>`
  }
  return data || "";
};

export const translateEfName = (str) => {
  const words = str
    .replace(/^(nvc|int|bit|dtm|flt)/gi, "")
    .replace(/^(id)/gi, "ref")
    .split(/(?=[A-Z])/).join(' ');
  return words.charAt(0).toUpperCase() + words.slice(1);
};
//endregion

//region Object
/**
 * Copia as propriedades de um objeto `source` para `target`.
 * Este método cria uma mutação no `target`
 * @param source
 * @param target
 * @param {string} skipPrefix
 */
export const copyProperties = (source: any, target: any, skipPrefix: string = "_") => {
  // console.log("asd");
  let keys = Object.keys(source);

  for (let prop of keys) {
    if (skipPrefix && prop.startsWith("_")) continue;
    target[prop] = source[prop];
  }

  return target;
};

/**
 * Diz se a propriedade é uma primitiva
 * https://stackoverflow.com/a/31538091
 * @param prop
 * @returns {boolean}
 */
export const isPrimitive = (prop: any) => prop !== Object(prop);
//endregion Object

//region IP
export let findIP = new Promise(r => {
  let w: any = window,
    a = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({ iceServers: [] }), b = () => { "" };
  a.createDataChannel("");
  a.createOffer(c => a.setLocalDescription(c, b, b), b);
  a.onicecandidate = c => { try { c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r) } catch (e) { "" } }
});

/*
export let findIP = (r) => {
  let w: any = window,
      a      = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({iceServers: []}),
      b      = () => {;};
  a.createDataChannel("");
  a.createOffer(c => a.setLocalDescription(c, b, b), b);
  a.onicecandidate = c => {try {c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r)} catch (e) {;}}
};
*/

/*Usage example*/
//findIP.then(ip => document.write('your ip: ', ip)).catch(e => console.error(e))
//endregion IP

