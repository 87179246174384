// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
//no imports

//export classe
export class SysLogin extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public nvcLogin : string = null;
    public intTentativas : number = 0;
    public bitLogin : boolean = false;
    public bitLogout : boolean = false;
    public dtmData:string = null;
    public _dtmData:Date = new Date();
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<SysLogin>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): SysLogin {
        let model = new SysLogin();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): SysLogin[] {
        if (objs && Array.isArray(objs)) return objs.map(SysLogin.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<SysLogin>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): SysLogin { return SysLogin.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `SysLogin`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: SysLogin) => m.id).displayName("id").required()
    .ensure((m: SysLogin) => m.nvcLogin).displayName("nvcLogin").required()
    .ensure((m: SysLogin) => m.intTentativas).displayName("intTentativas").required()
    .ensure((m: SysLogin) => m.bitLogin).displayName("bitLogin").required()
    .ensure((m: SysLogin) => m.bitLogout).displayName("bitLogout").required()
    .ensure((m: SysLogin) => m.dtmData).displayName("dtmData").required()
  .on(SysLogin);
