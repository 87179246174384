// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./../BaseViewModel";
import {VmWrapper} from "./../VmWrapper";
//export classe
export class RegistarDocumento extends BaseViewModel{
  public intCodEntidade: string = null;
  public strNumExterno: string = null;
  public strAbrevTpDoc: string = null;
  public strCodExercicio: string = null;
  public idAssinatura: number = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
  public constructor(fields?: Partial<RegistarDocumento>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

  public static fromPOJSO(obj: any): RegistarDocumento {
    let model = new RegistarDocumento();
        model.setState(obj);
        return model;
    }
    
  public static multipleFromPOJSO(objs: any | any[]): RegistarDocumento[] {
    if (objs && Array.isArray(objs)) return objs.map(RegistarDocumento.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
      return new VmWrapper<RegistarDocumento>({payload: this, confirmLevel: (+cl || 0)});
    }

  public cloneInstance(): RegistarDocumento { return RegistarDocumento.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
      return `CancelarAssinatura`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
  .ensure((m: RegistarDocumento) => m.strNumExterno).displayName("Número").required()
  .ensure((m: RegistarDocumento) => m.strAbrevTpDoc).displayName("Tipo Documento").required()
  .ensure((m: RegistarDocumento) => m.strCodExercicio).displayName("Ano").required()
  .on(RegistarDocumento);
