/**
 * Revisão on 2018-09-03.
 */
import moment = require("moment");

export function addFloat(a: number, b: number) {
  let a100 = Math.round(parseFloat("" + a) * 100);
  let b100 = Math.round(parseFloat("" + b) * 100);

  return (a100 + b100) / 100;
}

export function superRound(a: any) {
  return Math.round(parseFloat("" + a) * 100) / 100;
}

export function euroStringToFloat(str: string) {
  let s = str.replace("€", "").replace(".", "").replace(",", ".").trim();
  try {
    return Number.parseFloat(s);
  } catch (err) {
    return 0;
  }
}

/**
 *  gera um array de start(=) até end(<)
 https://stackoverflow.com/a/36963945
 */
export const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

//region Date
// export function isoStringToDate(str:string){
//   return realDate(new Date(str));
// }
//
// export function realDate(date:Date) {
//   date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
//   return date;
// }

export function dateISOString(date: Date = new Date()) {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.toISOString().substr(0,10);
}

export function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addUTCDays(date, days) {
  let m = moment(new Date(date));
  // let result = new Date(date);
  // result.setDate(result.getUTCDate() + days);
  return m.add(days, "day").startOf("day").toDate()
}

export function weekOf(date:Date){
  return moment(date).week();
}

export const datePt = (d:Date) => "Date";
export const strDatePt = (d: string) => "Date " + d;




/* JSR - Devolve o dia de hoje em dd/mm/aaaa*/
export function now() {
  var now = new Date();

  var options = { year: 'numeric', month: '2-digit', day: '2-digit' };

  return now.toLocaleDateString('pt-PT', options);
}
//endregion
