//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import { ComposeDialog, ComposeDialogOptions } from "../../dialogs/compose-dialog";
import { TabsListOptions } from "../../it-features/it-tabs/tabs-list";

@autoinject()
export class CaixaMovimentosLista {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  private strDataCaixa: string = "";
  public dicCaixas = [];
  public tabListConfig: TabsListOptions[] = [];
  constructor(public app: GlobalServices) { }

  activate() {
    return this.app.api.getProcessed("api/movimentoscaixa/dic-caixa")
      .then(lst => {
        this.dicCaixas = lst;
        this.tabListConfig = [];
        this.dicCaixas.forEach((el, idx) => {
          this.tabListConfig.push(new TabsListOptions({ titulo: el.text, tab: el.id }))
        });
      });
  }

  /**
   * doAction Loacl
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[movimentos-caixa-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case 'DETALHE': {
          window.location.href = "#/caixa/caixa-movimentos-detalhe?id=" + payload.id;
          break;
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[movimentos-caixa-listagem]", "RG_CELL_CHANGE", payload);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          window.location.href = "#/caixa/caixa-movimentos-detalhe?id=" + payload.rowRef.id;
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[movimentos-caixa-listagem]", "RG_ROW_CLICK", payload);
          this.isBusy = false;
          break;
        }

        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[movimentos-caixa-listagem]", "Acção DESCONHECIDA [movimentos-caixa-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
