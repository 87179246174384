import { DOM } from 'aurelia-framework';

/**
 * Este método serve para posicionar as dialogs ao centro do ecrã correctamente
 *
 * @param {HTMLElement} modalContainer
 */
export function centerDialog(modalContainer: HTMLElement) {
  const wrapper = <HTMLElement>modalContainer.children[0];
  recenter(wrapper);
  let observer = new MutationObserver(() => recenter(wrapper));
  observer.observe(modalContainer.querySelector("ux-dialog"), { attributes: true, childList: true });
}

function recenter(element: HTMLElement) {
  const vh = Math.max((<HTMLElement>DOM.querySelectorAll('html')[0]).clientHeight, window.innerHeight || 0);
  var margin = Math.max(((vh - element.offsetHeight) / 2) - 59, 30) + 'px';
  if (!element.style.marginTop && element.style.marginTop != margin)
    element.style.marginTop = Math.max(((vh - element.offsetHeight) / 2) - 59, 30) + 'px';
}
