import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";
import {BaseViewModel} from "../models/BaseViewModel";
import environment from "../environment";
import {GlobalServices, GlobalServicesContainer} from "../services/global-services";
import {confirmaActionTyped} from "../services/api-envelopes";

@autoinject()
export class ComposeDialog {
  protected controller: DialogController;
  protected validationController: ValidationController;

  protected app: GlobalServices;
  protected invoker: GlobalServicesContainer;

  /** modelo sob edição. na view (html) parcial deve ser usado como referência a fazer bind*/
  public modelo: BaseViewModel;
  public modeloOriginal: BaseViewModel;

  /** referência a um objeto menos profundo na árvore do estado. Se estiver definido é usado na submissão POST.
   *  O objetivo é o de atuar sobre uma parte mas gravar uma raiz.
   */
  protected containerModelo: BaseViewModel;

  protected options: ComposeDialogOptions = new ComposeDialogOptions();
  public isBusy: boolean               = true;

  constructor(controller: DialogController, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  //region Aurelia
  canActivate(p) {
    if (environment.debug) console.log("[compose-dialog]", "canActivate", p, this.controller);
    if (!p.modelo || !p.invoker) {
      console.error("Um dos parametros (modelo ou invoker) está em falta pelo que não se pode abir o popup.");
      return false;
    }
    this.invoker = p.invoker;
    this.app     = p.invoker.app;
    this.parseModelo(p.modelo);
    if (p.options) Object.assign(this.options, p.options);
    if (this.options && this.options.rootBindings) {
      let keys = Object.keys(this.options.rootBindings);

      for (let prop of keys) {
        this[prop] = this.options.rootBindings[prop];
      }
    }

    p.containerModelo && (this.containerModelo = p.containerModelo);
    this.isBusy = false;
    return true;
  }

  //apenas é verificado se a opção trackChanges estiver a true
  canDeactivate() {
    if (this.options.trackChanges && this.isChanged()) {
      return this.app.confirmaPopup(["Existem alterações por gravar.", "Deseja fechar o popup <b>SEM GRAVAR AS ALTERAÇÕES?</b>"])
        .then(r => {
          this.isBusy = false;

          return r;
        });
    }
    return true;
  }

  //endregion Aurelia

  //region actions
  private isChanged(): boolean {
    let strings = this.modelo.difference(this.modeloOriginal);
    if (environment.debug) console.log("[compose-dialog]", "canDeactivate", strings, "modelo base", this.modelo, "modelo cópia", this.modeloOriginal);
    return (strings.length > 0);
  }

  private parseModelo(m: BaseViewModel) {
    this.modelo         = m;
    this.modeloOriginal = m.cloneInstance();
  }

  private Ok(r: any) {
    this.controller.ok({
      containerModelo: this.containerModelo,
      modelo         : this.modelo,
      payload        : r,
    });
  }

  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[poc-folha-caixa-fecho-dialog]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {

        case 'OK': {
          if (this.options.doValidation) {
            return this.doAction("VALIDAR")
          } else {
            return this.doAction("GRAVAR");
          }
        }

        case 'CANCEL': {
          this.controller.cancel();
          return Promise.resolve(false);
        }

        case 'VALIDAR': {
          //valida todos os controlos no ecrã
          return this.validationController.validate()
            .then(r => {
              if (r.valid) { return this.doAction("GRAVAR");}
              else return this.isBusy = this.app.notificationErrorCompact("O formulário apresenta erros");
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err))
        }

        case 'GRAVAR': {
          // envia um POST para o endereco definido nas options
          // se o contentor estiver definido, e como as alterações provocam mutações, usa-se esse objeto em vez do modelo.
          if (environment.debug) console.log("[compose-dialog]", "GRAVAR", this.containerModelo, this.modelo);
          if (this.options.postUri) {
            if (this.options.messageBeforePost) this.app.notificationInfo(this.options.messageBeforePost);
            return confirmaActionTyped(this, this.containerModelo || this.modelo, this.options.postUri)
              .then(r => {
                if (environment.debug) console.log("[compose-dialog]", "GRAVAR - resposta do servidor", r);

                if (r) {
                  //this.modelo.setState(r);
                  this.options.trackChanges = false;
                  //this.modeloOriginal.copyState(this.modelo);
                  this.Ok(r);
                  // this.controller.ok({
                  //   containerModelo: this.containerModelo,
                  //   modelo: this.modelo,
                  //   payload: r,
                  // });
                  return true;
                }
                return false;
              });
          } else {
            this.Ok(null);
            //this.controller.ok(this.containerModelo || this.modelo);
            return true;
          }
        }

        //endregion
        default: {
          console.error("{doAction} DESCONHECIDA [poc-folha-caixa-fecho-dialog]", action, payload);
          this.app.notificationErrorCompact(["Acção DESCONHECIDA [poc-folha-caixa-fecho-dialog]", action]);
          this.isBusy = false;
        }
      }
    } catch (err) {
      console.error(err);
      this.app.notificationErrorCompact(err);
    }
  }

  //endregion actions
}

export class ComposeDialogOptions {
  /** deve ser executada a validação? */
  doValidation: boolean = true;
  trackChanges: boolean = true;

  /** deve ser executada a validação*/
  mainView: string = "./compose-test.html";

  /** deve ser executada a validação*/
  postUri: string = "";
  title: string   = "";

  withDefaultFooter: boolean = true;

  okLabel: string = "Gravar";
  okIcon: string  = "fa-save";

  /** root bindings são objectos importados para a raiz do compose dialogs*/
  rootBindings: any = {};

  messageBeforePost: string = null;

  public constructor(fields?: Partial<ComposeDialogOptions>,) {
    if (fields) Object.assign(this, fields);
  }
}
