import {bindable} from "aurelia-framework";
import {NavigationInstruction, RouteConfig, Router} from "aurelia-router";
import environment from "../../environment";

/**
 * A implementação realizada nesta class é uma adaptação da class it-navigation.
 */
export class ItMobileMenu {
  @bindable navigation: MobileNavStruct;
  @bindable breadcrumbs: MobileBreadcrumb[];

  attached() {
    // if (environment.debug) console.log("[mobile-menu]", "navigation", this.navigation);
    // if (environment.debug) console.log("[mobile-menu]", "breadcrumbs", this.breadcrumbs);
  }
}

export interface MobileMenuButton {
  title: string;
  icon: string;
  route: string;
}

export class MobileNavStruct {
  title: string                = "Menu";
  buttons: MobileMenuButton[] = [];
  href: string                = "#";
  parent: string              = "#";
  active: boolean             = false;
  level: number               = 0;

  public constructor(fields?: Partial<MobileNavStruct>,) {
    if (fields) Object.assign(this, fields);
  }

  public static translateRoute(route: RouteConfig): MobileMenuButton {
    return {
      title : route.settings.titulo,
      icon: route.settings.icon,
      route: route.href
    };
  }

  public static translateRoutes(routes: RouteConfig[]): MobileNavStruct {
    // if(environment.debug) console.log("[mobile-menu]","navModel",navModel);
    if (Array.isArray(routes) && routes.length > 0) {
      let navStruct     = new MobileNavStruct();
      navStruct.title = routes[0].title;
      navStruct.buttons = routes.filter(r => r.nav).map(r => MobileNavStruct.translateRoute(r));
      return navStruct;
    }
    return;
  }
}

export class MobileBreadcrumb {
  title: string = 'Menu';
  active: boolean = true;
  route: string = '#';
  level: number = 0;

  public constructor(fields?: Partial<MobileBreadcrumb>,) {
    if (fields) Object.assign(this, fields);
  }

  public static buildBreadcrumb(navigationInstruction: NavigationInstruction): MobileBreadcrumb {
    let title = 'Menu';
    return new MobileBreadcrumb({
      title: navigationInstruction.config.navModel.title,
      active: true,
      route: navigationInstruction.config.navModel.href,
      level: MobileBreadcrumb.getLevel(navigationInstruction.router)
    });
  }

  public static buildBreadcrumbs(navigationInstruction: NavigationInstruction, breadcrumbs: MobileBreadcrumb[] = []): MobileBreadcrumb[] {
    if(environment.debug) console.log("[mobile-menu]","navigationInstruction",navigationInstruction);
    if (navigationInstruction != null) {
      breadcrumbs.push(MobileBreadcrumb.buildBreadcrumb(navigationInstruction));
      if (!navigationInstruction.router.isRoot) {
        return MobileBreadcrumb.buildBreadcrumbs(navigationInstruction.parentInstruction, breadcrumbs);
      }
      else {
        //De forma a fornecer um link para o root, este é necessário ser adicionado manualmente. Isto só se verifica quando há mais que uma navegação registada
        if(breadcrumbs.length > 1)breadcrumbs.push(new MobileBreadcrumb());//Root
        breadcrumbs.reverse();//Dá se reverse ao array pois este é construido de forma ascendente (filhos para pais).
        breadcrumbs.splice(-1); //Retira-se o ultimo breadcrumb que aponta para a localização atual.
        if(breadcrumbs.length > 0) breadcrumbs[breadcrumbs.length-1].active = false;//Inativa-se o ultimo
        return breadcrumbs;//Por fim dá se reverse ao array pois este é construido de forma ascendente (filhos para pais)
      }
    }
    return [];
  }

  public static getLevel(router: Router, level: number = 0): number {
    if (router.isRoot) return level;
    else return MobileBreadcrumb.getLevel(router.parent, level + 1);
  }
}