// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
//no imports

//export classe
export class FechoEdicao extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public intEdicao : number = 0;
    public dtmEdicao:string = null;
    public _dtmEdicao:Date = new Date();
    public bitCartasEmitidas : boolean = false;
    public intTotalAssinantes : number = 0;
    public intCartasRenovacao : number = 0;
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<FechoEdicao>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): FechoEdicao {
        let model = new FechoEdicao();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): FechoEdicao[] {
        if (objs && Array.isArray(objs)) return objs.map(FechoEdicao.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<FechoEdicao>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): FechoEdicao { return FechoEdicao.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `FechoEdicao`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: FechoEdicao) => m.id).displayName("id").required()
    .ensure((m: FechoEdicao) => m.intEdicao).displayName("intEdicao").required()
    .ensure((m: FechoEdicao) => m.dtmEdicao).displayName("dtmEdicao").required()
    .ensure((m: FechoEdicao) => m.bitCartasEmitidas).displayName("bitCartasEmitidas").required()
    .ensure((m: FechoEdicao) => m.intTotalAssinantes).displayName("intTotalAssinantes").required()
    .ensure((m: FechoEdicao) => m.intCartasRenovacao).displayName("intCartasRenovacao").required()
    .ensure((m: FechoEdicao) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: FechoEdicao) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: FechoEdicao) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: FechoEdicao) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: FechoEdicao) => m.timestamp).displayName("timestamp").required()
  .on(FechoEdicao);
