/**
 * Created by herna on 5/3/2017.
 */
import {autoinject, LogManager} from "aurelia-framework";
import {Logger} from "aurelia-logging";
import {HttpClient} from "aurelia-fetch-client";
import {EventAggregator} from "aurelia-event-aggregator";
import environment from "../environment";
import {AuthService} from "./auth-service";

@autoinject()
export class Api {
  protected logger: Logger;
  public isRequesting: boolean = false;
  private authService;
  private httpClient: HttpClient;
  private debug:boolean = false;
  // private ea: EventAggregator;

  protected jsonHeaders = {
    'Accept'          : 'application/json',
    'Content-Type'    : 'application/json',
    'X-Requested-With': 'Fetch'
  };

  constructor(authService: AuthService) {
    this.isRequesting = true;
    this.logger       = LogManager.getLogger('API');
    // this.logger.id = 'API';

    let ep = environment.endpoint;
    if(!ep) {
      console.error("O access point no environment.ts não está configurado.");
    }
    if(!ep.endsWith("/")) {
      console.error("é esperado que o endpoint contenha uma barra (/) no final");
      ep = ep + "/" ;
    }

    if(environment.debug) console.log("[api]", `API object construct for ${ep}`);
    this.authService = authService;
    // this.ea           = ea;
    //configura o cliente
    this.httpClient   = new HttpClient().configure(config => {
      config
        .withBaseUrl(ep)
        // .withInterceptor({
        //   request(request) {
        //     let token = authService.pomisedActiveToken();
        //
        //     // authService.pomisedActiveToken()
        //     //   .then(tok => {
        //     //   if(tok)
        //     // //   console.log(`request intercepted`, request, authHeader);
        //     // //   request.headers.append('Authorization', "Bearer " + authHeader);
        //     //   return request;
        //     // }).catch(err => {
        //     //
        //     // })
        //     return request;
        //   }
        // })
        .withDefaults({
          credentials: 'include'
        })
      ;
    });
    this.isRequesting = false;
  }

  get(route: string, payload: any = null): Promise<Response> {
    this.logger.debug("API - get", route, payload);
    this.isRequesting = true;
    let newRoute      = route;
    if (payload) {
      newRoute += "?" + Object.keys(payload).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(payload[k])}`).join('&');
    }
    return this.authService.promisedActiveToken()
      .then(tok => {
        let headers: any = this.jsonHeaders;

        if (tok) headers.Authorization = "Bearer " + tok;
        else {
          this.isRequesting = false;
          this.logger.error("Não foi possível comunicar com o servidor: não existe um login válido");
          return Promise.reject("Não foi possível comunicar com o servidor: não existe um login válido");
          // throw new Error("Não existe um login válido");
        }
        return this.httpClient
          .fetch(newRoute, {method: "get", headers: headers})
          .then((r: Response) => {
            this.isRequesting = false;
            return r;
          });
      });
  }

  getProcessed(route: string, payload: any = null) {
    return this.get(route, payload)
      .then(r => this.processResponse(r))
  }

  /**
   * @param route
   * @param payload
   * @return {Promise<string>}
   */
  post(route: string, payload: any = null): Promise<Response> {
    this.logger.debug("API - post", route, payload);
    this.isRequesting = true;
    return this.authService.promisedActiveToken()
      .then(tok => {
        let headers: any = this.jsonHeaders;

        if (tok) headers.Authorization = "Bearer " + tok;
        else {
          this.isRequesting = false;
          this.logger.error("Não foi possível comunicar com o servidor: não existe um login válido");
          // throw new Error("Não existe um login válido");
          return Promise.reject("Não foi possível comunicar com o servidor: não existe um login válido");
        }
        return this.httpClient
          .fetch(route, {method: "post", headers: headers, body: JSON.stringify(payload)})
          .then((r: Response) => {
            this.isRequesting = false;
            return r;
          });
      });
  }

  postProcessed(route: string, payload: any = null) {
    return this.post(route, payload)
      .then(r => this.processResponse(r))
  }

  postProcessedT(route: string, payload: any = null, signal: boolean = true) {
    return this.post(route, payload)
      .then(r => this.processResponse(r))
      .then(r => this.processTypedResponse(r))
  }

  /**
   * @param route
   * @param params
   * @param payload
   * @return {Promise<string>}
   */
  deleteVerb(route: string, params: any = null, payload: any = null): Promise<Response> {
    this.logger.debug("API - delete", route, payload);
    this.isRequesting = true;
    let newRoute      = route;
    if (params) { newRoute += "?" + Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');}
    return this.authService.promisedActiveToken()
      .then(tok => {
        let headers: any = this.jsonHeaders;

        if (tok) headers.Authorization = "Bearer " + tok;
        else {
          this.isRequesting = false;
          this.logger.error("[DeleteVerb] Não foi possível comunicar com o servidor: não existe um login válido");
          // throw new Error("Não existe um login válido");
          return Promise.reject("[DeleteVerb] Não foi possível comunicar com o servidor: não existe um login válido");
        }
        return this.httpClient
          .fetch(newRoute, {method: "delete", headers: headers, body: JSON.stringify(payload)})
          .then((r: Response) => {
            this.isRequesting = false;
            return r;
          });
      });
  }

  deleteProcessed(route: string, params: any = null, payload: any = null) {
    return this.deleteVerb(route, params, payload)
      .then(r => this.processResponse(r))
  }

  deleteProcessedT(route: string, params: any = null, payload: any = null) {
    return this.deleteVerb(route, params, payload)
      .then(r => this.processResponse(r))
      .then(r => this.processTypedResponse(r))
  }

  /**
   *
   * @param route
   * @param params
   * @param payload
   * @return {Promise<string>}
   */
  postArrayBuffer(route: string, payload: any = null): Promise<Response> {
    this.logger.debug("API - post", route, payload);
    this.isRequesting = true;
    return this.authService.promisedActiveToken()
      .then(tok => {
        let headers: any = this.jsonHeaders;

        if (tok) headers.Authorization = "Bearer " + tok;
        else {
          this.isRequesting = false;
          this.logger.error("Não foi possível comunicar com o servidor: não existe um login válido");
          // throw new Error("Não existe um login válido");
          return Promise.reject("Não foi possível comunicar com o servidor: não existe um login válido");
        }
        return this.httpClient
          .fetch(route, {method: "post", headers: headers, body: JSON.stringify(payload)})
          .then((r: Response) => {
            this.isRequesting = false;
            return r;
          });
      });
  }

  /**
   *
   * @param r
   * @return {Promise<never>}
   */
  errorProcess(r: Response) {
    //console.log("Api::errorProcess", r, "this", this);
    this.logger.error("Aconteceu um erro na resposta do servidor: ", r);
    if (r) {
      // if (r.status == 400) {
      //   // this.ea.publish('notification:error', "Aconteceu um erro 400");
      //   // r.json()
      //   //   .then(r => {
      //   //     //analisar a resposta
      //   //     for (let prop in r) {
      //   //       if (r.hasOwnProperty(prop)) {
      //   //         let errors = r[prop].reduce((acc, el) => acc + el, "");
      //   //         r[prop].forEach(el => this.validationController.addError(el, this.planoExpedicao, _s.camelCase(prop)));
      //   //         this.app.notificationError(r[prop]);
      //   //       }
      //   //     }
      //   //     //throw "erro";
      //   //   });
      //   return Promise.reject(new Error("Aconteceu um erro 400"));
      //   // return r.json();
      // }
      // else
      if (r.status == 401) {return Promise.reject(new Error("Não Autorizado - O utilizador não dispõe das credenciais válidas para aceder ao recurso."));}
      else if (r.status == 403) {return Promise.reject(new Error("Acesso interdito - O recurso que se tenta aceder no servidor não está acessível para o utilizador atual."));}
      else if (r.status == 404) {return Promise.reject(new Error("Erro 404 - O recurso que se tenta aceder no servidor não existe ou não está acessível para o utilizador atual."));}
      else {
        return Promise.reject(new Error("Aconteceu um erro na resposta do servidor sem código determinado"));
      }
    }
    return Promise.reject(new Error("Aconteceu um erro por exaustão, sem código de erro conhecido."));
  }

  /**
   *
   * @param r
   * @return {any}
   */
  processResponse(r: Response) {
    //console.log("Api::processResponse", r, "this", this);
    if (r) {
      if (r.status >= 200 && r.status <= 299) {
        return r.json();
      }
      if (r.status == 400) {
        return r.json().then(Promise.reject)
      }
    }
    // return Promise.resolve(r);
    return this.errorProcess(r);
  }

  /**
   * O acesso apenas ao validation controller não ajuda...
   * Em princípio a única coisa que este método pode tratar são os redirects
   * @param r
   * @return {any}
   */
  processTypedResponse(r: any) {
    if (this.debug) console.log("[API]", "processTypedResponse", r);
    switch (r.type) {
      case "ok":
        return r.payload;
      // case "error": {
      //   //existe um validation controller?
      //   if (vc) {
      //     console.log("add validation objs", r);
      //     // vc.addError("nvcPassword", vc.)
      //     //vc.addObject()
      //   }
      //   return Promise.reject("Erro de servidor");
      // }
      case "error":
      case "confirm":
      default:
    }
    return r;
  }

  /**
   *
   * @param r
   * @return {any}
   */
  processBlobResponse(r: Response) {
    //console.log("Api::processResponse", r, "this", this);
    if (r) {
      if (r.status >= 200 && r.status <= 299) {
        return r.blob();
      }
      if (r.status == 400) {
        return r.json().then(Promise.reject)
      }
    }
    // return Promise.resolve(r);
    return this.errorProcess(r);
  }

  processBlobDownload(blob, filename: string = "ficheiro") {
    let URL         = window.URL || (window as any).webkitURL;
    let downloadUrl = URL.createObjectURL(blob);
    let a           = document.createElement("a");
    // safari doesn't support theese yet
    a.href          = downloadUrl;
    a.download      = filename;
    document.body.appendChild(a);
    a.click();
    //this.app.notificationSuccess("Ficheiro transferido para a pasta dos downloads com o nome: <strong>" + a.download + "</strong>");
    setTimeout(function () {
      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    }, 200);
  }

  processBlobPreview(blob, filename: string = "ficheiro", print = true) {
    let URL         = window.URL || (window as any).webkitURL;
    let downloadUrl = URL.createObjectURL(blob);

    let printPage = window.open(downloadUrl, "Report " + filename);
    if (printPage) {
      setTimeout(() => {
        if(print) (printPage as any).print();
        URL.revokeObjectURL(downloadUrl);
      }, 5);
    } else{
      URL.revokeObjectURL(downloadUrl);
      throw new Error("Não foi possível abrir uma nova aba. Os popups do <i>browser</i> estão bloqueados?");
    }
  }

  processBase64PDFPreview(base64, filename: string = "ficheiro.pdf", print: boolean = false) {
    let a = document.createElement("a");
    var objbuilder = '';
    objbuilder += ('<object width="100%" height="100%" data= "data:application/pdf;base64,');
    objbuilder += (base64);
    objbuilder += ('" type="application/pdf" class="internal">');
    objbuilder += ('<embed src="data:application/pdf;base64,');
    objbuilder += (base64);
    objbuilder += ('" type="application/pdf"  />');
    objbuilder += ('</object>');

    var win = window.open(filename, "_blank");
    var title = filename;
    win.document.write('<html><title>' + title + '</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px; ">');
    win.document.write(objbuilder);
    win.document.write('</body></html>');

    if (print) {
      setTimeout(function () {
        win.focus();
        win.print();
      }, 4000);
    }
  }

  processByteToPDFBlob(bytes) {
    var byteCharacters = atob(bytes);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: 'application/pdf' });
    return blob;
  }
}
