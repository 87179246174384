import {autoinject, Aurelia} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import { RemoteGrid } from "../../it-features/remote-grid/remote-grid";
import { ActionFromRemoteGrid, RemoteGridActions } from "../../it-features/remote-grid/interfaces";
import moment = require("moment");

//Este controlador representa o verdadeiro entrypoint da aplicação
@autoinject()
export class Index {
  private isBusy: boolean = false;
  private gridDividas: RemoteGrid;
  private gridPagamentos: RemoteGrid;
  private gridRenovacoes: RemoteGrid;
  private gridDesactivados: RemoteGrid;
  private gridCreditos: RemoteGrid;

  constructor(private app: GlobalServices) {
    
  }

  canActivate() {
    return this.app.api.getProcessed('api/jwt/is-auth')
      .then(response => {
        if (response === false) {
          this.app.auth.logout();
        }
        else
          return response;
      })
  }



  /**
   * doAction Local
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[titulos-lista]", "{doAction}", action, payload);
    try {
      switch (action) {

        case 'DETALHE': {
          this.isBusy = true;
          this.app.router.navigate("#/assinantes/assinantes-detalhe?id=" + payload.idAssinante, { replace: true });
          return;
        }

        case "IMPRIMIR-DEBITOS": {
          return this.app.confirmaPopup(["PRETENDE IMPRIMIR " + this.gridDividas.totalFiltrados + " NOTAS DE DÉBITO?"])
            .then(response => {
              if (response === true) {
                this.app.notificationInfo("A imprimir " + this.gridDividas.totalFiltrados + " notas de débito. Este processo poderá demorar vários minutos!");
                var py = this.gridDividas.serverPayload();
                this.isBusy = true;
                this.app.api.postProcessed('api/assinanteassinatura/imprime-debitos-assinantes-filtrado', py)
                  .then(response => {
                    Array.from(response['files']).forEach(file => {
                      let linkSource = `data:${file['contentType']};base64,${file['fileContents']}`;
                      let downloadLink = document.createElement("a");
                      let fileName = file['fileDownloadName'];
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    });
                    return this.isBusy = false;
                  });
              } else {
                return this.isBusy = false;
              }
            })
            .catch(err => { this.isBusy = false; this.app.notificationErrorCompact(err) })
        }

        case "SYNC-MB": {
          return this.app.confirmaPopup(["PRETENDE SINCRONIZAR OS PAGAMENTOS MULTIBANCO?"])
            .then(response => {
              if (response === true) {
                this.isBusy = true;
                this.app.api.postProcessed('api/wallet/sync-pagamentos')
                  .then(response => {
                    this.app.notificationSuccess("Pagamentos sincronizados!");
                    //this.lstResultados = response;
                    return this.isBusy = false;
                  });
              } else {
                return this.isBusy = false;
              }
            })
            .catch(err => { this.isBusy = false; this.app.notificationErrorCompact(err) })
        }



      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }

  //todo: configurar subRouter
  logout(){
    if(environment.debug) console.log("[index]","LOGOUT");
    this.app.auth.logout();
  }
}
