import {autoinject, computedFrom} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";

@autoinject()
export class ConfirmacaoDialog {
  protected mensagem: string    = "Evento a confirmar";
  protected mensagens: string[] = [];
  protected confirmBtn: HTMLButtonElement;

  constructor(private controller: DialogController) {
    // this.controller = controller;
    //console.log("lodash test", _s.camelCase("uma-string-que-vai passar a camel case"));
    this.controller.settings.centerHorizontalOnly = true;
    this.controller.settings.overlayDismiss       = true;
  }

  //canDeactivate() {}

  activate(msg: string | any) {
    //if(msg)
    if (typeof msg === 'string') {
      // this is a string
      this.mensagem = msg;
    } else {
      this.mensagem  = null;
      this.mensagens = msg;
    }
  }

  attached() {
    this.confirmBtn.focus();
  }
}
