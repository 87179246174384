//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import { App } from "../../app";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import { ComposeDialog, ComposeDialogOptions } from "../../dialogs/compose-dialog";
import { MovimentosCaixa } from "../../models/MovimentosCaixa";

@autoinject()
export class CaixaMovimentosDetalhe {
  public app: GlobalServices;
  public isBusy: boolean = false;
  public isDirty: boolean = false;
  public id: string = "";
  public modelo: MovimentosCaixa = null;
  public total: number = 0;
  private rg: RemoteGrid;
  public gridPagamentosUrl = 'PagamentosMovimentosCaixa?p1=';
  public gridTotaisMeioPagamentoUrl = 'MovimentosCaixaMeioPagamento?p1=';
  public gridTotaisMeioPagamento: RemoteGrid;

  private isCaixaAberta: boolean = false;
  private modelCaixa: any = null;

  constructor(g: GlobalServices, protected parent: App) {
    this.app = g;
  }

  canActivate(p, rc) {
    this.id = p.id;
    this.gridPagamentosUrl += this.id.toString();
    this.gridTotaisMeioPagamentoUrl += this.id.toString();
    return this.fetchModelo()
      .then(obj => this.parseModelo(obj))
      .then(x => this.fetchTotal())
      .catch(err => this.app.notificationErrorCompact(err));
  }


  /**
 * Obtém o modelo da API
 * @return {Promise<any>}
 */
  fetchModelo() {
    if (this.id)
      return this.app.api.getProcessed(`api/movimentoscaixa`, { id: this.id });
    throw new Error("O id do modelo não foi especificado");
  }

  /**
 * Instancia o modelo e o modeloOriginal
 * @param payload
 * @return {boolean}
 */
  parseModelo(payload: any) {
    this.modelo = MovimentosCaixa.fromPOJSO(payload);
    this.isDirty = false;
    return true;
  }

  fetchTotal() {
    return this.app.api.getProcessed("api/movimentoscaixa/total", { id: this.id }).then(valor => {
      this.total = valor.toFixed(2);
    });
  }

  /**
   * doAction Loacl
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[movimentos-caixa-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {

        case 'FECHAR-CAIXA': {
          return this.app.confirmaPopup(["Pretende FECHAR a caixa?"])
            .then(response => {
              if (response == true) {
                return this.app.api.postProcessed('api/movimentoscaixa/fechar', this.modelo.id)
                  .then(result => {
                    if (result === true) {
                      this.rg.refreshPage();
                      this.fetchModelo().then(obj => this.parseModelo(obj))
                      return this.isBusy = false;
                    } else {
                      this.isBusy = false;
                      return true;
                    }
                  });
              } else {
                return this.isBusy = false;
              }
            })
            .catch(err => this.app.notificationErrorCompact(err))

        }

        case 'REFRESH': {
          return location.reload();
        }

        case 'LIST': {
          return this.app.router.navigate("#/caixa/caixa-movimentos-lista", { replace: true });
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[movimentos-caixa-listagem]", "RG_CELL_CHANGE", payload);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          if (environment.debug) console.log("[movimentos-caixa-listagem]", "RG_ROW_CLICK", payload);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[movimentos-caixa-listagem]", "RG_ROW_CLICK", payload);
          this.isBusy = false;
          break;
        }

        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[movimentos-caixa-listagem]", "Acção DESCONHECIDA [logs-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
