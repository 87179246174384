import {Aurelia} from 'aurelia-framework'
import environment from './environment';
import * as moment from "moment";
import {setupValidationRules} from "./validation/custom-rules";

//Configure Bluebird Promises.
if (environment.debug)
  (<any>Promise).config({warnings: {wForgottenReturn: false}});
else
  (<any>Promise).config({warnings: false});

//Configure Moment
moment.locale("pt");

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin('aurelia-validation')
    .plugin('aurelia-dialog', config => {
      config.useDefaults();
      config.settings.lock                 = true;
      config.settings.startingZIndex       = 200;
      config.settings.keyboard             = [/*'Enter', */'Escape'];
      config.settings.centerHorizontalOnly = true;
      config.useCSS("");
      //config.settings.rejectOnCancel = true;
    })
    .feature('resources')
    .feature('it-features')
    .feature('it-v-grid');

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  if (environment.testing) {
    aurelia.use.plugin('aurelia-testing');
  }

  if(environment.debug) console.log("[main]","Setting isMobile to true.");
  window && (window["isMobile"] = false);

  setupValidationRules();

  return aurelia.start().then(() => aurelia.setRoot('mobile-app'));
}
