//import {setInterval} from "timers";
import { autoinject } from "aurelia-framework";
import { activationStrategy, Router } from 'aurelia-router';
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { GlobalServices } from "../../services/global-services";
import { BootstrapFormRenderer } from "../../services/bootstrap-form-renderer";
import environment from "../../environment";
import { Assinante } from "../../models/Assinante";
import { Morada } from "../../models/Morada";
import { MoradaTipo } from "../../models/MoradaTipo";
import { Pagamento } from "../../models/Pagamento";
import { AssinanteAssinatura } from "../../models/AssinanteAssinatura";
import { CancelarAssinatura } from "../../models/view-models/CancelarAssinatura";
import { AssinaturaAssinante } from "../../models/view-models/AssinaturaAssinante";
import { App } from "../../app";
import { confirmaActionTyped } from "../../services/api-envelopes";
import { dateISOString, now } from "../../utils/ItNumeric";
import { centerDialog } from "../../utils/itDialogs";
import { ComposeDialog, ComposeDialogOptions } from "../../dialogs/compose-dialog";
import { TabsListOptions } from "../../it-features/it-tabs/tabs-list"
import { RemoteGrid } from "../../it-features/remote-grid/remote-grid";
import { OperacoesAssinatura } from "../../models/view-models/OperacoesAssinatura";
import { RegistarDocumento } from "../../models/view-models/RegistarDocumento";
import { AnularDocumento } from "../../models/view-models/AnularDocumento";

@autoinject()
export class AssinantesEditar {
  public app: GlobalServices;
  public isBusy: boolean = false;
  public isDirty: boolean = false;
  public id: string = "";
  public modelo: Assinante = null;
  public modeloOriginal: Assinante = null;
  public proximocodigo = 0;
  public divida: number = null;
  public strDivida: string = null;
  public caixaAberta: boolean = false;
  //Vars para acções sobr as assinaturas
  public operacaoAssinatura: OperacoesAssinatura = null;
  public bitPodePagar: boolean = false;
  public bitValidoParaPagamentoMB: boolean = false;
  public bitPodeCancelar: boolean = false;
  public bitPodeEditar: boolean = false;
  public decTotalPagar: string = null;

  //Config Tabs
  public tabListConfig: TabsListOptions[] = [
    new TabsListOptions({ titulo: "Dados Pessoais", tab: "nav-dados" }),
    new TabsListOptions({ titulo: "Assinaturas", tab: "nav-edicoes", visible: false })
    //,new TabsListOptions({ titulo: "Pagamentos", tab: "nav-pagamentos", visible: false })
  ];

  //Apenas existem quando é uma nova entidade
  public modeloMoradaEnvio: Morada = null;
  public modeloMoradaFaturacao: Morada = null;

  //dictionaries
  public dicAssinaturas: any = [];
  public dicTitulos: any = [];
  public dicMeiosPagamento: any = [];
  public dicCondicoesPagamento: any = [];
  public dicTiposAssinante: any = [];
  public dicPaises: any = [];
  public dicMoradaTipos: any = [];

  //Validation
  private validationController: ValidationController;

  //Grids
  public gridMoradasUrl = 'MoradasAssinante?p1=';
  private gridMoradas: RemoteGrid;
  public gridAssinaturasUrl = 'AssinanteAssinatura?p1=';
  private gridAssinaturas: RemoteGrid;
  //public gridEdicoesUrl = 'EdicoesAssinante?p1=';
  //private gridEdicoes: RemoteGrid;
  public gridPagamentosUrl = 'PagamentosAssinante?p1=';
  private gridPagamentos: RemoteGrid;

  //region Aurelia
  constructor(g: GlobalServices, vcf: ValidationControllerFactory, protected parent: App) {
    this.app = g;
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  canActivate(p, rc) {
    if (environment.debug) console.log("[assinantes-editar]", "canActivate", p, rc);

    return this.app.api.getProcessed("api/assinatura/dic").then(x => { this.dicAssinaturas = x; })
      .then(x => this.app.api.getProcessed("api/titulo/dic").then(x => { this.dicTitulos = x; }))
      .then(x => this.app.api.getProcessed("api/meiopagamento/dic/erp").then(x => { this.dicMeiosPagamento = x; }))
      .then(x => this.app.api.getProcessed("api/condicoespagamento/dic").then(x => { this.dicCondicoesPagamento = x; }))
      .then(x => this.app.api.getProcessed("api/assinantetipo/dic").then(x => { this.dicTiposAssinante = x; }))
      .then(x => this.app.api.getProcessed("api/pais/dic").then(x => { this.dicPaises = x; }))
      .then(x => this.app.api.getProcessed("api/moradatipo/dic").then(x => { this.dicMoradaTipos = x; }))
      .then(x => this.app.api.getProcessed("api/assinante/proximocodigo").then(x => { this.proximocodigo = x; }))
      .then(x => this.fetchCaixaAberta())
      .then(x => {
        //Id
        this.id = p.id;
        //Grid Url
        this.gridMoradasUrl += this.id;
        this.gridAssinaturasUrl += this.id;
        //this.gridEdicoesUrl += this.id;
        this.gridPagamentosUrl += this.id;
        //Configura tabs
        this.tabListConfig[1].visible = !!this.id;
        //this.tabListConfig[2].visible = !!this.id;
        //Vai buscar o modelo
        if (!this.id) {
          this.modelo = new Assinante({ intNumero: this.proximocodigo });
          this.modeloOriginal = new Assinante({ intNumero: this.proximocodigo });

          this.modeloMoradaEnvio = new Morada({ bitPredefinida: true, nvcTipo: "ENVIO", nvcTipoNavigation: new MoradaTipo({ nvcDescricao: "Morada de envio" }) });
          this.modeloMoradaFaturacao = new Morada({ bitPredefinida: true, nvcTipo: "FATUR", nvcTipoNavigation: new MoradaTipo({ nvcDescricao: "Morada de faturação" }) });
          this.modelo.morada = [this.modeloMoradaEnvio, this.modeloMoradaFaturacao];
          return true;
        } else {
          return this.fetchModelo()
            .then(obj => this.parseModelo(obj))
            .then(x => this.fetchValorEmDivida())
            .catch(err => this.app.notificationErrorCompact(err))
        }
      })
      .then(x => {
        return this.fetchModeloMoradaEnvio()
          .then(obj => { if (obj) this.modeloMoradaEnvio = this.parseMorada(obj); })
      })
      .then(x => {
        if (!this.modelo.nvcEntidadeFaturacaoErp)
          return this.fetchModeloMoradaFaturacao()
            .then(obj => { if (obj) this.modeloMoradaFaturacao = this.parseMorada(obj); })
        else
          return this.doAction("ENTIDADE-ERP-CHANGED");
      });
  }

  attached() {
  }

  canDeactivate() {
    let strings = this.modelo.difference(this.modeloOriginal);
    if (environment.debug) console.log("[assinantes-editar]", "canDeactivate", strings);
    if (strings.length > 0) {
      return this.app.confirmaPopup(["Existem alterações por gravar", "Deseja sair <b>SEM EFETUAR A GRAVAÇÃO?</b>"]);
    }
    return true;
  }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  //endregion

  //region directActions

  /**
   * Obtém o modelo da API
   * @return {Promise<any>}
   */
  fetchModelo() {
    if (this.id)
      return this.app.api.getProcessed(`api/assinante`, { id: this.id });
    throw new Error("O id do modelo não foi especificado");
  }

  /**
   * Instancia o modelo e o modeloOriginal
   * @param payload
   * @return {boolean}
   */
  parseModelo(payload: any) {
    this.modelo = Assinante.fromPOJSO(payload);
    this.modeloOriginal = Assinante.fromPOJSO(payload);
    this.isDirty = false;
    return true;
  }

  /**
 * Instancia uma morada
 * @param payload
 */
  parseMorada(payload: any) {
    return payload ? Morada.fromPOJSO(payload) : null;
  }

  /**
  * Obtém o modelo da Morada de envio predefinida
  * @return {Promise<any>}
  */
  fetchModeloMoradaEnvio() {
    return this.app.api.getProcessed("api/morada/envio", { id: this.id });
  }
  /**
  * Obtém o modelo da Morada de faturação predefinida
  * @return {Promise<any>}
  */
  fetchModeloMoradaFaturacao() {
    return this.app.api.getProcessed("api/morada/faturacao", { id: this.id });
  }
  /**
  * Obtém o modelo da Morada de faturação predefinida pela entidade ERP
  * @return {Promise<any>}
  */
  fetchModeloMoradaFaturacaoERP() {
    return this.app.api.getProcessed("api/morada/faturacao-eticadata", { id: this.modelo.nvcEntidadeFaturacaoErp });
  }
  /**
* Obtém o modelo da Morada pelo id
* @return {Promise<any>}
*/
  fetchMorada(obj: any) {
    return obj ? this.app.api.getProcessed("api/morada", { id: obj.id }) : Promise.resolve(new Morada({ idAssinante: parseInt(this.id) }));
  }

  /**
 * Obtém o valor em divida do Assinante
 * @return {Promise<any>}
 */
  fetchValorEmDivida() {
    if (this.id)
      return this.app.api.getProcessed(`api/assinanteassinatura/divida`, { id: this.id }).then(v => {
        if (v > 0) {
          this.divida = parseFloat(v.toFixed(2));
          this.strDivida = this.divida.toString();
        } else {
          this.divida = null;
          this.strDivida = null;
        }
      });
    throw new Error("O id do modelo não foi especificado");
  }

  /**
* Verifica se existe uma caixa aberta
* @return {Promise<any>}
*/
  fetchCaixaAberta() {
    return this.app.api.getProcessed("api/movimentoscaixa/activo").then(x => {
      this.caixaAberta = (x != false);
    });
  }

  /**
* Faz refresh às tabelas
* @return {Promise<any>}
*/
  refreshTabelas() {
    if (this.gridAssinaturas) this.gridAssinaturas.refreshPage();
    if (this.gridPagamentos) this.gridPagamentos.refreshPage();
  }


  //endregion

  /**
   * doAction Local
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[assinantes-editar]", "doAction", action, payload);
    try {
      switch (action) {
        case 'REFRESH': {
          this.isBusy = true;
          return location.reload();
        }

        case 'NEW': {
          this.isBusy = true;
          return this.app.router.navigate("#/assinantes/assinantes-detalhe", { replace: true });
        }

        case 'LIST': {
          return this.app.router.navigate("#/assinantes/assinantes-lista", { replace: true });
        }


        // case 'REFRESH':
        case 'VALIDATE': {
          return this.validationController.validate()
            .then(vr => {
              if (vr.valid === true) { return this.doAction("SAVE") }
              else {
                this.app.notificationWarning("O formulário apresenta erros.");
                return this.isBusy = false;
              }
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err));
        }

        case 'SAVE': {
          this.isBusy = true;
          var isNew = (!this.id);
          return confirmaActionTyped(this, this.modelo, 'api/assinante')
            .then(response => {
              if (environment.debug) console.log("[assinantes-editar]", "Resposta do POST", response);
              if (response === false)
                return this.isBusy = false;
              else
                return response;
            })
            .then(response => {
              this.app.notificationSuccess("Dados guardados!");
              this.parseModelo(response.modelo);
              this.id = this.modelo.id.toString();
              if (isNew)
                this.doAction("RENOVAR-ASSINATURA", true);

              return this.isBusy = false;
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err));
        }

        case 'COPY-MORADA': {
          this.modeloMoradaFaturacao.nvcCodPostal = this.modeloMoradaEnvio.nvcCodPostal;
          this.modeloMoradaFaturacao.nvcLocalidade = this.modeloMoradaEnvio.nvcLocalidade;
          this.modeloMoradaFaturacao.nvcMorada = this.modeloMoradaEnvio.nvcMorada;
          this.modeloMoradaFaturacao.nvcPais = this.modeloMoradaEnvio.nvcPais;
          this.modeloMoradaFaturacao.bitProvisoria = this.modeloMoradaEnvio.bitProvisoria;
          this.modeloMoradaFaturacao.dtmInicio = this.modeloMoradaEnvio.dtmInicio;
          this.modeloMoradaFaturacao.dtmFim = this.modeloMoradaEnvio.dtmFim;
          break;
        }

        case 'SIMPLE-EDIT-MORADA': {
          return this.app.ds.open({
            viewModel: ComposeDialog,
            model: {
              modelo: payload,
              invoker: this,
              options: new ComposeDialogOptions({
                title: "Editar morada",
                withDefaultFooter: true,
                okLabel: "Gravar",
                mainView: '../routes/assinantes/ce/morada-form.html',
                postUri: 'api/morada',
                rootBindings: {
                  dicPaises: this.dicPaises
                }
              })
            },
            centerHorizontalOnly: true
          }).whenClosed(r => {
            if (!r.wasCancelled) {
              if (r.output.payload) {
                this.fetchModeloMoradaEnvio().then(obj => this.modeloMoradaEnvio = this.parseMorada(obj));
                this.fetchModeloMoradaFaturacao().then(obj => this.modeloMoradaFaturacao = this.parseMorada(obj));
                this.gridMoradas.refreshPage();
                this.app.notificationSuccess("Morada atualizada!");
              }
            }
            return r.wasCancelled
          });
        }

        case 'EDIT-MORADA': {
          this.isBusy = true;
          return this.fetchMorada(payload)
            .then(x => {
              var modelo = this.parseMorada(x);
              modelo.nvcContribuinte = modelo.nvcContribuinte ? modelo.nvcContribuinte : this.modelo.nvcContribuinte;
              modelo.nvcNome = modelo.nvcNome ? modelo.nvcNome : this.modelo.nvcNome;
              this.app.ds.open({
                viewModel: ComposeDialog,
                model: {
                  modelo: modelo,
                  invoker: this,
                  options: new ComposeDialogOptions({
                    title: modelo.id ? "Editar morada" : "Adicionar morada",
                    withDefaultFooter: true,
                    okLabel: "Gravar",
                    mainView: '../routes/assinantes/ce/morada-criar.html',
                    postUri: 'api/morada',
                    rootBindings: {
                      dicPaises: this.dicPaises,
                      dicMoradaTipos: this.dicMoradaTipos
                    }
                  })
                },
                //position: centerDialog,
                centerHorizontalOnly: true
              }).whenClosed(r => {
                if (!r.wasCancelled) {
                  if (r.output.payload) {
                    this.fetchModeloMoradaEnvio().then(obj => this.modeloMoradaEnvio = this.parseMorada(obj));
                    this.fetchModeloMoradaFaturacao().then(obj => this.modeloMoradaFaturacao = this.parseMorada(obj));
                    this.gridMoradas.refreshPage();
                    this.app.notificationSuccess("Morada atualizada!");
                    return this.isBusy = false;
                  }
                }
                else
                  this.isBusy = false;
                return r.wasCancelled
              })
            });
        }

        case 'DELETE-MORADA': {
          if (!payload) return;

          //Não está cosmeticamente bonito, mas por enquanto fica desta forma.
          var tipo = payload.nvcTipoDescricao == 'Envio' ? ' envio ' : ' faturação ';
          var msg = payload.bitPredefinida ? 'Esta morada é a morada predefinida para' + tipo + '! Tem a certeza de que pretende eliminar?' : 'Pretende eliminar esta morada de' + tipo + '?';

          return this.app.confirmaPopup([msg])
            .then(x => {
              if (x === true) {
                this.isBusy = true;

                return this.app.api.postProcessed("api/morada/delete", payload.id)
                  .then(x => this.fetchModeloMoradaEnvio().then(obj => this.modeloMoradaEnvio = this.parseMorada(obj)))
                  .then(x => this.fetchModeloMoradaFaturacao().then(obj => this.modeloMoradaFaturacao = this.parseMorada(obj)))
                  .then(x => this.gridMoradas.refreshPage())
                  .then(x => this.app.notificationSuccess('Morada removida!'))
                  .then(x => this.isBusy = false);
              }
            })
            .catch(err => this.app.notificationErrorCompact(err))
        }

        case 'USAR-MORADA-ENVIO': {
          return this.app.api.postProcessed("api/morada/definir-envio", payload.id)
            .then(x => this.fetchModeloMoradaEnvio().then(obj => this.modeloMoradaEnvio = this.parseMorada(obj)))
            .then(x => this.gridMoradas.refreshPage())
            .catch(err => this.app.notificationErrorCompact(err))
        }

        case 'USAR-MORADA-FATURACAO': {
          return this.app.api.postProcessed("api/morada/definir-faturacao", payload.id)
            .then(x => this.fetchModeloMoradaFaturacao().then(obj => this.modeloMoradaFaturacao = this.parseMorada(obj)))
            .then(x => this.gridMoradas.refreshPage())
            .catch(err => this.app.notificationErrorCompact(err))
        }

        case 'ENTIDADE-ERP-CHANGED': {
          if (this.modelo.nvcEntidadeFaturacaoErp && this.modelo.nvcEntidadeFaturacaoErp.length > 7) {
            return this.fetchModeloMoradaFaturacaoERP()
              .then(obj => {
                this.modeloMoradaFaturacao = this.parseMorada(obj.morada)
                this.modeloMoradaFaturacao['nvcNome'] = obj.nvcNome;
              });
          } else {
            return this.fetchModeloMoradaFaturacao()
              .then(obj => this.modeloMoradaFaturacao = this.parseMorada(obj))
          }
        }

        case 'CANCELAR-ASSINATURA': {
          //so permite cancelar uma assinatura de cada vez
          if (this.operacaoAssinatura.count == 0 || this.operacaoAssinatura.count > 1) return;
          var oAssinatura = this.operacaoAssinatura.first;

          return this.app.confirmaPopup(['Pretende cancelar esta assinatura?'])
            .then(response => {
              if (response === true) {

                var valorEdicao = (oAssinatura.decValor / ((oAssinatura.intEdicaoFim - oAssinatura.intEdicaoInicio) + 1));
                var intNumEdicoes = (oAssinatura.intEdicaoFim - oAssinatura.intEdicaoInicio) + 1;
                if (intNumEdicoes < 0) intNumEdicoes = 0;
                var decValor = (valorEdicao * intNumEdicoes).toFixed(2);

                var modelo = new CancelarAssinatura({
                  idAssinatura: oAssinatura.id,
                  decValor: oAssinatura.bitPago ? 0 : parseFloat(decValor),
                  intUltimaEdicao: oAssinatura.intEdicaoFim,
                  intPrimeiraEdicao: oAssinatura.intEdicaoInicio,
                  intNumEdicoes: intNumEdicoes,
                  decValorEdicao: valorEdicao
                });

                this.isBusy = true;
                return this.app.ds.open({
                  viewModel: ComposeDialog,
                  model: {
                    modelo: modelo,
                    invoker: this,
                    options: new ComposeDialogOptions({
                      title: "Cancelar Assinatura",
                      withDefaultFooter: true,
                      okLabel: "Gravar",
                      mainView: '../routes/assinantes/ce/cancelar-assinatura.html',
                      postUri: 'api/assinanteassinatura/cancelar',
                      rootBindings: {
                        parent: this,
                        bitPago: oAssinatura.bitPago
                      }
                    })
                  },
                  centerHorizontalOnly: true
                }).whenClosed(r => {
                  if (!r.wasCancelled) {
                    this.refreshTabelas();
                    this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                    this.fetchValorEmDivida();
                  }
                  return this.isBusy = false;
                })
              }
            })
            .catch(err => {
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });
        }

        case 'ATIVAR-ASSINATURA': {
          if (!this.operacaoAssinatura || !this.operacaoAssinatura.PodeAtivar) return;
          var oAssinatura = this.operacaoAssinatura.first;

          return this.app.confirmaPopup(['Pretende ativar esta assinatura?'])
            .then(response => {
              this.isBusy = true;
              if (response === true) {
                return this.app.api.postProcessed("api/assinanteassinatura/ativar", oAssinatura.id)
                  .then(x => {
                    this.refreshTabelas();
                    this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                    this.isBusy = false;
                  })
              } else {
                this.isBusy = false;
              }
            })
            .catch(err => {
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });
        }

        case 'CALC-VALOR': {
          if (payload.intUltimaEdicao < payload.intPrimeiraEdicao) payload.intUltimaEdicao = payload.intPrimeiraEdicao;
          payload.intNumEdicoes = (payload.intUltimaEdicao - payload.intPrimeiraEdicao) + 1;
          payload.decValor = (payload.decValorEdicao * payload.intNumEdicoes).toFixed(2);
          break;
        }

        case 'RENOVAR-ASSINATURA': {
          if (!this.modelo.id || this.modelo.id == 0) {
            this.app.notificationError("Grave o novo assinante antes de renovar a assinatura");
            return false;
          }

          if (!this.modelo.idAssinatura || this.modelo.idAssinatura == 0) {
            this.app.notificationError("O Assinante não tem nenhuma assinatura definida!");
            return false;
          }
          var isNew = payload === true ? true : false;

          return this.app.confirmaPopup([isNew ? 'Pretende ativar já a assinatura deste assinante?' : 'Pretende renovar a assinatura deste assinante?'])
            .then(x => {
              if (x === true) {
                this.isBusy = true;
                return this.app.api.postProcessed('api/assinanteassinatura/gerar?idAssinante=' + this.modelo.id + '&idAssinatura=' + this.modelo.idAssinatura)
                  .then(response => {
                    if (response) {
                      this.app.notificationSuccess(isNew ? "Assinatura ativada!" : "Assinatura renovada!");
                      this.refreshTabelas();
                      var oAssinatura = AssinanteAssinatura.fromPOJSO(response);
                      if (oAssinatura.decValorDivida > 0) {
                        this.app.confirmaPopup(['Pretende imprimir os dados para pagamento Multibanco?'])
                          .then(response => {
                            if (response === true) {
                              this.app.notificationInfo("A imprimir documento. Por favor aguarde.");
                              this.isBusy = true;
                              this.app.api.post('api/assinanteassinatura/imprime-debito-assinante/', oAssinatura.idAssinante)
                                .then(r => this.app.api.processBlobResponse(r))
                                .then(blob => {
                                  let nomeDoc = `Debito-${this.modelo.intNumero}-${now()}`;
                                  this.app.api.processBlobPreview(blob, nomeDoc);
                                  if (isNew) this.app.router.navigate("#/assinantes/assinantes-detalhe?id=" + this.modelo.id, { replace: true });
                                });
                            }
                            else
                              this.doAction("REFRESH");
                          });
                      } else {
                        if (isNew) this.app.router.navigate("#/assinantes/assinantes-detalhe?id=" + this.modelo.id, { replace: true });
                      }
                    }
                    return this.isBusy = false;
                  });
              } else {
                if (isNew) this.app.router.navigate("#/assinantes/assinantes-detalhe?id=" + this.modelo.id, { replace: true });
              }
              return this.isBusy = false;
            })
            .catch(err => this.app.notificationErrorCompact(err));
        }

        case 'RG_ROW_CLICK': {
          if (this.gridAssinaturas && this.gridAssinaturas.selecaoAtual().length > 0) {
            this.isBusy = true;
            let lstIds = AssinaturaAssinante.multipleFromPOJSO(this.gridAssinaturas.selecaoAtual()).map(obj => { return obj.id; });
            this.doAction("CARREGAR-DETALHE-ASSINATURA", lstIds);

          }
          else
            this.operacaoAssinatura = null;

          break;
        }

        case 'CARREGAR-DETALHE-ASSINATURA': {
          return this.app.api.postProcessed("api/assinanteassinatura/to-dto", payload)
            .then(response => {
              this.operacaoAssinatura = new OperacoesAssinatura(
                {
                  lstAssinaturas: AssinaturaAssinante.multipleFromPOJSO(response),
                  oAssinante: this.modelo
                }
              );
              this.isBusy = false;
            });
        }

        case 'PAGAR-ASSINATURAS': {
          //VERIFICA SE A CAIXA ESTÁ ABERTA
          if (!this.caixaAberta)
            return this.doAction('ABRIR-CAIXA', 'PAGAR-ASSINATURAS');

          var lstAssinaturas = AssinanteAssinatura.multipleFromPOJSO(this.gridAssinaturas.selecaoAtual());
          lstAssinaturas = lstAssinaturas.filter(function (el) {
            return el.bitPago === false;
          });
          var decValor = lstAssinaturas.reduce(function (tot, el) {
            return tot + el.decValorDivida;
          }, 0);

          if (!lstAssinaturas || lstAssinaturas.length == 0) {
            this.app.notificationError("As assinaturas seleccionadas já se encontram pagas.");
            return;
          }
          //VERIFICA SE ALGUMA ASSINATURA JÁ TEM UMA FATURA EMITIDA
          var lstIds = lstAssinaturas.map(obj => { return obj.id; });
          var dateNow = dateISOString();

          let oPagamento = new Pagamento({
            idAssinante: this.modelo.id,
            nvcMeioPagamento: this.modelo.nvcMeioPagamento,
            idCondicaoPagamento: this.dicCondicoesPagamento[0].id,
            decValor: decValor,
            dtmData: dateNow,
            lstIdsAssinaturas: lstIds
          });
          this.isBusy = true;
          return this.app.ds.open({
            viewModel: ComposeDialog,
            model: {
              modelo: oPagamento,
              invoker: this,
              options: new ComposeDialogOptions({
                title: "Adicionar pagamento",
                withDefaultFooter: true,
                okLabel: "Gravar",
                messageBeforePost: "A processar pagamento. Aguarde...",
                mainView: '../routes/assinantes/ce/pagamento-notas.html',
                postUri: 'api/pagamento',
                rootBindings: {
                  dicMeiosPagamento: this.dicMeiosPagamento,
                  dicCondicoesPagamento: this.dicCondicoesPagamento
                }
              })
            },
            centerHorizontalOnly: true
          }).whenClosed(r => {
            if (!r.wasCancelled) {
              if (r.output) {
                var oPagamento = Pagamento.fromPOJSO(r.output.payload.value);
                this.refreshTabelas();
                this.fetchValorEmDivida();
                this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                this.app.notificationSuccess("Pagamento registado!");
                if (oPagamento.bitFaturaRecibo) this.doAction('DOWNLOAD-FATURA-RECIBO', oPagamento);
                if (!oPagamento.bitFaturaRecibo) this.doAction('DOWNLOAD-FATURA', oPagamento);
              } else this.isBusy = false;
            } else this.isBusy = false;
            return r.wasCancelled
          })
            .catch(err => {
              if (this.gridPagamentos) this.gridPagamentos.refreshPage();
              if (this.gridAssinaturas) this.gridAssinaturas.refreshPage();
              this.fetchValorEmDivida();
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });
        }


        case 'LIQUIDAR-ASSINATURAS': {
          //VERIFICA SE A CAIXA ESTÁ ABERTA
          if (!this.caixaAberta)
            return this.doAction('ABRIR-CAIXA', 'LIQUIDAR-ASSINATURAS');

          if (!this.operacaoAssinatura.PodeLiquidar)
            this.app.notificationError("Assinatura já se encontra liquidada");

          let oLiquidacao = this.operacaoAssinatura.first.pagamentos.find(p => !p.bitFaturaRecibo && !p.bitLiquidado && !p.bitAnulado);

          this.isBusy = true;
          return this.app.ds.open({
            viewModel: ComposeDialog,
            model: {
              modelo: oLiquidacao,
              invoker: this,
              options: new ComposeDialogOptions({
                title: "Liquidar Assinatura",
                withDefaultFooter: true,
                okLabel: "Liquidar",
                messageBeforePost: "A processar a liquidação...",
                mainView: '../routes/assinantes/ce/assinatura-liquidar.html',
                postUri: 'api/pagamento/recibo',
                rootBindings: {
                  dicMeiosPagamento: this.dicMeiosPagamento,
                  dicCondicoesPagamento: this.dicCondicoesPagamento
                }
              })
            },
            centerHorizontalOnly: true
          }).whenClosed(r => {
            if (!r.wasCancelled) {
              if (r.output.payload) {
                var oPagamento = Pagamento.fromPOJSO(r.output.payload.modelo);
                this.refreshTabelas();
                this.fetchValorEmDivida();
                this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                this.app.notificationSuccess("Pagamento registado!");
                this.doAction('DOWNLOAD-RECIBO', oPagamento);
              } else this.isBusy = false;
            } else this.isBusy = false;
            return r.wasCancelled
          })
            .catch(err => {
              if (this.gridPagamentos) this.gridPagamentos.refreshPage();
              if (this.gridAssinaturas) this.gridAssinaturas.refreshPage();
              this.fetchValorEmDivida();
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });
        }

        case 'PRINT-DEBITO-ASSINATURA': {
          return this.app.confirmaPopup(['Pretende imprimir o documento de pagamento?'])
            .then(x => {
              if (x === true) {
                this.isBusy = true;
                this.app.notificationInfo("A imprimir documento. Por favor aguarde.");

                //Se forem várias seleccionadas, imprime todas, lol TODO
                if (this.operacaoAssinatura && this.operacaoAssinatura.SelectedItemsLength > 0) {
                  let nomeDoc = `Debito-${this.modelo.intNumero}-${now()}`;
                  return this.app.api.post('api/assinanteassinatura/imprime-debito-assinante/', this.modelo.id)
                    .then(r => this.app.api.processBlobResponse(r))
                    .then(blob => {
                      this.app.api.processBlobPreview(blob, nomeDoc);
                      return this.isBusy = false;
                    });
                }

                //Se for só uma assinatura, imprime normal, lol
                if (this.operacaoAssinatura && this.operacaoAssinatura.SelectedItemsLength == 1) {
                  let nomeDoc = `Debito-${this.modelo.intNumero}-${now()}`;
                  return this.app.api.post('api/assinanteassinatura/imprime-debito/', this.operacaoAssinatura.first.id)
                    .then(r => this.app.api.processBlobResponse(r))
                    .then(blob => {
                      this.app.api.processBlobPreview(blob, nomeDoc);
                      return this.isBusy = false;
                    });
                }
              }
            })
            .catch(err => {
              this.isBusy = false;
              this.app.notificationErrorCompact(err)
            })
        }

        case 'ABRIR-CAIXA': {
          return this.app.confirmaPopup(["Pretende abrir a caixa?"])
            .then(response => {
              if (response == true) {
                this.isBusy = true;
                return this.app.api.postProcessed('api/movimentoscaixa/abrir')
                  .then(result => {
                    if (result === true) {
                      return this.fetchCaixaAberta()
                        .then(x => this.doAction(payload))
                        .then(x => this.isBusy = false);
                    } else {
                      return this.isBusy = false;;
                    }
                  });
              }
            })
            .catch(err => this.app.notificationErrorCompact(err))
        }


        case 'EDITAR-ASSINATURA': {
          if (this.operacaoAssinatura.count != 1) return;
          var oModelo = AssinaturaAssinante.fromPOJSO(this.operacaoAssinatura.first);

          return this.app.ds.open({
            viewModel: ComposeDialog,
            model: {
              modelo: oModelo,
              invoker: this,
              options: new ComposeDialogOptions({
                title: "Alterar Assinatura",
                withDefaultFooter: true,
                okLabel: "Gravar",
                messageBeforePost: "Aguarde...",
                mainView: '../routes/assinantes/ce/assinatura-editar.html',
                postUri: 'api/assinanteassinatura/editar',
                rootBindings: {
                  parent: this,
                  dicAssinaturas: this.dicAssinaturas
                }
              })
            },
            centerHorizontalOnly: true
          }).whenClosed(r => {
            if (!r.wasCancelled) {
              if (r.output.payload) {
                this.refreshTabelas();
                this.fetchValorEmDivida();
                this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                this.app.notificationSuccess("Assinatura alterada!");
              } else this.isBusy = false;
            } else this.isBusy = false;
            return r.wasCancelled
          })
            .catch(err => {
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });
        }

        case 'EMITIR-DOCUMENTO': {
          let oPagamento = Pagamento.fromPOJSO(payload);
          if (oPagamento.bitFaturaEmitidaErp) {
            this.app.notificationWarning("O documento já foi emitido externamente com o código: " + oPagamento.nvcNumeroFaturaErp);
            return;
          }

          return this.app.confirmaPopup(['Pretende emitir o documento de pagamento?'])
            .then(x => {
              if (x === true) {
                this.isBusy = true;
                return this.app.api.postProcessed('api/pagamento/emitir', oPagamento.id)
                  .then(result => {
                    if (result) {
                      oPagamento = Pagamento.fromPOJSO(result);
                      this.app.notificationSuccess("Documento Emitido!");
                      this.refreshTabelas();
                      this.fetchValorEmDivida();
                      this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                      if (oPagamento.bitFaturaRecibo) this.doAction('DOWNLOAD-FATURA-RECIBO', oPagamento);
                      if (!oPagamento.bitFaturaRecibo) this.doAction('DOWNLOAD-FATURA', oPagamento);
                      return this.isBusy = false;
                    }
                  });
              }
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err.message));
        }

        case 'DOWNLOAD-FATURA-RECIBO': {
          this.isBusy = true;
          let pagamento = Pagamento.fromPOJSO(payload);
          return this.doAction('DOWNLOAD-DOCUMENTO', { id: pagamento.id, nome: pagamento.nvcNumeroFaturaErp, tipo: 'FR' });
        }

        case 'DOWNLOAD-FATURA': {
          this.isBusy = true;
          let pagamento = Pagamento.fromPOJSO(payload);
          return this.doAction('DOWNLOAD-DOCUMENTO', { id: pagamento.id, nome: pagamento.nvcNumeroFaturaErp, tipo: 'F' });
        }

        case 'DOWNLOAD-RECIBO': {
          this.isBusy = true;
          let pagamento = Pagamento.fromPOJSO(payload);
          return this.doAction('DOWNLOAD-DOCUMENTO', { id: pagamento.id, nome: pagamento.nvcNumeroReciboErp, tipo: 'R' });
        }

        case 'DOWNLOAD-DOCUMENTO': {
          this.isBusy = true;
          let id = payload.id;
          let nome = payload.nome;
          let tipo = payload.tipo;
          this.app.notificationInfo("A imprimir documento. Por favor aguarde.");
          let url = '';

          if (tipo == 'FR') url = 'api/pagamento/imprimir-faturarecibo';
          if (tipo == 'F') url = 'api/pagamento/imprimir-fatura';
          if (tipo == 'R') url = 'api/pagamento/imprimir-recibo';

          return this.app.api.post(url, id)
            .then(bytes => {
              return this.app.api.processBlobResponse(bytes)
                .then(blob => this.app.api.processBlobPreview(blob, nome))
                .then(x => this.isBusy = false);
            })
            .catch(err => {
              this.isBusy = false;
              //this.app.notificationErrorCompact("Não foi possível imprimir o documento.");
              this.app.notificationErrorCompact(err);
            })
        }


        case 'REGISTAR-DOCUMENTO': {
          if (this.operacaoAssinatura.count != 1 || !this.operacaoAssinatura.PodePagar) return;

          let oModelo = new RegistarDocumento({
            intCodEntidade: this.modelo.nvcChaveErp,
            idAssinatura: this.operacaoAssinatura.first.id
          });

          return this.app.ds.open({
            viewModel: ComposeDialog,
            model: {
              modelo: oModelo,
              invoker: this,
              options: new ComposeDialogOptions({
                title: "Registar Fatura",
                withDefaultFooter: true,
                okLabel: "Registar",
                messageBeforePost: "Aguarde...",
                mainView: '../routes/assinantes/ce/registar-documento.html',
                postUri: 'api/pagamento/doc-existente',
                rootBindings: {
                  parent: this
                }
              })
            },
            centerHorizontalOnly: true
          }).whenClosed(r => {
            if (!r.wasCancelled) {
              if (r.output.payload) {
                this.refreshTabelas();
                this.fetchValorEmDivida();
                this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                this.app.notificationSuccess("Documento Registado!");
              } else this.isBusy = false;
            } else this.isBusy = false;
            return r.wasCancelled
          })
            .catch(err => {
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });

        }




        case 'ANULAR-PAGAMENTO': {
          if (!payload) return;
          var oPagamento = Pagamento.fromPOJSO(payload);

          return this.app.confirmaPopup(['Pretende anular este pagamento?'])
            .then(response => {
              if (response === true) {

                var modelo = new AnularDocumento({
                  idPagamento: oPagamento.id
                });

                this.isBusy = true;
                return this.app.ds.open({
                  viewModel: ComposeDialog,
                  model: {
                    modelo: modelo,
                    invoker: this,
                    options: new ComposeDialogOptions({
                      title: "Anular Pagamento",
                      withDefaultFooter: true,
                      okLabel: "Anular",
                      mainView: '../routes/assinantes/ce/anular-pagamento.html',
                      postUri: 'api/pagamento/anular-fatura',
                      rootBindings: {
                        dicMotivos: [
                          { id: 'VAL', text: 'Valores errados' },
                          { id: 'NIF', text: 'Contribuinte errado' },
                          { id: 'CLI', text: 'Cliente errado' },
                          { id: 'CRE', text: 'Documento creditado (NÃO ANULA NO ETICADATA)' }
                        ]
                      }
                    })
                  },
                  centerHorizontalOnly: true
                }).whenClosed(r => {
                  if (!r.wasCancelled) {
                    this.refreshTabelas();
                    this.fetchValorEmDivida();
                    this.doAction("CARREGAR-DETALHE-ASSINATURA", this.operacaoAssinatura.ids);
                    this.app.notificationSuccess("Pagamento anulado!");
                  }
                  return this.isBusy = false;
                })
              }
            })
            .catch(err => {
              this.app.notificationErrorCompact(err.message);
              return this.isBusy = false;
            });
        }

        case 'GERAR-REFERENCIA': {
          return this.app.confirmaPopup(['Pretende gerar uma nova referência Multibanco para este assinante?'])
            .then(x => {
              if (x === true) {
                this.isBusy = true;
                return this.app.api.postProcessed('api/assinante/gerar-multibanco', this.modelo.id)
                  .then(result => {
                    if (result) {
                      if (result.nvcReferenciaMb !== this.modelo.nvcReferenciaMb) {
                        this.app.notificationSuccess("Referência multibanco gerada!");
                        this.modelo.nvcReferenciaMb = result.nvcReferenciaMb;
                        this.modelo.nvcEntidadeMb = result.nvcEntidadeMb;
                      }
                      return this.isBusy = false;
                    }
                  });
              }
              else {
                return this.isBusy = false;
              }
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err.message));
        }

        case 'GERAR-PASSWORD': {
          return this.app.confirmaPopup(['Pretende gerar uma nova password para este assinante?'])
            .then(x => {
              if (x === true) {
                this.isBusy = true;
                return this.app.api.postProcessed('api/assinante/gerar-password', this.modelo.id)
                  .then(result => {
                    if (result) {
                      if (result.nvcWebsite !== this.modelo.nvcWebsite) {
                        this.app.notificationSuccess("Password gerada!");
                        this.modelo.nvcWebsite = result.nvcWebsite;
                      }
                      return this.isBusy = false;
                    }
                  });
              }
              else {
                return this.isBusy = false;
              }
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err.message));
        }

        case 'CONSUMIDORFINAL-CHANGED': {
          if (this.modelo.bitConsumidorFinal) this.modelo.bitFaturacaoEletronica = false;
          break;
        }

        default: {
          if (environment.debug) console.error("[assinantes-listagem]", "Acção DESCONHECIDA [logs-listagem]", action);
          // if (environment.debug)  this.app.notificationErrorCompact("Acção desconhecida: " + action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }


  pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  }
}
