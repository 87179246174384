//import {setInterval} from "timers";
import { autoinject, computedFrom } from "aurelia-framework";
import { activationStrategy } from "aurelia-router";
import { GlobalServices } from "../../services/global-services";
import { RemoteGrid } from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import { ActionFromRemoteGrid, RemoteGridActions } from "../../it-features/remote-grid/interfaces";
import { ComposeDialog, ComposeDialogOptions } from "../../dialogs/compose-dialog";
import { MaskInputOptions } from "../../it-features/it-mask/mask-input"
import { FechoEdicao } from "../../models/FechoEdicao"
import moment = require("moment");

@autoinject()
export class EdicaoFechar {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  private model: FechoEdicao = null;
  private bitFechada: boolean = false;
  private bitTemCartas: boolean = false;
  private maskConfig = new MaskInputOptions({ numeric: true, block: true, blocks: [4] });
  private gridCode = "Etiquetas?p1=";
  private gridUrl = "Etiquetas?p1=0";
  private titulo = "";

  constructor(public app: GlobalServices) { }

  canActivate() {
  }

  attached() {
    this.doAction("MUDA-EDICAO");
  }


  /**
   * doAction Loacl
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[etiquetas-export]", "{doAction}", action, payload);
    try {
      switch (action) {

        case "MUDA-EDICAO": {
          var url = this.model && this.model.intEdicao ? "api/fechoedicao/" + this.model.intEdicao : "api/fechoedicao";
          return this.app.api.getProcessed(url).then(result => {
            this.model = FechoEdicao.fromPOJSO(result.modelo);
            this.bitFechada = result.fechada;
            this.bitTemCartas = this.model.intCartasRenovacao > 0;
            this.gridUrl = this.gridCode + this.model.intEdicao;
            this.titulo = " | Edição nº " + this.model.intEdicao + " de " + this.model.dtmEdicao;
            if (this.rg) this.rg.refreshPage();
            this.isBusy = false;
          });
        }


        case "FECHAR-EDICAO": {
          if (this.bitFechada) return this.app.notificationWarning("A edição já se encontra fechada!");
          return this.app.confirmaPopup(['PRETENDE FECHAR A EDIÇÃO ' + this.model.intEdicao + '?'])
            .then(x => {
              if (x === true) {
                this.app.notificationInfo("A fechar a edição. Este processo poderá demorar vários minutos!");
                this.isBusy = true;
                return this.rg.getExcel("Etiquetas_" + this.model.intEdicao.toString())
                  .then(x => this.app.api.postProcessed('api/fechoedicao', this.model.intEdicao)
                    .then(response => {
                      this.doAction("MUDA-EDICAO");
                      return this.isBusy = false;
                    })
                    .catch(err => {
                      this.app.notificationErrorCompact(err)
                      return this.isBusy = false;
                    }))
              } else
                return this.isBusy = false;
            })
            .catch(err => { this.isBusy = false; this.app.notificationErrorCompact(err) })
        }

        case "EMITIR-CARTAS": {
          return this.app.confirmaPopup(["PRETENDE EMITIR AS CARTAS DE DÉBITO?"])
            .then(response => {
              if (response === true) {
                this.isBusy = true;
                this.app.notificationInfo("A emitir as cartas de débito. Este processo poderá demorar vários minutos!");
                this.app.api.postProcessed('api/fechoedicao/emite-cartas', this.model.intEdicao)
                  .then(r => {
                    r.files.forEach((bytes, i) => {
                      let convered = this.app.api.processByteToPDFBlob(bytes);
                      this.app.api.processBlobPreview(convered, "Cartas de débito edição " + this.model.intEdicao + " p" + i);
                    });
                    
                    return this.isBusy = false;
                  })
                  .catch(err => {
                    this.app.notificationErrorCompact(err)
                    return this.isBusy = false;
                  })
              } else {
                return this.isBusy = false;
              }
            });
        }


        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[etiquetas-export]", "Acção DESCONHECIDA [etiquetas-export]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
