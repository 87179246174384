import {autoinject, bindable, bindingMode} from "aurelia-framework";
import environment from "../../environment";

/**
 * Um CE que cria uma navegaçao por tabs
 */
@autoinject()
export class TabsList {
  /**
   * Lista de tabs disponíveis
   */
  @bindable({defaultBindingMode: bindingMode.oneWay}) tabsList: TabsListOptions[] = [];
  @bindable dispatcher: any = null;
  @bindable customButtonVisible: boolean = true;
  @bindable customButtonText: string = "Renovar Assinatura";

  /**
   * A tab considerada ativa (selecionada, com o conteúdo correspondente visível)
   */
  private activeTab: TabsListOptions = null;

  private isBusy: boolean = false;

  bind() {
    // if(environment.debug) console.log("[tabs-list]","bind", this);
    if (this.tabsList.length > 0) {
      this.tabClick(this.tabsList[0]);
    }
  }

  /**
   * tratamento do click: devido à natureza assíncrona do click está protegida pelo bit isBusy que atua como semáforo
   * @param navItem
   */
  tabClick(navItem) {
    if (!this.isBusy) {
      this.isBusy = true;
      //marcar a tab préviamente selecionada como false
      this.activeTab && (this.activeTab.active = false);

      //atribui a nova tab selecionada
      this.activeTab         = navItem;
      this.activeTab.active  = true;
      //memoriza o histórico
      this.activeTab.visited = true;
      //liberta o semáforo
      this.isBusy            = false;
    }
  }

  makeActive(name: string = "") {
    if (name && !this.isBusy) {
      let tab = this.tabsList.find(el => el.tab == name);
      if (tab) this.tabClick(tab);
    }
  }

  makeActiveIndex(index: number = 0) {
    //garantir inteiro
    index = ~~index;
    if (index >= 0 && !this.isBusy) {
      try {
        let tab = this.tabsList[index];
        if (tab) this.tabClick(tab);
      } catch (e) {
        console.error("[tabs-list]", "O indice não existe");
      }
    }
  }
}

/**
 * estrutura para declarar Tabs
 */
export class TabsListOptions {
  titulo: string   = null;
  tab: string      = null;
  visible: boolean = true;

  //determina que a tab atual está ativa (e.g. para uso em _show.bind_)
  active: boolean = false;

  //determina que a tab atual foi visitada (e.g. para uso em _if.bind_)
  visited: boolean = false;

  public constructor(fields?: Partial<TabsListOptions>,) {
    if (fields) Object.assign(this, fields);
  }
}
