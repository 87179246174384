// Auto-gerado com typewriter
import { computedFrom } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { BaseViewModel } from "./BaseViewModel";
import { VmWrapper } from "./VmWrapper";
//imports locais
import { Assinante } from './Assinante';
import { Pais } from './Pais';
import { MoradaTipo } from './MoradaTipo';
//export classe
export class Morada extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public id: number = 0;
  public idAssinante: number = 0;
  public nvcTipo: string = null;
  public nvcMorada: string = null;
  public nvcMoradaExtra: string = null;
  public nvcLocalidade: string = null;
  public nvcCodPostal: string = null;
  public nvcPais: string = null;
  public nvcObservacoes: string = null;
  public dtmInicio: string = null;
  public _dtmInicio: Date = new Date();
  public dtmFim: string = null;
  public _dtmFim: Date = new Date();
  public bitPredefinida: boolean = false;
  public bitProvisoria: boolean = false;
  public nvcCodigoInterno: string = "00000000-0000-0000-0000-000000000000";
  public nvcNomeFaturacao: string = null;
  public nvcContribuinte: string = null;
  //public nvcUtilizadorI : string = null;
  //public dtmDataI:string = null;
  //public _dtmDataI:Date = new Date();
  //public nvcUtilizadorU : string = null;
  //public dtmDataU:string = null;
  //public _dtmDataU:Date = new Date();
  //public timestamp : number[] = [];
  public idAssinanteNavigation: Assinante = null;
  public nvcPaisNavigation: Pais = null;
  public nvcTipoNavigation: MoradaTipo = null;


  public nvcNome: string = null;
  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Morada>, ) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Morada {
    let model = new Morada();
    model.setState(obj);
    if (obj.nvcPaisNavigation) model.nvcPaisNavigation = Pais.fromPOJSO(obj.nvcPaisNavigation);
    return model;
  }

  get isNew(): boolean {
    return !(this.id && this.id > 0)
  }

  public static multipleFromPOJSO(objs: any | any[]): Morada[] {
    if (objs && Array.isArray(objs)) return objs.map(Morada.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Morada>({ payload: this, confirmLevel: (+cl || 0) });
  }

  public cloneInstance(): Morada { return Morada.fromPOJSO(this.stateToPOJSO()); }
  //endregion

  public toString() {
    return `Morada`;
  }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Morada) => m.id).displayName("id").required()
  .ensure((m: Morada) => m.idAssinante).displayName("idAssinante").required()
  .ensure((m: Morada) => m.nvcTipo).displayName("Tipo de morada").required()
  .ensure((m: Morada) => m.nvcMorada).displayName("Morada").required()
  //.ensure((m: Morada) => m.nvcMoradaExtra).displayName("nvcMorada").required()
  .ensure((m: Morada) => m.nvcLocalidade).displayName("Localidade").required()
  .ensure((m: Morada) => m.nvcCodPostal).displayName("Código Postal").required()
  .ensure((m: Morada) => m.nvcPais).displayName("País").required()
  .ensure((m: Morada) => m.nvcObservacoes).displayName("Observações").required()
  .ensure((m: Morada) => m.nvcCodigoInterno).displayName("Código Interno").required()
  //.ensure((m: Morada) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
  //.ensure((m: Morada) => m.dtmDataI).displayName("dtmDataI").required()
  //.ensure((m: Morada) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
  //.ensure((m: Morada) => m.dtmDataU).displayName("dtmDataU").required()
  //.ensure((m: Morada) => m.timestamp).displayName("timestamp").required()
  .ensure((m: Morada) => m.idAssinanteNavigation).displayName("idAssinanteNavigation").required()
  .ensure((m: Morada) => m.nvcPaisNavigation).displayName("nvcPaisNavigation").required()
  .ensure((m: Morada) => m.nvcTipoNavigation).displayName("nvcTipoNavigation").required()
  .on(Morada);
