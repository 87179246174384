import {ValidationController} from "aurelia-validation";
import environment from "../environment";
import {lcfirst} from "./ItMultiPurpose";

/**
 * Este método não deve ser usado
 * Apenas está na base de código case se ache necessário replicar a funcionalidade
 *
 * @param {ValidationController} vc
 * @param subject
 * @param messages
 * @param {boolean} debug
 * @deprecated
 */
export function processValidation(vc: ValidationController, subject:any, messages:any, debug = false) {
  let keys = Object.keys(messages);
  keys.forEach(k => {
    if(environment.debug && debug) console.log('[processValidation]', "Ativar validação para", k, ' - ', messages[k], vc);
    //adiciona o erro no cliente
    vc.addError(messages[k], subject, lcfirst(k));
  });
}