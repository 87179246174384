import {FrameworkConfiguration} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    './elements/f-g.html',
    './elements/notifications',
    './elements/page-loading-frame.html',


    './attributes/foco',
    './attributes/autosel',
  ]);
}
