// Auto-gerado com typewriter
import { computedFrom } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { BaseViewModel } from "./../BaseViewModel";
import { VmWrapper } from "./../VmWrapper";
import { Pagamento } from "../Pagamento";
//export classe
export class AssinaturaAssinante extends BaseViewModel {
  public id: number = 0;
  public idAssinatura: number = 0;
  public bitCancelado: boolean = false;
  public bitEntregue: boolean = false;
  public bitFatura: boolean = false;
  public bitPago: boolean = false;
  public decValor: number = 0;
  public decValorDivida: number = 0;
  public dtmEdicaoFim: string = null;
  public dtmEdicaoInicio: string = null;
  public intEdicaoFim: number = 0;
  public intEdicaoInicio: number = 0;
  public nvcDescricaoAssinatura: string = null;
  public nvcObservacoes: string = null;
  public pagamentos: Pagamento[] = [];
  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<AssinaturaAssinante>, ) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): AssinaturaAssinante {
    let model = new AssinaturaAssinante();
    model.setState(obj);
    if (obj.pagamentos) model.pagamentos = Pagamento.multipleFromPOJSO(obj.pagamentos);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): AssinaturaAssinante[] {
    if (objs && Array.isArray(objs)) return objs.map(AssinaturaAssinante.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<AssinaturaAssinante>({ payload: this, confirmLevel: (+cl || 0) });
  }

  public cloneInstance(): AssinaturaAssinante { return AssinaturaAssinante.fromPOJSO(this.stateToPOJSO()); }
  //endregion

  public toString() {
    return `AssinaturaAssinante`;
  }
}
// aurelia-validation (comentar o que não interessa)
//ValidationRules 
//    .ensure((m: CancelarAssinatura) => m.idAssinatura).displayName("idAssinatura").required()
//    .ensure((m: CancelarAssinatura) => m.decValor).displayName("decValor").required()
//    .ensure((m: CancelarAssinatura) => m.nvcObservacoes).displayName("nvcObservacoes").required()
//    .ensure((m: CancelarAssinatura) => m.intUltimaEdicao).displayName("intUltimaEdicao").required()
//  .on(CancelarAssinatura);
