// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Morada} from './Morada';
//export classe
export class MoradaTipo extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public nvcTipo : string = null;
    public nvcDescricao : string = null;
    public bitActivo : boolean = false;
    public bitVisivel: boolean = false;
    public bitSistema: boolean = false;
    public intOrdem: number = 0;
    public nvcCodigoInterno : string = "00000000-0000-0000-0000-000000000000";
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    public morada : Morada[] = [];
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<MoradaTipo>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): MoradaTipo {
        let model = new MoradaTipo();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): MoradaTipo[] {
        if (objs && Array.isArray(objs)) return objs.map(MoradaTipo.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<MoradaTipo>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): MoradaTipo { return MoradaTipo.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `MoradaTipo`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: MoradaTipo) => m.nvcTipo).displayName("nvcTipo").required()
    .ensure((m: MoradaTipo) => m.nvcDescricao).displayName("nvcDescricao").required()
    .ensure((m: MoradaTipo) => m.bitActivo).displayName("bitActivo").required()
    .ensure((m: MoradaTipo) => m.bitVisivel).displayName("bitVisivel").required()
    .ensure((m: MoradaTipo) => m.intOrdem).displayName("intOrdem").required()
    .ensure((m: MoradaTipo) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    .ensure((m: MoradaTipo) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: MoradaTipo) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: MoradaTipo) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: MoradaTipo) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: MoradaTipo) => m.timestamp).displayName("timestamp").required()
    .ensure((m: MoradaTipo) => m.morada).displayName("morada").required()
  .on(MoradaTipo);
