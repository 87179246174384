// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {UtilizadorTipo} from './UtilizadorTipo';
//export classe
export class Utilizador extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public id : number = 0;
    public intNumero : number = 0;
    public nvcLogin : string = null;
    public nvcNome : string = null;
    public nvcPassword : string = null;
    public nvcSalt : string = null;
    public intPermissao : number = 0;
    public nvcTipo : string = null;
    public nvcTags : string = null;
    public bitActivo : boolean = false;
    public nvcContactoPessoal : string = null;
    public nvcTelemovel : string = null;
    public nvcTelefone : string = null;
    public nvcEmail : string = null;
    public nvcObservacoes : string = null;
    public nvcChaveErp : string = null;
    public nvcCodigoInterno : string = "00000000-0000-0000-0000-000000000000";
    public nvcUtilizadorI : string = null;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public nvcUtilizadorU : string = null;
    public dtmDataU:string = null;
    public _dtmDataU:Date = new Date();
    public timestamp : number[] = [];
    public nvcTipoNavigation : UtilizadorTipo = null;
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<Utilizador>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): Utilizador {
        let model = new Utilizador();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): Utilizador[] {
        if (objs && Array.isArray(objs)) return objs.map(Utilizador.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<Utilizador>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): Utilizador { return Utilizador.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `Utilizador`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: Utilizador) => m.id).displayName("id").required()
    .ensure((m: Utilizador) => m.intNumero).displayName("intNumero").required()
    .ensure((m: Utilizador) => m.nvcLogin).displayName("nvcLogin").required()
    .ensure((m: Utilizador) => m.nvcNome).displayName("nvcNome").required()
    .ensure((m: Utilizador) => m.nvcPassword).displayName("nvcPassword").required()
    .ensure((m: Utilizador) => m.nvcSalt).displayName("nvcSalt").required()
    .ensure((m: Utilizador) => m.intPermissao).displayName("intPermissao").required()
    .ensure((m: Utilizador) => m.nvcTipo).displayName("nvcTipo").required()
    .ensure((m: Utilizador) => m.nvcTags).displayName("nvcTags").required()
    .ensure((m: Utilizador) => m.bitActivo).displayName("bitActivo").required()
    .ensure((m: Utilizador) => m.nvcContactoPessoal).displayName("nvcContactoPessoal").required()
    .ensure((m: Utilizador) => m.nvcTelemovel).displayName("nvcTelemovel").required()
    .ensure((m: Utilizador) => m.nvcTelefone).displayName("nvcTelefone").required()
    .ensure((m: Utilizador) => m.nvcEmail).displayName("nvcEmail").required()
    .ensure((m: Utilizador) => m.nvcObservacoes).displayName("nvcObservacoes").required()
    .ensure((m: Utilizador) => m.nvcChaveErp).displayName("nvcChaveErp").required()
    .ensure((m: Utilizador) => m.nvcCodigoInterno).displayName("nvcCodigoInterno").required()
    .ensure((m: Utilizador) => m.nvcUtilizadorI).displayName("nvcUtilizadorI").required()
    .ensure((m: Utilizador) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: Utilizador) => m.nvcUtilizadorU).displayName("nvcUtilizadorU").required()
    .ensure((m: Utilizador) => m.dtmDataU).displayName("dtmDataU").required()
    .ensure((m: Utilizador) => m.timestamp).displayName("timestamp").required()
    .ensure((m: Utilizador) => m.nvcTipoNavigation).displayName("nvcTipoNavigation").required()
  .on(Utilizador);
