import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import environment from "../environment";

@autoinject()
export class ADialog {
  protected controller: DialogController;
  private errors: any;
  private errorKeys: string[] = [];

  constructor(controller: DialogController/*, app: GlobalServices*/) {
    // console.log("ConfirmacaoDialog constructor");
    this.controller           = controller;
  }

  activate(p) {
    if(environment.debug) console.log("[a-dialog]","activate");
  }
}
